import { useNetwork } from '@hooks/useNetwork';
import React from 'react';
import { Container } from 'react-bootstrap';
import { WrongNetworkBlock } from '../WrongNetworkBlock';

export const NonPolygonWidget = () => {
  const { defaultNetwork } = useNetwork()

  return (
    <Container>
      <div className='non-polygon-block tile'>
        <h3 className='title mb-4'>Locking and Staking</h3>
        <p className="mb-4 fs-4">
          Locking and Staking are available only on{' '}
          <b>{defaultNetwork.userFriendlyName} network</b>
        </p>
        <WrongNetworkBlock
          prefix={'To lock or stake your tokens'}
          expectedNetwork={defaultNetwork}
        />
      </div>
    </Container>
  )
}
