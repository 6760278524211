import React, { useCallback, useEffect } from 'react';
import { Container, Navbar } from 'react-bootstrap';
import { PortfolioList, ProjectFilter, SEOTags } from '@components';
import { useWeb3React } from '@web3-react/core';
import './Portfolio.css'
import { useProjectsState } from '@contracts/hooks/useProjects';
import { seoTags } from '@/seo-content';

export const Portfolio = () => {
  const { account } = useWeb3React()

  const {
    loading,
    whitelistedProjects: projects,
    getWhitelistedProjects,
    abortRequest
  } = useProjectsState()

  const loadProjects = useCallback(async () => {
    abortRequest()
    await getWhitelistedProjects()
  }, [abortRequest, getWhitelistedProjects])

  useEffect(() => {
    loadProjects()
  }, [account])

  return (
    <div className="portfolio-page">
      <section className="account-portfolio">
        <SEOTags {...seoTags.accountPortfolio} />
        <Container>
          <PortfolioList projects={!loading && projects ? projects : undefined} />
        </Container>
      </section>
    </div>
  )
}
