import { instanceWithSignature as API } from '../config';
import { ExclusiveWhitelistRequestArgs, WhitelistSuccessResponse } from './types';
import { WhitelistStatus } from '@contracts/hooks/useWhitelist';
import { sendExceptionReport } from '@utils/errors';
import { toChecksumAddress } from '@utils/string';

export const sendDataForExclusiveWhitelist = async ({
  project_id,
  wallet_address,
  form_data,
  password,
  referrer
}: ExclusiveWhitelistRequestArgs): Promise<WhitelistStatus | null> => {
  try {
    const { data } = await API.post(
      '/apply-to-exclusive-whitelist',
      {
        wallet_address: toChecksumAddress(wallet_address),
        form_data,
        referrer,
        password
      },
      {
        params: {
          project_id
        }
      }
    )

    return (data as WhitelistSuccessResponse).status
  } catch (err) {
    sendExceptionReport(err)
    return null
  }
}
