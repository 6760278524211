import React, { useState } from 'react';
import './ApplyForIDO.css';
import {
  FormFillResponseView,
  IDOForm,
  IDOFormValues,
  SEOTags,
} from '../../../components';
import { Container } from 'react-bootstrap';
import { submitToIDOForm } from '../../../api/form-fills';
import { seoTags } from '../../../seo-content';
import PageHeading from "@components/layout/PageHeading/PageHeading";
import Banner from '@assets/banners/apply-for-ido-banner.png'

export const ApplyForIDO = () => {
  const [formStage, setFormStage] = useState<'FILL' | 'RESPONSE'>('FILL');
  const [responseStatus, setResponseStatus] = useState<'error' | 'success' | null>(null);

  const resetForm = () => {
    setFormStage('FILL');
    setResponseStatus(null);
  };

  const submitIDO = async (values: IDOFormValues) => {
    const response = await submitToIDOForm(values);
    setResponseStatus(response.result);
    setFormStage('RESPONSE');
  };

  return (
    <div className="page apply-for-ido-page">
      <SEOTags { ...seoTags.ido } />
      <PageHeading banner={Banner} large>
        <PageHeading.Title>
          Apply for IDO
        </PageHeading.Title>
      </PageHeading>
      <section className='ido-application'>
        <Container>
          {
            formStage === 'FILL'
              ? <IDOForm onSubmit={submitIDO} />
              : <FormFillResponseView
                status={responseStatus}
                onReset={resetForm}
              />
          }
        </Container>
      </section>
    </div>
  );
};
