import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ReactComponent as LunarIcon } from '@assets/tiers/lunar.svg'
import { ReactComponent as OrbitIcon } from '@assets/tiers/orbit.svg'
import { ReactComponent as InterplanetaryIcon } from '@assets/tiers/interplanetary.svg'
import { ReactComponent as GalacticIcon } from '@assets/tiers/galactic.svg'
import { ReactComponent as UniversalIcon } from '@assets/tiers/universal.svg'
import { ZKTier, ZKTiers, tierBreakpoints } from '@contracts/hooks/useZKTier/constants';
import './ZKPowerLevel.scss';
import { usePlatformSettings } from '@hooks/usePlatformSettings';
import { numberToCurrency } from '@utils/balanceFormatter';
import classNames from "classnames";

interface Props {
  currentTier: ZKTier | null
}

const tiersArray = Object.values(ZKTiers) as ZKTier[]

const ZKPowerLevel = ({ currentTier }: Props) => {
  const { settings } = usePlatformSettings()
  const breakpoints = useMemo(() => {
    return tierBreakpoints.map(point => numberToCurrency(+point * (settings?.tiersFactor ?? 1)))
  }, [settings])

  const tierIndex = useMemo(() => {
    return tiersArray.findIndex(t => t.toLowerCase() === currentTier?.toLowerCase())
  }, [currentTier])

  const overlayWidth = useMemo(() => {
    if (tierIndex === -1) {
      return '100%'
    }
    if (tierIndex === tiersArray.length - 1) {
      return '0'
    }
    return 100 - 18.5 * (tierIndex + 1) + '%'
  }, [tierIndex])

  return (
    <Row className="heat-row gx-0">
      <Col className="text-left column">
        <div className="color-strip">
          <div
            className='color-strip__overlay'
            style={{
              width: overlayWidth
            }}
          />
        </div>
        <div className="progress">
          <div className={classNames('item', { active: tierIndex >= 0 })}>
            <div className="title">Start</div>
          </div>
          <div className={classNames('item', { active: tierIndex >= 0 })}>
            <div className="icon">
              <LunarIcon />
            </div>
            <div className="description">
              <div className="title">
                Lunar
              </div>
              <div className="subtitle">
                {breakpoints[0]}+ ZK POWER
              </div>
              <p>
                Community Lottery <br/> Round
              </p>
            </div>
          </div>
          <div className={classNames('item', { active: tierIndex >= 1 })}>
            <div className="icon">
              <OrbitIcon />
            </div>
            <div className="description">
              <div className="title">
                Orbit
              </div>
              <div className="subtitle">
                {breakpoints[1]}+ ZK POWER
              </div>
              <p>First Come - <br/> First Served</p>
            </div>
          </div>
          <div className={classNames('item', { active: tierIndex >= 2 })}>
            <div className="icon">
              <InterplanetaryIcon />
            </div>
            <div className="description">
              <div className="title">
                Interplanetary
              </div>
              <div className="subtitle">
                {breakpoints[2]}+ ZK POWER
              </div>
              <p>First Come - <br/> First Served</p>
            </div>
          </div>
          <div className={classNames('item', { active: tierIndex >= 3 })}>
            <div className="icon">
              <GalacticIcon />
            </div>
            <div className="description">
              <div className="title">
                Galactic
              </div>
              <div className="subtitle">
                {breakpoints[3]}+ ZK POWER
              </div>
              <p>First Come - <br/> First Served</p>
            </div>
          </div>
          <div className={classNames('item', { active: tierIndex >= 4 })}>
            <div className="icon">
              <UniversalIcon />
            </div>
            <div className="description">
              <div className="title">
                Universal
              </div>
              <div className="subtitle">
                {breakpoints[4]}+ ZK POWER
              </div>
              <p>First Come - <br/> First Served</p>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

ZKPowerLevel.defaultProps = {
  currentTier: ZKTiers.Universal
}

export { ZKPowerLevel }
