import Web3, { utils } from 'web3';
import { AbiItem } from 'web3-utils';

import multiperiodLockingAbi from './abi/multiperiodLocking.json';
import lpStakingAbi from './abi/staking.json';
import lpTokenAbi from './abi/lpTokenABI.json';
import commonTokenAbi from './abi/commonToken.json';
import projectPresaleAbi from './abi/projectPresale.json';
import projectPresaleMerkleAbi from './abi/projectPresaleMerkle.json';
import projectVestingAbi from './abi/projectVesting.json';
import whitelistAbi from './abi/whitelist.json';
import flareAbi from './abi/flareABI.json';
import hiroAbi from './abi/hiroABI.json';
import ignitorAbi from './abi/ignitorABI.json';
import { ContractAddress, tokenAddresses } from './address';
import { web3NoAccountInstances } from '@utils/web3';
import { checkIsMerkleWhitelisting, defaultEnvironmentNetworkId, NetworkId } from './networks';

const getContract = (
  abi: any,
  address: ContractAddress,
  chainId: string,
  web3?: Web3
) => {
  const _web3 = web3 ?? web3NoAccountInstances[chainId];
  return new _web3.eth.Contract(abi as AbiItem, address);
};

export const getMultiperiodLockingContract = (currentChainId: NetworkId, web3?: Web3) => {
  if (currentChainId !== defaultEnvironmentNetworkId) {
    throw new Error('Calling internal contract with a non default network')
  }
  return getContract(
    multiperiodLockingAbi,
    tokenAddresses.multiperiodLocking,
    defaultEnvironmentNetworkId,
    web3
  );
}

export const getLPStakingContract = (currentChainId: NetworkId, web3?: Web3) => {
  if (currentChainId !== defaultEnvironmentNetworkId) {
    throw new Error('Calling internal contract with a non default network')
  }
  return getContract(
    lpStakingAbi,
    tokenAddresses.lpStaking,
    defaultEnvironmentNetworkId,
    web3
  )
}
export const getLpTokenContract = (currentChainId: NetworkId, web3?: Web3) => {
  if (currentChainId !== defaultEnvironmentNetworkId) {
    throw new Error('Calling internal contract with a non default network')
  }
  return getContract(
    lpTokenAbi,
    tokenAddresses.lpToken,
    defaultEnvironmentNetworkId,
    web3
  )
}
export const getTokenContract = (chainId: string, tokenAddress?: ContractAddress, web3?: Web3) => {
  return tokenAddress ? getContract(commonTokenAbi, tokenAddress, chainId, web3) : null
}
export const getProjectPresaleContract = (chainId: string, contractAddress?: ContractAddress, web3?: Web3) => {
  const isMerkleWhitelisting = checkIsMerkleWhitelisting(chainId)
  const presaleAbi = isMerkleWhitelisting ? projectPresaleMerkleAbi : projectPresaleAbi
  return contractAddress ? getContract(presaleAbi, contractAddress, chainId, web3) : null
}
export const getProjectVestingContract = (chainId: string, contractAddress?: ContractAddress, web3?: Web3) => {
  return contractAddress ? getContract(projectVestingAbi, contractAddress, chainId, web3) : null
}
export const getWhitelistContract = (chainId: string, contractAddress?: ContractAddress, web3?: Web3) => {
  return contractAddress ? getContract(whitelistAbi, contractAddress, chainId, web3) : null
}