import React from 'react'
import './Footer.scss'
import { RoutesPaths } from '@router/constants'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import logo from '@assets/logo.svg'
import { ReactComponent as CopyIcon } from '@assets/copy.svg'
import { ReactComponent as MetamaskIcon } from '@assets/metamask-logo.svg'
import { Copiable, DynamicImage } from '@components/common'
import { defaultEnvironmentNetworkData } from '@contracts/networks'
import { socialLinks, infoEmail } from '@constants'
import { tokenAddresses } from '@contracts/address'
import { shorterETHAddress } from '@utils/string'

interface FooterLink {
  title: string
  href?: string
  to?: string
}

const footerLinks: Record<string, Array<FooterLink>> = {
  company: [
    {
      title: 'About Us',
      to: RoutesPaths.ABOUT_US,
    },
    {
      title: 'Apply For IDO',
      to: RoutesPaths.APPLY_FOR_IDO,
    },
    {
      title: 'Join Our Network',
      to: RoutesPaths.JOIN_NETWORK,
    },
    //TODO: temporary hidden until Bug Bounty is implemented
    /*{
      title: 'Bug Bounty',
      href: '#',
    },*/
  ],
  developers: [
    /*TODO: temporary hidden until Gitbook is updated*/
    /*{
      title: 'Documentation',
      href: 'https://app.gitbook.com/@firestarter-1/s/firestarter/'
    },*/
    // {
    //   title: 'Press Kit',
    //   to: RoutesPaths.PRESS_KIT,
    // },
  ],
  governance: [
    {
      title: 'User Agreement',
      to: RoutesPaths.USER_AGREEMENT,
    },
    {
      title: 'Privacy Policy',
      to: RoutesPaths.PRIVACY,
    },
  ],
  help: [
    {
      title: 'Support',
      href: `mailto:${infoEmail}`,
    },
    {
      title: 'FAQ',
      to: RoutesPaths.FAQ,
    },
    {
      title: 'KYC Process',
      to: RoutesPaths.KYC_PROCESS,
    },
    {
      title: `Add ${defaultEnvironmentNetworkData.userFriendlyName} to Metamask`,
      to: RoutesPaths.ADD_NETWORK_GUIDE,
    },
  ],
}

const getFooterListItem = (item: FooterLink): React.ReactNode => (
  <li key={item.title} className="footer__list-item">
    {!!item.to ? (
      <Link to={item.to}>{item.title}</Link>
    ) : (
      <a href={item.href} target="_blank" rel="noopener noreferrer">
        {item.title}
      </a>
    )}
  </li>
)

export const Footer = () => {
  const year = new Date().getFullYear()

  return (
    <footer className="footer">
      <Container>
        <Row className="justify-content-between">
          <Col xs={{ span: 12 }} xl={{ span: 3 }}>
            <div className="footer__logo">
              <Link to={RoutesPaths.MAIN}>
                <img src={logo} alt="Logo" />
              </Link>
            </div>
            <div className="footer__token-address token-address mt-4">
              <Image
                width={32}
                height={32}
                src="/networks/zksync.png"
                className="token-address__network"
              />
              <Copiable text={tokenAddresses.zkstToken}>
                <span className="token-address__address">
                  {shorterETHAddress(tokenAddresses.zkstToken)}
                </span>
                <CopyIcon className="token-address__copy" />
              </Copiable>
              <MetamaskIcon className="token-address__metamask" />
            </div>
          </Col>
          <Col xs={{ span: 4 }} xl={{ span: 2 }}>
            <div className="footer__col">
              <h4 className="footer__list-title">Company</h4>
              <ul className="footer__list">
                {footerLinks.company.map((item) => getFooterListItem(item))}
              </ul>
            </div>
          </Col>
          <Col xs={{ span: 4 }} xl={{ span: 2 }}>
            <div className="footer__col">
              {/*<h4 className="footer__list-title">Developers</h4>*/}
              {/*<ul className="footer__list">*/}
              {/*  {footerLinks.developers.map((item) => getFooterListItem(item))}*/}
              {/*</ul>*/}
              <h4 className="footer__list-title">Governance</h4>
              <ul className="footer__list">
                {footerLinks.governance.map((item) => getFooterListItem(item))}
              </ul>
            </div>
          </Col>
          <Col xs={{ span: 4 }} xl={{ span: 2 }}>
            <div className="footer__col">
              <h4 className="footer__list-title">Help</h4>
              <ul className="footer__list">
                {footerLinks.help.map((item) => getFooterListItem(item))}
              </ul>
            </div>
          </Col>
          <Col xs={{ span: 12 }} xl={{ span: 3 }}>
            <div className="footer__col footer__col--socials">
              <h4 className="footer__list-title">Social</h4>
              <div className="footer__col-content">
                <ul className="footer__socials">
                  {socialLinks.map((item) => (
                    <li key={item.href} className="footer__socials-item">
                      <a
                        href={item.href}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <DynamicImage path={`socials/${item.icon}.svg`} />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="copyrights-row justify-content-center">
          <Col>
            <div className="footer-copyrights text-center">
              <span>©{year} ZKStudios &#8226; </span>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
