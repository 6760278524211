import { OGProperties, PageTags } from '../types';
import { RoutesPaths } from '@router/constants';

const title = 'Join Our Network'
const url = window.location.origin + RoutesPaths.JOIN_NETWORK

export const joinNetwork: PageTags = {
  title: `| ${title}`,
  url: url,
  properties: [
    {
      property: OGProperties.title,
      content: `FireStarter | ${title}`,
    },
    {
      property: OGProperties.description,
      content: 'Join Our FireStarter Network to be in touch.',
    },
    {
      property: OGProperties.url,
      content: url,
    },
  ]
}
