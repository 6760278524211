import { secondsToDays } from '@utils/dates';
import { SECOND } from '@constants';

export function getReadablePeriod(periodInMillis: number) {
  const periodInDays = secondsToDays(periodInMillis / SECOND)
  if (periodInDays >= 365) {
    const years = periodInDays / 365
    return years + ` Year${years > 1 ? 's' : ''}`
  }
  return periodInDays + ' Days'
}
