import React from 'react';
import { PortfolioItem } from '../PortfolioItem';
import './PortfolioList.css'
import { NormalizedProjectInfo } from '@components/Project/types';
import { LoadingWrap } from '@components/common/LoadingWrap';

interface Props {
  projects?: NormalizedProjectInfo[]
}

export const PortfolioList = ({ projects }: Props) => {

  return (
    <div className="portfolio-list">
      <ul className='portfolio-list__header'>
        <li>Project</li>
        <li>Total tokens</li>
        <li>Unvested</li>
        <li>Claimed</li>
        <li>Claim</li>
        <li>Action</li>
      </ul>
      <div className='portfolio-list__body'>
        <LoadingWrap loading={!projects}>
          {
            !!projects && projects.length
              ? projects.map(project => (
                <PortfolioItem key={project.id} project={project} />
              )
              ) : (
                <div className="tile no-projects-tile">
                  <div className="dots">
                    <div/>
                    <div/>
                    <div/>
                  </div>
                  <p className="tile__description">
                    The projects you are participating in will appear here
                  </p>
                </div>
              )
          }
        </LoadingWrap>
      </div>
      <div className="tile no-projects-tile hidden">
        <div className="dots">
          <div/>
          <div/>
          <div/>
        </div>
        <p className="tile__description">
          The projects you are participating in will appear here
        </p>
      </div>
    </div>
  )
}
