import { instance as API } from '../config';
import { SubscribeFormValues } from '@components';
import { SubscriptionResponse } from './types';
import { sendExceptionReport } from '@utils/errors';

export const subscribe = async (values: SubscribeFormValues): Promise<SubscriptionResponse> => {
  try {
    const { data } = await API.put('/subscribe', values)
    return {
      result: 'success',
      jobId: data.jobId
    }
  } catch (err) {
    sendExceptionReport(err)
    return {
      result: 'error',
      error: err.message
    }
  }
}
