import { useDecimals, useTokenBalance } from './useBalances';
import { tokenAddresses } from '../address';
import { useCallback, useEffect, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';
import { useLpStakingContract, useLpTokenContract } from './useContracts';
import { useWeb3 } from '@hooks/useWeb3';
import { useWeb3React } from '@web3-react/core';
import {
  differenceInDays,
  differenceInSeconds,
  formatDistance,
  isPast,
  toDate
} from 'date-fns';
import { balanceToNumber } from '@utils/balanceFormatter';
import { NotifyTxCallbacks } from '../notify';
import { sendExceptionReport } from '@utils/errors';
import { COINGECKO_FLAME_ID, COINGECKO_USDC_ID, coingeckoRetryTimeout, getPricesByTokenIds } from '@api/prices';
import { useTransactions } from './useTransactions';
import { useIsMounted } from '@hooks/useIsMounted';
import { BlockNumber, TransactionReceipt } from 'web3-core';
import { retry } from '@utils/promises';
import { Contract } from 'web3-eth-contract';
import { useNetwork } from '@hooks/useNetwork';

interface StakingUserInfoResponse {
  amount: string
  rewardDebt: string
  lastDepositedAt: string
}

interface LoadStatsReturns {
  totalStakedAmount: BigNumber
  flamePerSecond: BigNumber
  startTime: number
  stakingPeriod: number
  stakingInProgress: boolean
  totalRewardsAmount: BigNumber
}

export interface StakingStats {
  totalRewards: BigNumber
  totalStaked: BigNumber
  lockedRewards: BigNumber
  unlockedRewards: BigNumber
  programDuration?: string
}

const initialStats: StakingStats = {
  totalRewards: new BigNumber(0),
  totalStaked: new BigNumber(0),
  lockedRewards: new BigNumber(0),
  unlockedRewards: new BigNumber(0),
  programDuration: undefined,
}

export const useStaking = () => {
  const isMountedRef = useIsMounted()
  const web3 = useWeb3()
  const { account } = useWeb3React()
  const {
    isDefaultNetworkSelected
  } = useNetwork()
  const {
    callTransaction,
    sendTransaction
  } = useTransactions()

  const lpTokenContract = useLpTokenContract()
  const lpBalance = useTokenBalance(isDefaultNetworkSelected ? tokenAddresses.lpToken : undefined)
  const stakingContract = useLpStakingContract()
  const [loading, setLoading] = useState(false)
  const [blockNumber, setBlockNumber] = useState<BlockNumber>('latest')
  const [isStakingActive, setStakingActive] = useState(false)

  const [zkstPrice, setZkstPrice] = useState(0)
  const [lpTokenPrice, setLpTokenPrice] = useState(0)

  const [rewards, setRewards] = useState(new BigNumber(0))
  const [staked, setStaked] = useState(new BigNumber(0))
  const [lastStaked, setLastStaked] = useState(new Date())

  const [totalStaked, setTotalStaked] = useState(new BigNumber(0))
  const [totalRewards, setTotalRewards] = useState(new BigNumber(0))
  const [rewardsPerSecond, setRewardsPerSecond] = useState(new BigNumber(0))
  const [stakingStartMillis, setStakingStart] = useState(0)
  const [stakingDurationMillis, setStakingDuration] = useState(0)

  const lpDecimals = useDecimals(isDefaultNetworkSelected ? tokenAddresses.lpToken : undefined)
  const zkstDecimals = useDecimals(isDefaultNetworkSelected ? tokenAddresses.zkstToken : undefined)
  const usdcDecimal = 6

  const stakingStats = useMemo<StakingStats>(() => {
    const lastDateOfProgram = new Date(stakingStartMillis + stakingDurationMillis)
    const leftSecondsOfProgram = isPast(lastDateOfProgram)
      ? 0
      : differenceInSeconds(
        lastDateOfProgram,
        isPast(stakingStartMillis) ? new Date() : stakingStartMillis,
      )

    const lockedRewards = rewardsPerSecond.multipliedBy(new BigNumber(leftSecondsOfProgram))
    const unlockedRewards = totalRewards.minus(lockedRewards)

    const programDuration = isStakingActive
      ? formatDistance(
        lastDateOfProgram,
        new Date()
      )
      : undefined;

    return {
      ...initialStats,
      totalStaked,
      totalRewards,
      lockedRewards,
      unlockedRewards,
      programDuration
    }
  }, [
    totalStaked,
    totalRewards,
    rewardsPerSecond,
    stakingStartMillis,
    stakingDurationMillis,
    isStakingActive
  ])

  const APY = useMemo(() => {
    const flamePerSecondNumber = balanceToNumber(rewardsPerSecond, zkstDecimals)
    const totalStakedNumber = balanceToNumber(totalStaked, lpDecimals)
    const yearInSeconds = 365 * 24 * 3600

    const annualRewardsValue = flamePerSecondNumber * zkstPrice * yearInSeconds
    const stakedValue = totalStakedNumber * lpTokenPrice
    const notRoundedAPY = annualRewardsValue / (stakedValue || 1)
    return Math.floor(notRoundedAPY * 10000) / 100
  }, [rewardsPerSecond, totalStaked, zkstDecimals, lpDecimals, zkstPrice, lpTokenPrice])

  const currentPenalty = useMemo(() => {
    if (balanceToNumber(rewards, zkstDecimals) <= 0) {
      return new BigNumber(0)
    }
    if (differenceInDays(new Date(), lastStaked) < 30) {
      return rewards.div(2)
    }
    return new BigNumber(0)
  }, [rewards, lastStaked, zkstDecimals])

  const resetUserInfo = () => {
    setRewards(new BigNumber(0))
    setStaked(new BigNumber(0))
    setLastStaked(new Date())
  }

  const loadUserInfo = useCallback(async () => {
    if (!account || !stakingContract) {
      resetUserInfo()
      return
    }

    try {
      await retry(() => Promise.all([
        callTransaction(
          stakingContract.methods.userInfo(account),
          blockNumber
        ).then(({ amount, lastDepositedAt }) => {
          if (isMountedRef.current) {
            setStaked(new BigNumber(amount))
            if (+lastDepositedAt) {
              setLastStaked(toDate(+lastDepositedAt * 1000))
            }
          }
        }),
        callTransaction(
          stakingContract.methods.pendingFlame(account),
          blockNumber
        ).then(result => {
          isMountedRef.current && setRewards(new BigNumber(result))
        })
      ]))
    } catch (err) {
      sendExceptionReport(err)
      isMountedRef.current && resetUserInfo()
    }

  }, [stakingContract, account, web3, isMountedRef, blockNumber])

  const resetStats = () => {
    setTotalStaked(new BigNumber(0))
    setTotalRewards(new BigNumber(0))
    setRewardsPerSecond(new BigNumber(0))
    setStakingStart(0)
    setStakingDuration(0)
    setStakingActive(false)
  }

  const getLpTokenPrice = async (
    lpTokenContract: Contract,
    flamePrice: number,
    usdcPrice: number,
  ): Promise<number> => {
    const totalReserve = await lpTokenContract.methods.getReserves().call();
    const flameReserve = balanceToNumber(new BigNumber(totalReserve[0]), zkstDecimals)
    const usdcReserve = balanceToNumber(new BigNumber(totalReserve[1]), usdcDecimal)
    const totalSupply = await lpTokenContract.methods.totalSupply().call();
    const totalSupplyNumber = balanceToNumber(new BigNumber(totalSupply), lpDecimals)
    return (flameReserve * flamePrice + usdcReserve * usdcPrice) / (totalSupplyNumber || 1)
  };

  const loadPrices = useCallback(async () => {
    if (!lpTokenContract) {
      return
    }
    const prices = await retry(
      () => getPricesByTokenIds([
        COINGECKO_FLAME_ID,
        COINGECKO_USDC_ID
      ]),
      3,
      coingeckoRetryTimeout
    )

    const flamePriceAmount = prices[COINGECKO_FLAME_ID]
    const usdcPrice = prices[COINGECKO_USDC_ID]
    const lpTokenPriceAmount = await getLpTokenPrice(
      lpTokenContract,
      flamePriceAmount,
      usdcPrice
    )

    setZkstPrice(flamePriceAmount)
    setLpTokenPrice(lpTokenPriceAmount)
  }, [lpTokenContract])

  const loadStatsPromise = useCallback(async (): Promise<LoadStatsReturns> => {
    const _lpTokenContract = lpTokenContract as Contract
    const _stakingContract = stakingContract as Contract

    let totalStakedAmount = new BigNumber(0)
    let flamePerSecond = new BigNumber(0)
    let startTime = 0
    let stakingPeriod = 0
    let stakingInProgress = false
    let totalRewardsAmount = new BigNumber(0)

    let batch = new web3.BatchRequest()

    await retry(() => new Promise<void>((resolve, reject) => {
      batch.add(
        _stakingContract.methods.isStakingInProgress().call.request(
          { from: account },
          (error: Error, result: boolean) => {
            if (error) {
              return reject(error)
            }
            stakingInProgress = result
          }
        )
      )
      batch.add(
        _lpTokenContract.methods.balanceOf(tokenAddresses.lpStaking).call.request(
          { from: account },
          (error: Error, result: string) => {
            if (error) {
              return reject(error)
            }
            totalStakedAmount = new BigNumber(result)
          }
        )
      )
      batch.add(
        _stakingContract.methods.flamePerSecond().call.request(
          { from: account },
          (error: Error, result: string) => {
            if (error) {
              return reject(error)
            }
            flamePerSecond = new BigNumber(result)
          }
        )
      )
      batch.add(
        _stakingContract.methods.totalRewards().call.request(
          { from: account },
          (error: Error, result: string) => {
            if (error) {
              return reject(error)
            }
            totalRewardsAmount = new BigNumber(result)
          }
        )
      )
      batch.add(
        _stakingContract.methods.startTime().call.request(
          { from: account },
          (error: Error, result: string) => {
            if (error) {
              return reject(error)
            }
            startTime = +result * 1000
          }
        )
      )
      batch.add(
        _stakingContract.methods.stakingPeriod().call.request(
          { from: account },
          (error: Error, result: string) => {
            if (error) {
              return reject(error)
            }
            stakingPeriod = +result * 1000
            resolve()
          }
        )
      )

      batch.execute()
    }))

    return {
      totalStakedAmount,
      flamePerSecond,
      startTime,
      stakingPeriod,
      stakingInProgress,
      totalRewardsAmount
    }
  }, [lpTokenContract, stakingContract, web3])

  const loadStats = useCallback(async () => {
    if (!stakingContract || !lpTokenContract) {
      resetStats()
      return
    }
    try {
      const {
        totalStakedAmount,
        flamePerSecond,
        startTime,
        stakingPeriod,
        stakingInProgress,
        totalRewardsAmount,
      } = await loadStatsPromise()

      if (isMountedRef.current) {
        setTotalStaked(totalStakedAmount)
        setRewardsPerSecond(flamePerSecond)
        setStakingStart(startTime)
        setStakingDuration(stakingPeriod)
        setStakingActive(stakingInProgress)
        setTotalRewards(totalRewardsAmount)
      }
    } catch (err) {
      sendExceptionReport(err)
      isMountedRef.current && resetStats()
    }
  }, [stakingContract, lpTokenContract, web3, isMountedRef])

  useEffect(() => {
    if (!loading && account) {
      loadUserInfo()
    }
  }, [account, loading, blockNumber, stakingContract])

  useEffect(() => {
    if (!loading) {
      loadStats()
      loadPrices()
    }
  }, [loading, stakingContract, blockNumber, lpTokenContract])

  const onStake = useCallback(async (
    amount: string,
    callbacks: NotifyTxCallbacks = {}
  ) => {
    if (!account || !stakingContract) {
      return
    }
    setLoading(true)

    const receipt = await sendTransaction(
      await stakingContract.methods.deposit(amount, account),
      callbacks
    ) as TransactionReceipt

    setBlockNumber(receipt.blockNumber)
    setLoading(false)
  }, [account, stakingContract, sendTransaction])

  const onUnstake = useCallback(async (
    amount: string,
    callbacks: NotifyTxCallbacks = {}
  ) => {
    if (!account || !stakingContract) {
      return
    }
    setLoading(true)

    const receipt = await sendTransaction(
      await stakingContract.methods.withdraw(amount, account),
      callbacks
    ) as TransactionReceipt

    setBlockNumber(receipt.blockNumber)
    setLoading(false)
  }, [account, stakingContract, sendTransaction])

  const onClaim = useCallback(async (
    callbacks: NotifyTxCallbacks = {}
  ) => {
    if (!account || !stakingContract) {
      return
    }
    setLoading(true)

    const receipt = await sendTransaction(
      await stakingContract.methods.harvest(account),
      callbacks
    ) as TransactionReceipt

    setBlockNumber(receipt.blockNumber)
    setLoading(false)
  }, [account, stakingContract, sendTransaction])

  return {
    isStakingActive,
    rewardsPerSecond,
    lpBalance,
    rewards,
    staked,
    APY,
    lastStaked,
    currentPenalty,
    stakingStats,
    lpDecimals,
    zkstDecimals,
    onStake,
    onUnstake,
    onClaim
  }
}
