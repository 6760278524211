import { OGProperties, PageTags } from '../types';
import { defaultEnvironmentNetworkData } from '@contracts/networks';
import { RoutesPaths } from '@router/constants';

const title = `Add ${defaultEnvironmentNetworkData.userFriendlyName} to Metamask`
const url = window.location.origin + RoutesPaths.ADD_NETWORK_GUIDE

export const addNetwork: PageTags = {
  title: `| ${title}`,
  url: url,
  properties: [
    {
      property: OGProperties.title,
      content: `FireStarter | ${title}`,
    },
    {
      property: OGProperties.description,
      content: `Quick guide how to add ${defaultEnvironmentNetworkData.userFriendlyName} to Metamask.`,
    },
    {
      property: OGProperties.url,
      content: url,
    },
  ]
}
