import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import './Splash.scss';
import { ReactComponent as Logo } from '@assets/logo.svg';

interface Props {
  show: boolean
}

export const Splash: React.FC<Props> = React.memo(({ show }) => {

  const SplashScreen: React.FC = React.memo(() => {
    const splashRef = useRef<HTMLDivElement | null>(null)

    const hide = () => {
      if (splashRef.current && !show) {
        splashRef.current!.style.opacity = '0'
        setTimeout(() => {
          splashRef.current!.style.visibility = 'hidden';
        }, 400)
      }
    }

    useEffect(() => {
      hide()
    }, [show])

    return (
      <div ref={splashRef} className="splash-screen">
        <div className="splash-screen__logo">
          <Logo/>
        </div>
      </div>
    )
  })

  useEffect(() => {
    if (show) {
      document.querySelector('body')?.classList.add('modal-open')
    } else {
      document.querySelector('body')?.classList.remove('modal-open')
    }
  }, [show])

  return ReactDOM.createPortal(
    <SplashScreen />,
    document.querySelector('body')!
  )
})
