import { NormalizedProjectInfo } from '@components/Project/types';
import { KYCStatus } from '@api/kyc/types';
import { GasPriceOptions } from '@api/gas-stations/types';
import { PlatformSettings } from '@hooks/usePlatformSettings';

export enum ActionType {
  SET_LOADING,
  SET_PLATFORM_SETTINGS,
  SET_PROJECTS,
  SET_WHITElLISTED_PROJECTS,
  SET_PROJECT,
  SET_KYC_DATA,
  SET_GAS_PRICE,
}

export interface SetLoading {
  type: ActionType.SET_LOADING
  payload?: boolean
}

export interface SetPlatformSettings {
  type: ActionType.SET_PLATFORM_SETTINGS,
  payload: PlatformSettings
}

export interface SetProjects {
  type: ActionType.SET_PROJECTS
  payload: NormalizedProjectInfo[]
}

export interface SetWhitelistedProjects {
  type: ActionType.SET_WHITElLISTED_PROJECTS,
  payload: NormalizedProjectInfo[]
}

export interface SetProject {
  type: ActionType.SET_PROJECT
  payload: NormalizedProjectInfo | null
}

export interface SetKYCData {
  type: ActionType.SET_KYC_DATA
  payload: {
    status: KYCStatus | null
    referenceId: string | null
  }
}

export interface SetGasPrice {
  type: ActionType.SET_GAS_PRICE
  payload: GasPriceOptions
}

export type StateAction =
  SetLoading |
  SetPlatformSettings |
  SetProjects |
  SetWhitelistedProjects |
  SetProject |
  SetKYCData |
  SetGasPrice

export interface KYCData {
  status: KYCStatus | null | undefined
  referenceId: string | null
}

export interface StateGasPrice extends GasPriceOptions {}
export type StateLoading = boolean
export type StateProjects = NormalizedProjectInfo[] | null
export type StateCurrentProject = NormalizedProjectInfo | null
export type StatePlatformSettings = PlatformSettings | null

export interface AppState {
  loading: StateLoading
  settings: StatePlatformSettings
  projects: StateProjects
  whitelistedProjects: StateProjects
  currentProject: StateCurrentProject
  KYC: KYCData
  gasPrices: StateGasPrice
}
