import React from 'react';
import './WhitelistStatusView.css'
import { Container } from 'react-bootstrap';
import { WhitelistStatus, WhitelistStatuses } from '@contracts/hooks/useWhitelist';
import { DynamicImage } from '@components/common/DynamicImage';
import { supportEmail } from '@constants';

interface Props {
  status: WhitelistStatus
}

export const WhitelistStatusView = ({ status }: Props) => {

  return (
    <section className="whitelist-status-view">
      <div className='background'>
        <div className='ellipse21' />
      </div>
      <Container className="d-flex justify-content-center align-items-center">
        <div className='tile'>
          <div className='whitelist-status-content'>
            <h4 className='tile__main'>
              {status === WhitelistStatuses.rejected
                ? 'Your application has been rejected'
                  : status === WhitelistStatuses.passed
                  ? 'Your application was successful'
                    : 'Your application is under review'
              }
            </h4>
            <p className='tile__description'>
              {status === WhitelistStatuses.rejected
                ? <>Please, contact us via email <a href={`mailto:${supportEmail}`}>{supportEmail}</a> for more details.</>
                : status === WhitelistStatuses.passed
                  ? 'Your wallet has been whitelisted. We will notify you when the pre-sale starts.'
                  : 'Please check back to view your status once whitelisting is complete.'
              }
            </p>
          </div>
          {status !== WhitelistStatuses.in_review &&
            <DynamicImage path={`whitelist-statuses/${status}.svg`} className='whitelist-status-image'/>
          }
        </div>
      </Container>
    </section>
  )
}
