import React from 'react';
import './PressKit.css'
import { RoundButton, SEOTags, StaticPageWrapper } from '../../../components';
import { Container, Row, Col } from 'react-bootstrap';
import { FSBrandBook, FSGraphicAssets, FSPlatformScreenshots, infoEmail } from '../../../constants';
import BrandBookImage from '../../../assets/press-kit/brand-book-bg.png'
import AssetsImage from '../../../assets/press-kit/assets-bg.png'
import ScreenshotsImage from '../../../assets/press-kit/screenshots-bg.png'
import { seoTags } from '../../../seo-content';

const assetsList = [
  {
    title: <>ZKStudios <br/> Brand Book</>,
    background: BrandBookImage,
    link: FSBrandBook
  },
  {
    title: <>Video and <br/> Graphic Assets</>,
    background: AssetsImage,
    link: FSGraphicAssets
  },
  {
    title: <>Platform <br/> Screenshots</>,
    background: ScreenshotsImage,
    link: FSPlatformScreenshots
  }
]

export const PressKit = () => {

  return (
    <StaticPageWrapper title="ZKStudios Press Kit">
      <SEOTags { ...seoTags.pressKit } />
      <section className="press-kit marketing-page__text">
        <Container>
          <h2 className='title'>Download our press assets here</h2>
          <p>
            For media inquiries please email <a className="info-email" href={`mailto:${infoEmail}`}>{infoEmail}</a>
          </p>
          <Row className="press-kit__list g-4">
            {
              assetsList.map(item => (
                <Col key={item.link} xs={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 4 }} className="flex-grow-1">
                  <div
                    className='press-kit__item tile tile--with-shadow'
                    style={{
                      backgroundImage: `url(${item.background})`
                    }}
                  >
                    <h2 className='title'>{item.title}</h2>
                    <RoundButton
                      size="large"
                      wide
                      color="DARK"
                      href={item.link}
                    >
                      Download
                    </RoundButton>
                  </div>
                </Col>
              ))
            }
          </Row>
        </Container>
      </section>
    </StaticPageWrapper>
  )
}
