import { OGProperties, PageTags } from '../types';
import { RoutesPaths } from '@router/constants';

const title = 'Apply for IDO'
const url = window.location.origin + RoutesPaths.APPLY_FOR_IDO

export const ido: PageTags = {
  title: `| ${title}`,
  url: url,
  properties: [
    {
      property: OGProperties.title,
      content: `FireStarter | ${title}`,
    },
    {
      property: OGProperties.description,
      content: 'Apply for IDO to launch your project on FireStarter',
    },
    {
      property: OGProperties.url,
      content: url,
    },
  ]
}
