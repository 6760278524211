import React from 'react';
import './ApprovalSteps.css'
import classNames from "classnames";

interface Props {
  fillingCondition: boolean
}

export const ApprovalSteps = ({fillingCondition}: Props) => {

  return (
    <div className="approval-steps">
      <span className={classNames('approval-steps__label', { active: !fillingCondition})}>Step 1</span>
      <div className="approval-steps__lines">
        <div className={classNames('approval-steps__line', { filled: !fillingCondition})} />
        <div className={classNames('approval-steps__line', { filled: fillingCondition})} />
      </div>
      <span className={classNames('approval-steps__label', { active: fillingCondition})}>Step 2</span>
    </div>
  )
}
