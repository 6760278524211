export const supportEmail = 'info@zkstudios.xyz'
export const infoEmail = 'info@zkstudios.xyz'
export const socialLinks = [
  {
    icon: 'twitter',
    href: 'https://twitter.com/zkstudios_xyz',
  },
  {
    icon: 'telegram',
    href: 'https://t.me/zkstudios',
  },
]