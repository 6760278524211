import React, { useMemo } from 'react';
import { getNetworkIcon, NetworkId, NetworkInfo } from '@contracts/networks';
import classNames from 'classnames';
import { useNetwork } from '@hooks/useNetwork';
import { Image } from 'react-bootstrap';
import { AddNetworkButton } from '../AddNetworkButton';
import './WrongNetworkBlock.scss'

interface Props {
  expectedNetwork: NetworkId | NetworkInfo
  prefix: string
  embedded?: boolean
}

const WrongNetworkBlock: React.FC<Props> = ({
  expectedNetwork,
  prefix,
  embedded
}) => {
  const {
    getNetwork,
  } = useNetwork()

  const networkInfo = useMemo(() => {
    if (typeof expectedNetwork === 'string') {
      return getNetwork(expectedNetwork)
    }
    return expectedNetwork
  }, [expectedNetwork])

  return (
    <div
      className={classNames({
        'wrong-network-block': true,
        embedded
      })}
    >
      <Image
        roundedCircle={true}
        className="wrong-network-block__icon"
        src={getNetworkIcon(networkInfo)}
      />
      <div className='wrong-network-block__text'>
        {prefix}{' '}
        switch to <b>{networkInfo.userFriendlyName} network</b>
      </div>
      <AddNetworkButton
        className="wrong-network-block__button"
        size="large"
        networkId={networkInfo.networkId}
      />
    </div>
  )
}

WrongNetworkBlock.defaultProps = {
  embedded: true
}

export { WrongNetworkBlock }
