import TooltipIcon from '@assets/tooltip-icon.svg';
import { Image } from 'react-bootstrap';
import { DynamicImage } from '@components';
import { lotteryTicketPrice } from '@contracts/hooks/useZKTier/constants';
import { usePlatformSettings } from '@hooks/usePlatformSettings';
import { numberToCurrency } from '@utils/balanceFormatter';

export const FPRequirementsTooltip = () => {

  return (
    <div className="tiers-tooltip__inner">
      <h5 className='tiers-tooltip__title'>
        <Image src={TooltipIcon} />
        <span>zk Power</span>
      </h5>
      <div className='tiers-tooltip__content'>
        <p>
          zk Power is an aggregator that counts
          all eligible ZKST balances across several sources.
        </p>
      </div>
    </div>
  )
}

export const CooldownPeriodTooltip = () => {

  return (
    <div className="tiers-tooltip__inner">
      <h5 className='tiers-tooltip__title'>
        <Image src={TooltipIcon} />
        <span>Cooldown period</span>
      </h5>
      <div className='tiers-tooltip__content'>
        <p>
          Cooldown period is a 7 days period after an IDO, during which wallets that have successfully invested in IDO,
          are restricted from applying to other IDOs whitelists.
        </p>
        <p>
          <b>Note</b>: It’s impossible to unstake and unlock your ZKST tokens during the 7 days of the Cooldown period.
        </p>
      </div>
    </div>
  )
}

export const SurpriseLotteryTooltip = () => {

  return (
    <div className="tiers-tooltip__inner">
      <h5 className='tiers-tooltip__title'>
        <Image src={TooltipIcon} />
        <span>Community Lottery </span>
      </h5>
      <div className='tiers-tooltip__content'>
        <p>
          We will randomly pick 10 lucky winners of the Lunar Tier and airdrop them an IDO launch prize.
        </p>
      </div>
    </div>
  )
}

export const FCFSLotteryTooltip = () => {
  const { settings } = usePlatformSettings()

  return (
    <div className="tiers-tooltip__inner">
      <h5 className='tiers-tooltip__title'>
        <Image src={TooltipIcon} />
        <span>FCFS Whitelist Lottery</span>
      </h5>
      <div className='tiers-tooltip__content'>
        <p>
          The more ZK Power you have, the higher your allocation and chance to win a spot in a whitelist.
        </p>
        <p>
          Participation in IDOs is on a FCFS basis.
        </p>
        <div className='tiers-tooltip__widget'>
          <div className='d-flex align-items-center tiers-tooltip__expression'>
            <DynamicImage path="zkst.svg" />
            <b>=</b>
            <DynamicImage path="tiers/ticket-single.svg" />
          </div>
          <div className='orange-text'>
            Every {numberToCurrency(lotteryTicketPrice * (settings?.tiersFactor ?? 1))} ZK power <br/>
            = 1 Lottery ticket
          </div>
        </div>
      </div>
    </div>
  )
}

export const GuaranteedAllocationTooltip = () => {

  return (
    <div className="tiers-tooltip__inner">
      <h5 className='tiers-tooltip__title'>
        <Image src={TooltipIcon} />
        <span>Guaranteed Allocation</span>
      </h5>
      <div className='tiers-tooltip__content'>
        <p>
          Top 100 on ZK Leaderboard are qualified for Guaranteed Allocation. The amount depends on
          their Tier.
        </p>
      </div>
    </div>
  )
}
