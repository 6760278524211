import React, { useCallback, useEffect } from 'react';
import { RoutesPaths } from '@router/constants';
import { Link, useRouteMatch } from 'react-router-dom';
import { ReactComponent as Logo } from '@assets/logo.svg';
import { Container, Dropdown, Nav, Navbar } from 'react-bootstrap';
import { Copiable, NetworkSwitcher, RoundButton } from '@components/common';
import { useWeb3React } from '@web3-react/core';
import { shorterETHAddress } from '@utils/string';
import { MetaMask } from '@contracts/connectors';
import { WalletAddress } from '@contracts/address';
import './Header.scss';
import { useNetwork } from '@hooks/useNetwork';
import { useSearchParams } from '@hooks/useSearchParams';
import { HeaderTheme, useHeaderTheme } from "@hooks/useHeaderTheme";

interface Props {}

const switchNetworkQueryParameter = 'switch_network_to'

export const Header = (props: Props) => {
  const { account, activate, active } = useWeb3React();
  const newLockingPath = useRouteMatch(RoutesPaths.ACCOUNT.LOCKING_MULTIPERIOD)
  const stakingPath = useRouteMatch(RoutesPaths.ACCOUNT.STAKING)
  const portfolioPath = useRouteMatch(RoutesPaths.ACCOUNT.MAIN)
  const nftPath = useRouteMatch(RoutesPaths.METAVERSE_NFT.MAIN);
  const projectsPath = useRouteMatch(RoutesPaths.PROJECTS);
  const leadeboardPath = useRouteMatch(RoutesPaths.LEADERBOARD);

  const theme = useHeaderTheme()
  const onClickWallet = () => {
    if (!active) {
      activate(MetaMask);
    }
  };

  const { switchNetwork, checkIfSelected, currentNetworkId } = useNetwork()
  const { query, removeQuery } = useSearchParams()

  const switchNetworkAfterRedirect = useCallback(async () => {
    if (!(switchNetworkQueryParameter in query)) {
      return
    }
    const networkToSwitch = query[switchNetworkQueryParameter]
    if (!checkIfSelected(networkToSwitch)) {
      await switchNetwork(networkToSwitch)
    }
    removeQuery(switchNetworkQueryParameter)
  }, [query, switchNetwork, checkIfSelected, currentNetworkId])

  useEffect(() => {
    if (switchNetworkQueryParameter in query && currentNetworkId) {
      switchNetworkAfterRedirect()
    }
  }, [query, currentNetworkId])

  return (
    <Container as="header" className={`header ${theme.toLowerCase()}`} fluid>
      <Navbar>
        <Navbar.Brand>
          <Link to={RoutesPaths.MAIN}>
            <Logo className="d-inline-block align-top" />
          </Link>
        </Navbar.Brand>
        <Navbar.Collapse className="justify-content-center m-auto">
          <Nav.Link
            as={Link}
            to={RoutesPaths.LEADERBOARD}
            active={leadeboardPath?.isExact}
          >
            Leaderboard
          </Nav.Link>
          {/*<Nav.Link
            as={Link}
            to="/metaverse-nft"
            active={nftPath?.isExact}
          >
            Metaverse NFT
          </Nav.Link>*/}
          <Nav.Link
            as={Link}
            active={projectsPath?.isExact}
            to={RoutesPaths.PROJECTS}
          >
            Projects
          </Nav.Link>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          <Dropdown className="nav-dropdown">
            <Dropdown.Toggle
              as={RoundButton}
              color={theme === HeaderTheme.DARK ? 'LIGHT' : 'TRANSPARENT'}
              className="header__nav-button"
            >
              ACCOUNT
            </Dropdown.Toggle>
            <Dropdown.Menu className="nav-dropdown__menu">
              <Dropdown.Item
                as={Link}
                active={newLockingPath?.isExact}
                to={RoutesPaths.ACCOUNT.LOCKING_MULTIPERIOD}
                className="nav-dropdown__item"
              >
                LOCKUP
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                active={stakingPath?.isExact}
                to={RoutesPaths.ACCOUNT.STAKING}
                className="nav-dropdown__item"
              >
                STAKING
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                active={portfolioPath?.isExact}
                to={RoutesPaths.ACCOUNT.MAIN}
                className="nav-dropdown__item"
              >
                MY PORTFOLIO
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <NetworkSwitcher>
            <RoundButton
              className="header__connect-wallet"
              color={theme === HeaderTheme.DARK ? 'LIGHT' : 'TRANSPARENT'}
              onClick={onClickWallet}
            >
              {active
                ? <Copiable text={account!}>{shorterETHAddress(account as WalletAddress)}</Copiable>
                : <span>CONNECT</span>
              }
            </RoundButton>
          </NetworkSwitcher>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  );
};
