import { OGProperties, PageTags } from '../types';
import { RoutesPaths } from '@router/constants';

const title = 'About Us'
const url = window.location.origin + RoutesPaths.ABOUT_US

export const aboutUs: PageTags = {
  title: `| ${title}`,
  url: url,
  properties: [
    {
      property: OGProperties.title,
      content: `FireStarter | ${title}`,
    },
    {
      property: OGProperties.description,
      content: 'FireStarter core team',
    },
    {
      property: OGProperties.url,
      content: url,
    },
  ]
}
