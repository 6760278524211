import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shorterETHAddress } from '@utils/string'
import LeaderNFT from '@assets/nft/leaders-nft.png'
import { Image } from 'react-bootstrap';
import { Copiable, SimpleTable, TableColumn, TableData } from '@components/common';
import { numberToCurrency } from '@utils/balanceFormatter';
import { ZKTiers, TierAccessTypes } from '@contracts/hooks/useZKTier/constants';
import { WalletAddress } from '@contracts/address';
import './LeaderboardList.scss'
import classNames from 'classnames';
import { LeaderboardData } from '@api/leaderboard/types';
import { getLeaderboard } from '@api/leaderboard';
import LunarTierImage from '@assets/tiers/lunar.svg';
import OrbitTierImage from '@assets/tiers/orbit.svg';
import InterplanetaryTierImage from '@assets/tiers/interplanetary.svg';
import GalacticTierImage from '@assets/tiers/galactic.svg';
import UniversalTierImage from '@assets/tiers/universal.svg';
import { formatDistance } from 'date-fns';

const NFTImage = () => <Image src={LeaderNFT} roundedCircle height={40} width={40} title="HIRO" />

const tierImagesElements = {
  [ZKTiers.Lunar]: <Image src={LunarTierImage} height={40} width={40} title={ZKTiers.Lunar} />,
  [ZKTiers.Orbit]: <Image src={OrbitTierImage} height={40} width={40} title={ZKTiers.Orbit} />,
  [ZKTiers.Interplanetary]: <Image src={InterplanetaryTierImage} height={40} width={40} title={ZKTiers.Interplanetary} />,
  [ZKTiers.Galactic]: <Image src={GalacticTierImage} height={40} width={40} title={ZKTiers.Galactic} />,
  [ZKTiers.Universal]: <Image src={UniversalTierImage} height={40} width={40} title={ZKTiers.Universal} />,
}

const getWalletElement = ({
  ethAddress,
}: {
  ethAddress?: WalletAddress,
}) => {
  return (
    <div className="leaderboard-table__wallet">
      {
        ethAddress && (
          <div>
            <Copiable text={ethAddress}>
              {shorterETHAddress(ethAddress, 6, -6)}
            </Copiable>
          </div>
        )
      }
    </div>
  )
}

const leaderboardColumns: TableColumn[] = [
  {
    id: 'number',
    title: '#',
    headerClass: 'text-center',
    bodyClass: 'text-center',
    width: 60
  },
  {
    id: 'walletAddress',
    title: 'Users',
  },
  {
    id: 'zkstPower',
    title: 'ZK Power',
    headerClass: 'text-center',
    bodyClass: 'text-center'
  },
  {
    id: 'tierImage',
    title: 'Tier',
    headerClass: 'text-center',
    bodyClass: 'ps-5'
  },
  {
    id: 'allocationMultiplier',
    title: 'Allocation Multiplier',
    headerClass: 'text-center',
    bodyClass: 'text-center'
  },
  {
    id: 'access',
    title: 'Access/Tickets',
    headerClass: 'text-center',
    bodyClass: 'text-center orange-text fw-semibold'
  },
  {
    id: 'cooldown',
    title: 'Cooldown',
    headerClass: 'text-center',
    bodyClass: 'text-center'
  },
]

interface LeaderboardTableData extends TableData{
  number: number
  walletAddress: React.ReactNode
  zkstPower: string
  tierImage: React.ReactNode
  allocationMultiplier: string
  access: string | number
  cooldown: number | string
}

const leaderboardListSelections: Record<string, string> = {
  top25: 'Top 25',
  top100: 'Top 100',
  top200: 'Top 200',
}

const leaderboardOptions: Record<string, any> = {
  top25: {
    size: 25,
  },
  top100: {
    size: 100,
  },
  top200: {
    size: 200,
  },
  NFTHolders: {
    size: 200,
    only_nft_holders: true,
  }
}

export const LeaderboardList = () => {
  const [currentSelection, setCurrentSelection] = useState('top25')
  const options = useMemo(() => leaderboardOptions[currentSelection], [currentSelection])
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<LeaderboardData>({
    accounts: [],
    lastUpdatedAt: null
  })

  const loadLeaderboard = useCallback(async () => {
    setLoading(true)
    const result = await getLeaderboard(options)
    setData(result)
    setLoading(false)
  }, [options])

  useEffect(() => {
    loadLeaderboard()
  }, [options])

  const leaders = useMemo<LeaderboardTableData[]>(() => {
    if (!data) return []
    return  data.accounts.map((acc, index) => {
      return {
        number: index + 1,
        walletAddress: getWalletElement({
          ethAddress: acc.walletAddress,
        }),
        zkstPower: numberToCurrency(acc.zkstPower, 0),
        tierImage: <>{acc.tier ? tierImagesElements[acc.tier] : null} {acc.tier}</>,
        allocationMultiplier: acc.allocationMultiplier ? `${acc.allocationMultiplier} x` : 'n/a',
        access: (acc.accessType === TierAccessTypes.GUARANTEED || index < 25)
              ? 'Guaranteed'
              : acc.lotteryTickets,
        cooldown: ''
      }
    })
  }, [data])

  return (
    <div className='leaderboard-list'>
      <div className='leaders-list__header tile tile--secondary'>
        <ul className='leaders-list__selections'>
          {Object.entries(leaderboardListSelections).map(([key, title]) => (
            <li
              key={key}
              className={classNames(
                'leaders-list__selection-item',
                { active: currentSelection === key}
              )}
              onClick={() => setCurrentSelection(key)}
            >
              {title}
            </li>
          ))}
        </ul>
        <div className='leaders-list__update-time'>
          <span className='text-simple grey-text'>
            Last updated:{' '}
          </span>
          <span className='text-simple'>
            {data.lastUpdatedAt ? `${formatDistance(new Date(), data.lastUpdatedAt)} ago` : '--' }
          </span>
        </div>
      </div>
      <SimpleTable
        wrapperClass='leaderboard-table-wrapper'
        tableClass='leaderboard-table'
        columns={leaderboardColumns}
        data={leaders}
        loading={loading}
      />
    </div>
  )
}
