import { NetworkId, NetworksIDs } from '../networks';
import { GasPriceOptions, GasPriceOracleCall } from '@api/gas-stations/types';
import {
  getBNBGasPrice,
  getBNBTGasPrice,
  getEthGasPrice,
  getMumbaiGasPrice,
  getPolygonGasPrice,
  getRinGasPrice
} from '@api/gas-stations';
import { useCallback, useMemo } from 'react';
import { useDispatch } from '@hooks/useDispatch';
import { useSelector } from '@hooks/useSelector';
import { ActionType, StateGasPrice } from '@store/types';
import { sendExceptionReport } from '@utils/errors';
import { useNetwork } from '@hooks/useNetwork';
import { getAnyGasPrice } from '@api/gas-stations/common'

const oracles: Record<NetworkId, GasPriceOracleCall> = {
  [NetworksIDs.maticmum]: getMumbaiGasPrice,
  [NetworksIDs.matic]: getPolygonGasPrice,
  [NetworksIDs.eth]: getEthGasPrice,
  [NetworksIDs.rin]: getRinGasPrice,
  [NetworksIDs.bnb]: getBNBGasPrice,
  [NetworksIDs.bnbt]: getBNBTGasPrice,
}

const initialGasPrices: GasPriceOptions = {
  safeLow: 0,
  standard: 0,
  fast: 0,
  fastest: 0
}

const gasPriceMultiplier = parseFloat(process.env.REACT_APP_GAS_PRICE_MULTIPLIER || '1.2')
const ethGasPriceMultiplier = process.env.REACT_APP_ETH_GAS_PRICE_MULTIPLIER
  ? parseFloat(process.env.REACT_APP_ETH_GAS_PRICE_MULTIPLIER)
  : gasPriceMultiplier

const networksGasPriceMultipliers: Record<NetworkId, number> = {
  [NetworksIDs.matic]: gasPriceMultiplier,
  [NetworksIDs.eth]: ethGasPriceMultiplier,
  [NetworksIDs.rin]: ethGasPriceMultiplier,
}

const gasMultiplier = parseFloat(process.env.REACT_APP_GAS_MULTIPLIER || '1.2')
const ethGasMultiplier = process.env.REACT_APP_ETH_GAS_MULTIPLIER
  ? parseFloat(process.env.REACT_APP_ETH_GAS_MULTIPLIER)
  : gasMultiplier

const networksGasMultipliers: Record<NetworkId, number> = {
  [NetworksIDs.matic]: gasMultiplier,
  [NetworksIDs.eth]: ethGasMultiplier,
  [NetworksIDs.rin]: ethGasMultiplier,
}

const networksGasPriceRate: Record<NetworkId, 'safeLow' | 'standard' | 'fast' | 'fastest'> = {
  [NetworksIDs.bnb]: 'standard',
  [NetworksIDs.bnbt]: 'standard',
  [NetworksIDs.eth]: 'standard',
  [NetworksIDs.rin]: 'standard',
  [NetworksIDs.matic]: 'fastest',
  [NetworksIDs.maticmum]: 'fastest',
}

const mapGasResponse = ({
  safeLow,
  standard,
  fast,
  fastest
}: GasPriceOptions): GasPriceOptions => ({
  safeLow,
  standard,
  fast,
  fastest
})

export const useGasPrice = () => {
  const dispatch = useDispatch()
  const gasPrices = useSelector<StateGasPrice>(state => state.gasPrices)
  const { currentNetworkId, defaultNetwork } = useNetwork()

  const gasPrice = useMemo(
    () => {
      const multiplier = (currentNetworkId ? networksGasPriceMultipliers[currentNetworkId] : gasPriceMultiplier) ?? gasPriceMultiplier
      const gasPriceRateKey = (currentNetworkId ? networksGasPriceRate[currentNetworkId] : 'fastest') ?? 'fastest'
      const gasPriceBase = gasPrices[gasPriceRateKey]
      // For  zkSync Era it rounds to a float number as far as gas price in gWei is < 1
      return Math.floor(gasPriceBase * multiplier * 1000) / 1000;
    },
    [gasPrices, currentNetworkId]
  )
  const currentGasMultiplier = useMemo(
    () => (currentNetworkId ? networksGasMultipliers[currentNetworkId] : gasMultiplier) ?? gasMultiplier,
    [currentNetworkId]
  )
  const gasPriceCall = useCallback(
    oracles[currentNetworkId ?? defaultNetwork.networkId] ??
    (() => getAnyGasPrice(currentNetworkId ?? defaultNetwork.networkId)),
    [currentNetworkId]
  )

  const getGasPrice = useCallback(async () => {
    try {
      const response = await gasPriceCall()
      dispatch({
        type: ActionType.SET_GAS_PRICE,
        payload: mapGasResponse(response)
      })
    } catch (err) {
      sendExceptionReport(err)
      dispatch({
        type: ActionType.SET_GAS_PRICE,
        payload: initialGasPrices
      })
    }
  }, [gasPriceCall])

  return {
    gasPrice,
    getGasPrice,
    currentGasMultiplier,
  }
}
