import { IUserTier, UserTierResponse } from '@api/tiers/types'
import { toCapitalize, toChecksumAddress } from '@utils/string'
import BigNumber from 'bignumber.js'
import { ZKTier, ZKTiers } from "@contracts/hooks/useZKTier/constants";

export const mapTierResponse = ({
  wallet_address,
  zkst_power,
  tier,
  lottery_tickets,
  access_type,
  allocation_multiplier,
  hiros_amount,
  locked_amount,
  staked_amount,
  multiperiod_locking,
  multiperiod_power,
  old_locked_amount,
  old_staked_amount
}: UserTierResponse): IUserTier => {
  return {
    walletAddress: toChecksumAddress(wallet_address),
    zkstPower: zkst_power,
    tier: tier ? toCapitalize(tier) as ZKTier : tier,
    lotteryTickets: lottery_tickets,
    accessType: access_type,
    allocationMultiplier: allocation_multiplier,
    lockedAmount: new BigNumber(locked_amount),
    stakedAmount: new BigNumber(staked_amount),
    hirosAmount: hiros_amount,
    ...(multiperiod_locking.reduce((acc, item) => {
      acc.multiperiodLockedAmount = acc.multiperiodLockedAmount
        .plus(new BigNumber(item.locked_amount))
      acc.multiperiodRewards = acc.multiperiodRewards
        .plus(new BigNumber(item.rewards))
      return acc
    }, {
      multiperiodLockedAmount: new BigNumber(0),
      multiperiodRewards: new BigNumber(0)
    })),
    multiperiodPower: multiperiod_power,
    oldLockedAmount: new BigNumber(old_locked_amount),
    oldStakedAmount: new BigNumber(old_staked_amount)
  }
}
