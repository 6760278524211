import Parsiq from '../../assets/partners/parsiq.svg'
import Blockpass from '../../assets/partners/blockpass.svg'
import SSB from '../../assets/partners/ssb.svg'
import Chainlink from '../../assets/partners/chainlink.svg'
import TransformGroup from '../../assets/partners/transform-group.svg'
import Kucoin from '../../assets/partners/kucoin.svg'
import zkSync from '../../assets/partners/zkSync.svg'
import CoinGecko from '../../assets/partners/coingecko.svg'
import { tokenAddresses } from "@contracts/address";
import { Copiable } from "@components";

export const partners = [
  {
    image: Blockpass,
    name: 'BlockPass'
  },
  {
    image: SSB,
    name: 'Satoshi Street Bets'
  },
  {
    image: TransformGroup,
    name: 'Transform Group'
  },
  {
    image: Parsiq,
    name: 'Parsiq'
  },
  {
    image: CoinGecko,
    name: 'CoinGecko'
  },
  {
    image: zkSync,
    name: 'zkSync'
  },
  {
    image: Chainlink,
    name: 'Chainlink'
  },
  {
    image: Kucoin,
    name: 'KuCoin'
  },
];

export const faqItems = [
  {
    title: 'How can projects be powered by ZKStudios?',
    body: (
      <p>
        zKStudios platform incubates and works with projects for the longevity of the project.
        We don’t just stop at launch, that is just the beginning.
      </p>
    )
  },
  {
    title: 'What is Token Burn?',
    body: (
      <p>
        A token burn is done by permanently removing some tokens from circulation, thus increasing scarcity. Unlocking
        or unstacking before the 30 day mark will result in penalties. A % of penalties if burned to increase scarcity
      </p>
    )
  },
  {
    title: 'What is the ZKStudios Token Address?',
    body: (
      <p>
        The $ZKST Token Address is
        {' '}
        <Copiable className="fw-medium" text={tokenAddresses.zkstToken}>
          {tokenAddresses.zkstToken}
        </Copiable>
      </p>
    )
  },
  {
    title: 'How do I lock $ZKST?',
    body: (
      <p>
        <b>Step 1.</b> Go to zkstudios.xyz
        <br/>
        <b>Step 2.</b> Connect your Wallet
        <br/>
        <b>Step 3.</b> Go to Account Page
        <br/>
        <b>Step 4.</b> Scroll Down to Lock Section
        <br/>
        <b>Step 5.</b> Determine amount of $ZKST to be locked and click Approve to make sure enough funds are available to participate.
        <br/>
        <b>Step 6.</b> Once approved, you can then lock your $ZKST with the Lock Button and establish your Tier.
      </p>
    )
  },
  //TODO: fill the answer when the Tiers logic is fully defined
  /*{
    title: 'What are the FireStarter Tiers?',
    body: (
      <p>
        Please leave room for Tiers.
      </p>
    )
  },*/
  {
    title: 'How do I choose a ZKStudios Project?',
    body: (
      <>
        <p>
          ZKStudios Projects is where Defi and Culture Collide. Providing backers with a diverse range of investment
          opportunities that have mainstream stay and influence.
        </p>
        <p>
          <b>Step 1.</b> Go to zkstudios.xyz
          <br/>
          <b>Step 2.</b> Connect your Wallet
          <br/>
          <b>Step 3.</b> Go to Projects Page
          <br/>
          <b>Step 4.</b> Select an "In Progress" Project
          <br/>
          <b>Step 5.</b> Review Project's details and hit select to proceed to Whitelisting.
        </p>
      </>
    )
  },
  {
    title: 'How to Apply to Whitelist?',
    body: (
      <>
        <p>
          To ensure safety and security, ZKStuidos implements a Whitelisting process for users wishing to invest in
          ZKStudios Projects. This process should be done before investing in a project. Users are required to go
          through a KYC process, provide additional details, and follow us on our Social Channels.
        </p>
        <p>
          <b>Step 1.</b> Go to zkstudios.xyz
          <br/>
          <b>Step 2.</b> Connect your Wallet
          <br/>
          <b>Step 3.</b> Go to Projects Page
          <br/>
          <b>Step 4.</b> Go to Upcoming Project's Page and Choose Project
          <br/>
          <b>Step 5.</b> Click on Apply to Whitelist
        </p>
      </>
    )
  },
  {
    title: 'How to Swap?',
    body: (
      <>
        <p>
          ZKStudios facilities the peer to peer swapping of tokens that make up our fundraising rounds. Exchange the
          Projects Contribution Token, determined by protocol, for Project Reward Tokens specific to the project.
        </p>
        <p>
          <b>Step 1.</b> Go to zkstudios.xyz
          <br/>
          <b>Step 2.</b> Connect your Wallet
          <br/>
          <b>Step 3.</b> Go to Projects Page
          <br/>
          <b>Step 4.</b> Select an "In Progress" Project
          <br/>
          <b>Step 5.</b> Locate Swap section and enter the amount you wish to contribute.
          <br/>
          <b>Step 6.</b> Click on Approve to ensure enough funds are available.
          <br/>
          <b>Step 7.</b> Confirm by clicking on Swap.
        </p>
      </>
    )
  },
  {
    title: 'How to Claim?',
    body: (
      <p>
        <b>Step 1.</b> Go to ZKStudios website
        <br/>
        <b>Step 2.</b> Connect your wallet
        <br/>
        <b>Step 3.</b> Go to Account Page
        <br/>
        <b>Step 4.</b> Switch to My Portfolio
        <br/>
        <b>Step 5.</b> Locate needed project and click on Claim
        <br/>
        <b>Step 6.</b> Submit transaction in you wallet
      </p>
    )
  },
];
