import { OGProperties, PageTags } from '../types';
import { RoutesPaths } from '@router/constants';

const title = 'Privacy Policy'
const url = window.location.origin + RoutesPaths.PRIVACY

export const privacy: PageTags = {
  title: `| ${title}`,
  url: url,
  properties: [
    {
      property: OGProperties.title,
      content: `FireStarter | ${title}`,
    },
    {
      property: OGProperties.description,
      content: 'Privacy Policy of FireStarter.',
    },
    {
      property: OGProperties.url,
      content: url,
    },
  ]
}
