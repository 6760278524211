import { ActionType, AppState, StateAction } from './types';

export function stateReducer(state: AppState, action: StateAction): AppState {

  if (process.env.NODE_ENV === 'development') {
    console.groupCollapsed(`State Action: ${ActionType[action.type]}`)
    console.log(`Action: ${ActionType[action.type]}`);
    console.log('Prev state: ', state);
    if ('payload' in action) {
      console.log('Payload: ', action.payload);
    }
    console.groupEnd()
  }

  switch(action.type) {
    case ActionType.SET_LOADING:
      return {
        ...state,
        loading: action.payload ?? true
      }
    case ActionType.SET_PLATFORM_SETTINGS:
      return {
        ...state,
        settings: action.payload
      }
    case ActionType.SET_PROJECTS:
      return {
        ...state,
        projects: action.payload,
        loading: false
      }
    case ActionType.SET_WHITElLISTED_PROJECTS:
      return {
        ...state,
        whitelistedProjects: action.payload,
        loading: false
      }
    case ActionType.SET_PROJECT:
      return {
        ...state,
        currentProject: action.payload,
        loading: false
      }
    case ActionType.SET_KYC_DATA:
      return {
        ...state,
        KYC: action.payload,
        loading: false
      }
    case ActionType.SET_GAS_PRICE:
      return {
        ...state,
        gasPrices: action.payload
      }
    default:
      return state
  }
}
