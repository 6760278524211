import { WalletAddress } from '../address';
import BigNumber from 'bignumber.js';
import { useCallback, useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { ProviderRpcError, SECOND } from '@constants';
import { BlockNumber, TransactionReceipt } from 'web3-core';
import { useTransactions } from './useTransactions';
import { NotifyTxCallbacks } from '../notify';
import { useMultiperiodLockingContract } from './useContracts';
import { mapSettledToFulfilled } from '@utils/promises';

interface LockPeriod {
  apy: string
  power: string
  penalty: string
  lockPeriod: string
  fullPenaltyCliff: string
  penaltyMode: number
  isActive: boolean
}

export interface LockPeriodInfo {
  apy: number
  power: number
  penalty: number
  lockPeriod: number
  fullPenaltyCliff: number
  penaltyMode: string
  isActive: boolean
  rewardPenalty?: number
  additionalPenalty?: number
  tierIndex: number
}

interface LockEntry {
  account: WalletAddress
  amount: string
  stakedAt: string
  unstakedAt: string
  tierIndex: string
}

export interface LockEntryInfo {
  account: WalletAddress
  amount: BigNumber
  rewards: BigNumber
  stakedAt: number
  unstakedAt: number
  tierIndex: number
  stakeId: string
}

const additionalPeriodInfos: Record<string, Pick<LockPeriodInfo, 'additionalPenalty' | 'rewardPenalty'>> = {
  '2592000': { // 30 days
  },
  '15552000': { // 180 days
    rewardPenalty: 1,
  },
  '31536000': { // 1 year
    rewardPenalty: 1,
    additionalPenalty: 1,
  },
  '94608000': { // 3 years
    rewardPenalty: 1,
    additionalPenalty: 1,
  },
}

const PENALTY_MODES = [
  'STATIC',
  'LINEAR'
]

export const PERCENTAGE_PRECISION = 100

function mapPeriodResponse(period: LockPeriod, index: number): LockPeriodInfo {
  return {
    apy: +period.apy / PERCENTAGE_PRECISION,
    power: +period.power / PERCENTAGE_PRECISION,
    penalty: +period.penalty / PERCENTAGE_PRECISION,
    lockPeriod: +period.lockPeriod * SECOND,
    fullPenaltyCliff: +period.fullPenaltyCliff * SECOND,
    penaltyMode: PENALTY_MODES[period.penaltyMode],
    isActive: period.isActive,
    rewardPenalty: additionalPeriodInfos[period.lockPeriod]?.rewardPenalty,
    additionalPenalty: additionalPeriodInfos[period.lockPeriod]?.additionalPenalty,
    tierIndex: index
  }
}

function mapEntryResponse(
  entry: LockEntry,
  rewards: BigNumber,
  stakeId: string
): LockEntryInfo {
  return {
    ...entry,
    amount: new BigNumber(entry.amount),
    rewards,
    stakedAt: +entry.stakedAt * SECOND,
    unstakedAt: +entry.unstakedAt * SECOND,
    stakeId,
    tierIndex: +entry.tierIndex
  }
}

interface TransactionResponse<R = any> {
  data: R
}

interface TransactionError {
  error: ProviderRpcError | string
}

export type TransactionResult<R = any> = TransactionResponse<R> | TransactionError

export interface IUseMultiperiodLocking {
  loadingPeriods: boolean
  loadingEntries: boolean
  lockPeriods?: LockPeriodInfo[]
  lockEntries?: Record<string, LockEntryInfo>
  totalLocked: BigNumber
  getPeriods: () => Promise<void>
  getEntries: () => Promise<void>
  getPenalty: (stakeId: string) => Promise<BigNumber>
  getAccumulatedRewards: (stakeId: string) => Promise<BigNumber>
  lock: (amount: string, tierIndex: number, callbacks?: NotifyTxCallbacks) => Promise<TransactionResult>
  unlock: (stakeId: string, callbacks?: NotifyTxCallbacks) => Promise<TransactionResult>
  unlockEarly: (stakeId: string, callbacks?: NotifyTxCallbacks) => Promise<TransactionResult>
  unlockEarlyWithHiro: (stakeId: string, hiroId: string, callbacks?: NotifyTxCallbacks) => Promise<TransactionResult>
}

export type UnlockMethod = IUseMultiperiodLocking['unlock'] |
  IUseMultiperiodLocking['unlockEarly'] |
  IUseMultiperiodLocking['unlockEarlyWithHiro']

export const useMultiperiodLocking = (): IUseMultiperiodLocking => {
  const [loadingPeriods, setLoadingPeriods] = useState(false)
  const [loadingEntries, setLoadingEntries] = useState(false)
  const [lockPeriods, setLockPeriods] = useState<LockPeriodInfo[]>()
  const [lockEntries, setLockEntries] = useState<Record<string, LockEntryInfo>>()
  const [totalLocked, setTotalLocked] = useState<BigNumber>(new BigNumber(0))
  const lockingContract = useMultiperiodLockingContract()
  const [blockNumber, setBlockNumber] = useState<BlockNumber>('latest')
  const { callTransaction, sendTransaction } = useTransactions()
  const { account } = useWeb3React()

  const getPeriods = useCallback(async () => {
    setLoadingPeriods(true)
    let fetchingStep = 1
    let periods: LockPeriodInfo[] = []
    let isFailed: any

    while (!isFailed) {
      let periodsIds = Array.from(Array(4).keys())
      const periodsBunch = await Promise.allSettled(
        periodsIds.map(async (idx) => {
          const id = idx + ((fetchingStep - 1) * 4)
          const period = await callTransaction(
            lockingContract?.methods.tiers(id),
            blockNumber
          ) as LockPeriod
          return mapPeriodResponse(period, id)
        })
      )
      periods = [
        ...periods,
        ...mapSettledToFulfilled(periodsBunch)
      ]
      fetchingStep++
      if (periodsBunch.some(result => result.status === 'rejected')) {
        isFailed = true
      }
    }

    setLockPeriods(periods)
    setLoadingPeriods(false)
  }, [lockingContract, blockNumber, callTransaction])

  const getAccumulatedRewards = useCallback(async (stakeId: string): Promise<BigNumber> => {
    const rewards = await callTransaction(
      lockingContract?.methods.getAccumulatedRewardAmount(stakeId),
      blockNumber
    )
    return new BigNumber(rewards)
  }, [callTransaction, lockingContract, blockNumber])

  const getPenalty = useCallback(async (stakeId: string): Promise<BigNumber> => {
    const penalty = await callTransaction(
      lockingContract?.methods.getPenaltyAmount(stakeId),
      blockNumber
    )
    return new BigNumber(penalty)
  }, [lockingContract, callTransaction, blockNumber])

  const getEntries = useCallback(async () => {
    setLoadingEntries(true)
    const stakeIds = await callTransaction(
      lockingContract?.methods.getUserStakeIds(account),
      blockNumber
    ) as string[]
    const entries = await Promise.all(
      stakeIds.map(async (id) => {
        const [info, rewards] = await Promise.all([
          callTransaction(
            lockingContract?.methods.userStakeOf(id),
            blockNumber
          ),
          getAccumulatedRewards(id)
        ]);
        return mapEntryResponse(info, rewards, id);
      })
    );

    setLockEntries(
      entries.reduce<Record<string, LockEntryInfo>>((acc, item) => {
        acc[item.stakeId] = item
        return acc
      }, {})
    );
    setTotalLocked(
      entries.reduce<BigNumber>((total, item) => {
        return item.unstakedAt === 0 ? total.plus(item.amount) : total
      }, new BigNumber(0))
    )
    setLoadingEntries(false)
  }, [
    lockingContract,
    account,
    blockNumber,
    callTransaction,
    getAccumulatedRewards,
  ])

  useEffect(() => {
    getPeriods()
  }, [lockingContract])

  const lock = useCallback(async (
    amount: string,
    tierIndex: number,
    callbacks: NotifyTxCallbacks = {}
  ): Promise<TransactionResult> => {
    if (!lockingContract) {
      return {
        error: 'No contract initialized. Try again later'
      }
    }

    try {
      const receipt = await sendTransaction(
        lockingContract.methods.stake(amount, tierIndex),
        callbacks
      ) as TransactionReceipt;
      setBlockNumber(receipt.blockNumber);
      return {
        data: receipt.events?.Stake,
      };
    } catch (error) {
      return {
        error,
      };
    }
  }, [lockingContract, sendTransaction])

  const unlock = useCallback(async (
    stakeId: string,
    callbacks: NotifyTxCallbacks = {}
  ) => {
    if (!lockingContract) {
      return {
        error: 'No contract initialized. Try again later'
      }
    }

    try {
      const receipt = await sendTransaction(
        lockingContract.methods.unstake(stakeId),
        callbacks
      ) as TransactionReceipt;
      setBlockNumber(receipt.blockNumber);
      return {
        data: receipt.events?.Unstake
      };
    } catch (error) {
      return {
        error
      };
    }
  }, [lockingContract, sendTransaction])

  const unlockEarly = useCallback(async (
    stakeId: string,
    callbacks: NotifyTxCallbacks = {}
  ) => {
    if (!lockingContract) {
      return {
        error: 'No contract initialized. Try again later'
      }
    }

    try {
      const receipt = await sendTransaction(
        lockingContract.methods.unstakeEarly(stakeId),
        callbacks
      ) as TransactionReceipt;
      setBlockNumber(receipt.blockNumber);
      return {
        data: receipt.events?.UnstakeEarly
      };
    } catch (error) {
      return {
        error
      };
    }
  }, [lockingContract, sendTransaction])

  const unlockEarlyWithHiro = useCallback(async (
    stakeId: string,
    hiroId: string,
    callbacks: NotifyTxCallbacks = {}
  ) => {
    if (!lockingContract) {
      return {
        error: 'No contract initialized. Try again later'
      }
    }

    try {
      const receipt = await sendTransaction(
        lockingContract.methods.unstakeEarlyUsingHiro(stakeId, hiroId),
        callbacks
      ) as TransactionReceipt;
      setBlockNumber(receipt.blockNumber);
      return {
        data: receipt.events?.UnstakeWithHiro
      };
    } catch (error) {
      return {
        error
      };
    }
  }, [lockingContract, sendTransaction])

  return {
    loadingPeriods,
    loadingEntries,
    lockPeriods,
    lockEntries,
    totalLocked,
    getPeriods,
    getEntries,
    getPenalty,
    getAccumulatedRewards,
    lock,
    unlock,
    unlockEarly,
    unlockEarlyWithHiro,
  }
}
