import { LeaderboardData, LeaderboardResponse } from './types';
import BigNumber from 'bignumber.js';
import { toCapitalize } from "@utils/string";
import { ZKTier } from "@contracts/hooks/useZKTier/constants";

export const mapLeaderboardResponse = ({
  data
}: LeaderboardResponse): LeaderboardData => {
  return {
    accounts: data.accounts.map(acc => ({
      walletAddress: acc.wallet_address,
      tier: acc.tier ? (toCapitalize(acc.tier) as ZKTier) : acc.tier,
      zkstPower: acc.zkst_power,
      accessType: acc.access_type,
      lotteryTickets: acc.lottery_tickets,
      allocationMultiplier: acc.allocation_multiplier,
      hirosAmount: acc.hiros_amount,
      stakedAmount: new BigNumber(acc.staked_amount),
      lockedAmount: new BigNumber(acc.locked_amount)
    })),
    lastUpdatedAt: new Date(data.last_updated_at)
  }
}
