import { instance as API } from '../config';
import { LeaderboardData, LeaderboardResponse } from './types';
import { mapLeaderboardResponse } from './mapping';
import { sendExceptionReport } from '../../utils/errors';

interface GetLeaderboardParams {
  size?: number
  only_nft_holders?: boolean
}

export const getLeaderboard = async ({
  size,
  only_nft_holders,
}: GetLeaderboardParams): Promise<LeaderboardData> => {
  try {
    const { data } = await API.get<LeaderboardResponse>('/get-leaderboard', {
      params: {
        size,
        only_nft_holders
      }
    })

    return mapLeaderboardResponse(data)
  } catch (err) {
    sendExceptionReport(err)
    return {
      accounts: [],
      lastUpdatedAt: null
    }
  }
}
