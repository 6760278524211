import './KYCPage.scss'
import { SEOTags, KYCBadge } from '../../../components';
import { Col, Container, Row } from 'react-bootstrap';
import { useKYC } from '../../../contracts/hooks/useKYC';
import { useWeb3React } from '@web3-react/core';
import { useEffect } from 'react';
import { seoTags } from '../../../seo-content';
import Banner from '@assets/banners/kyc-banner.png'
import PageHeading from "@components/layout/PageHeading/PageHeading";

export const KYCPage = () => {
  const { account } = useWeb3React()
  const { getKYCStatus } = useKYC()

  useEffect(() => {
    getKYCStatus()
  }, [account])

  return (
    <div className="page kyc-page">
      <SEOTags { ...seoTags.kycPage } />
      <PageHeading banner={Banner}>
        <PageHeading.Title>
          KYC
        </PageHeading.Title>
      </PageHeading>
      <div className="kyc-page__content">
        <section className='kyc-page__caption'>
          <Container>
            <p>
              IN ORDER TO PARTICIPATE IN THE LAUNCHES OF ZKSTUDIOs PROJECTS, YOU WILL NEED TO COMPLETE OUR KYC PROCESS
            </p>
          </Container>
        </section>
        <section className='kyc-page__requirements'>
          <Container>
            <Row className="align-items-center">
              <Col md={{ span: 5 }}>
                <p className="grey-text">
                  Please make sure you have the <br/> following before you begin:
                </p>
              </Col>
              <Col md={{ span: 7 }}>
                <div className="tile--secondary">
                  <ul>
                    <li><span>Passport or National ID</span></li>
                    <li><span>Proof of address</span></li>
                  </ul>
                </div>
              </Col>
            </Row>
          <KYCBadge />
          </Container>
        </section>
      </div>
    </div>
  );
};
