import { RoutesPaths } from "@router/constants";
import { matchPath, useLocation } from "react-router-dom";
import { useMemo } from "react";

export enum HeaderTheme {
  LIGHT = 'LIGHT',
  DARK = 'DARK'
}

export const useHeaderTheme = () => {
  const darkThemeRoutes = [
    RoutesPaths.PROJECTS,
    RoutesPaths.PROJECT,
    RoutesPaths.WHITELIST,
    RoutesPaths.EXCLUSIVE_PROJECT,
    RoutesPaths.EXCLUSIVE_WHITELIST,
    RoutesPaths.USER_AGREEMENT,
    RoutesPaths.PRIVACY,
    RoutesPaths.ADD_NETWORK_GUIDE,
  ]

  const { pathname, search, hash } = useLocation()

  const theme = useMemo<HeaderTheme>(() => {
    const matchDark = darkThemeRoutes
      .some(route => {
        const match = matchPath(pathname, route)
        return match && match.isExact
      })
    if (matchDark) return HeaderTheme.DARK
    return HeaderTheme.LIGHT
  }, [pathname, search, hash])

  return theme
}
