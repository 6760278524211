import { ProgressBar } from "react-bootstrap";
import './FormFillStatusBar.scss'

interface Props {
  filledCount: number
  totalCount: number
}

export const FormFillStatusBar = ({ filledCount, totalCount }: Props) => {
  const percentage = Math.floor(filledCount / (totalCount || 1) * 100)

  return (
    <div className="form-fill-statusbar">
      <ProgressBar now={percentage} />
      <div className="form-fill-statusbar__value">
        {percentage} %
      </div>
    </div>
  )
}