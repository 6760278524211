import React from 'react';
import './AboutUs.scss'
import { SEOTags, StaticPageWrapper, TeamCard, TeamMember } from '../../../components';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { seoTags } from '../../../seo-content';
import { partners } from '../../Homepage/contents';
import PlusIcon from '../../../assets/add-icon.svg'
import Banner from '@assets/banners/about-us-banner.png'
import PageHeading from "@components/layout/PageHeading/PageHeading";

const teamMembers: TeamMember[] = [
  {
    avatar: 'parul_qujral.png',
    name: 'Parul Gujral',
    bio: <>Snowball Money Founder <br/>Forbes Contributor</>
  },
  {
    avatar: 'danny_tawiah.png',
    name: 'Danny Tawiah',
    bio: <>Former Nike VP of Innovation <br/>Advisor at Fabricant</>
  },
  {
    avatar: 'ed_delahunty.png',
    name: 'Ed Delahunty',
    bio: <>Founder MediaGiraffes <br/>Supreme LA, Lyft</>
  },
  {
    avatar: 'grady_brannan.png',
    name: 'Grady Brannan',
    bio: <>Photographer at Rolling Stone, <br/>Time, G-Eazy</>
  },
]

const investors: TeamMember[] = [
  {
    avatar: 'g-eazy.png',
    name: 'G-Eazy',
    bio: 'Rapper',
    logo: 'g-eazy.svg'
  },
  {
    avatar: 'ran-neuner.png',
    name: 'Ran Neuner',
    bio: 'cryptobanter.com',
    logo: 'ran-neuner.png'
  },
  {
    avatar: 'seven-letter.png',
    name: 'Casey Zoltan',
    bio: 'The 7th Letter',
  },
  {
    avatar: 'jauz.png',
    name: 'JAUZ',
    bio: 'DJ',
    logo: 'jauz.svg'
  },
  {
    avatar: 'ghastly.png',
    name: 'Ghastly',
    bio: 'DJ',
    logo: 'ghastly.svg'
  },
  {
    avatar: 'jesse-wellens.png',
    name: 'Jesse Wellens',
    bio: 'Youtuber',
    logo: 'jesse-wellens.svg'
  },
  {
    avatar: 'nicky-diamonds.png',
    name: 'Nicky Diamonds',
    bio: 'Diamond Supply',
    logo: 'nicky-diamonds.svg'
  },
]

export const AboutUs = () => {

  return (
    <div className="page about-us-page">
      <SEOTags {...seoTags.aboutUs} />
      <PageHeading banner={Banner}>
        <PageHeading.Title>
          About us
        </PageHeading.Title>
      </PageHeading>
      <section className='about-us-caption'>
        <Container>
          <p>
            <b>We offer resources</b> to the leading projects and communities <b>all year round</b>. When launching Initial DEX
            Offerings (IDOs), projects open up their presale funding rounds to early supporters and holders of zk tokens
          </p>
        </Container>
      </section>
      <section className="partnerships">
        <Container>
          <h2 className="title text-uppercase">Partners</h2>
          <Row className="align-items-stretch">
            {partners.map((item) => (
              <Col
                key={item.name}
                sm={{ span: 6 }}
                md={{ span: 4 }}
                lg={{ span: 3 }}
                className="partnerships-col"
              >
                <img
                  src={item.image} alt={item.name}
                  className="partnerships-item"
                />
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </div>
  );
};
