import { useWeb3React } from '@web3-react/core';
import { useState, useEffect, useCallback } from 'react';
import { useWhitelistContract } from './useContracts';
import BigNumber from 'bignumber.js';
import { getWhitelistData, sendDataForExclusiveWhitelist, sendDataForWhitelist } from '@api/whitelist';
import { ContractAddress } from '../address';
import { useIsMounted } from '@hooks/useIsMounted';
import { WhitelistRequestFormData } from '@api/whitelist/types';
import { isEmptyObject } from '@utils/object';

export enum WhitelistStatuses {
  not_submitted = 'not_submitted',
  in_review = 'in_review',
  rejected = 'rejected',
  passed = 'passed',
}

export type WhitelistStatus = keyof typeof WhitelistStatuses

interface WhiteListReturn {
  whiteListStatus: WhitelistStatus | null
  isKycPassed: boolean
  privatePresaleAllowed: boolean
  publicMaxAllocation: BigNumber
  privateMaxAllocation: BigNumber
  merkleProof?: string[]
  applyToWhitelist: (formData: WhitelistRequestFormData) => Promise<WhitelistStatus | null>
  applyToExclusiveWhitelist: (
    formData: WhitelistRequestFormData,
    referrer: string,
    password: string
  ) => Promise<WhitelistStatus | null>
}

export const useWhitelist = (projectId: string, contract?: ContractAddress): WhiteListReturn  => {
  const isMountedRef = useIsMounted()
  const { account } = useWeb3React()
  const [whiteListStatus, setStatus] = useState<WhitelistStatus | null>(null)
  const [privatePresaleAllowed, setPrivatePresaleAllowed] = useState(false)
  const [publicMaxAllocation, setPublicMaxAllocation] = useState<BigNumber>(new BigNumber(0))
  const [privateMaxAllocation, setPrivateMaxAllocation] = useState<BigNumber>(new BigNumber(0))
  const [isKycPassed, setIsKycPassed] = useState(false)
  const [merkleProof, setMerkleProof] = useState<string[]>()
  const whitelistContract = useWhitelistContract(contract)

  const resetStatus = () => {
    setStatus(WhitelistStatuses.not_submitted)
  }

  const resetAllo = () => {
    setIsKycPassed(false)
    setPublicMaxAllocation(new BigNumber(0))
    setPrivateMaxAllocation(new BigNumber(0))
    setPrivatePresaleAllowed(false)
    setMerkleProof(undefined)
  }

  const getStatusAndAllo = useCallback(async () => {
    if (!account) {
      resetStatus()
      resetAllo()
      return
    }

    const data = await getWhitelistData({
      account,
      projectId
    })
    if (!isMountedRef.current) return
    if (!data) {
      resetStatus()
      resetAllo()
      return
    }

    setStatus(data.status)
    if (data.paramsData && !isEmptyObject(data.paramsData)) {
      const {
        is_kyc_passed,
        private_allocation,
        private_presale_allowed,
        public_allocation,
        merkle_proof,
      } = data.paramsData
      setIsKycPassed(is_kyc_passed)
      setPrivateMaxAllocation(new BigNumber(private_allocation))
      setPublicMaxAllocation(new BigNumber(public_allocation))
      setPrivatePresaleAllowed(private_presale_allowed)
      setMerkleProof(merkle_proof)
      return
    }

    if (!whitelistContract) {
      resetAllo()
      return
    }

    const {
      '1': kycPassed,
      '2': publicAllocation,
      '3': privateAllowed,
      '4': privateAllocation
    } = await whitelistContract.methods.getUser(account).call()

    if (isMountedRef.current) {
      setIsKycPassed(kycPassed)
      setPublicMaxAllocation(new BigNumber(publicAllocation))
      setPrivateMaxAllocation(new BigNumber(privateAllocation))
      setPrivatePresaleAllowed(privateAllowed)
    }

  }, [account, whitelistContract, isMountedRef])

  useEffect(() => {
    getStatusAndAllo()
  }, [projectId, whitelistContract, account])

  const applyToWhitelist = useCallback(async (formData) => {
    if (!account) {
      return null
    }
    const status = await sendDataForWhitelist({
      project_id: projectId,
      wallet_address: account,
      form_data: formData
    })

    if (status) {
      setStatus(status)
    }
    return status
  }, [projectId, account])

  const applyToExclusiveWhitelist = useCallback(async (
    formData,
    referrer,
    password
  ) => {
    if (!account) {
      return null
    }

    const status = await sendDataForExclusiveWhitelist({
      project_id: projectId,
      wallet_address: account,
      form_data: formData,
      referrer,
      password
    })

    if (status) {
      setStatus(status)
    }
    return status
  }, [projectId, account])

  return {
    whiteListStatus,
    isKycPassed,
    privatePresaleAllowed,
    publicMaxAllocation,
    privateMaxAllocation,
    merkleProof,
    applyToWhitelist,
    applyToExclusiveWhitelist
  }
}
