import { useCallback, useEffect, useMemo, useState } from 'react';
import { useProjectPresaleContract, useTokenContract } from './useContracts';
import { useWeb3React } from '@web3-react/core';
import BigNumber from 'bignumber.js';
import { NotifyTxCallbacks } from '../notify';
import { ContractAddress, WalletAddress } from '../address';
import { sendExceptionReport } from '@utils/errors';
import { useTransactions } from './useTransactions';
import { useIsMounted } from '@hooks/useIsMounted';
import { BlockNumber, TransactionReceipt } from 'web3-core';
import { useDecimals, useTokenBalance } from './useBalances';
import { SECOND } from '@constants/dates';

export interface IWhitelistUserData {
  wallet: WalletAddress
  isKycPassed: boolean
  publicMaxAlloc: string
  allowedPrivateSale: boolean
  privateMaxAlloc: string
}

export const usePresale = (presaleAddress?: ContractAddress, fundTokenAddress?: ContractAddress, rewardTokenAddress?: ContractAddress) => {
  const isMountedRef = useIsMounted()
  const fundTokenContract = useTokenContract(fundTokenAddress)
  const presaleContract = useProjectPresaleContract(presaleAddress)
  const { account } = useWeb3React()

  const [loading, setLoading] = useState(false)
  const [blockNumber, setBlockNumber] = useState<BlockNumber>('latest')
  const fundTokenBalance = useTokenBalance(fundTokenAddress, loading, blockNumber)
  const [privateSoldAmount, setPrivateSoldAmount] = useState<BigNumber>(new BigNumber(0))
  const [publicSoldAmount, setPublicSoldAmount] = useState<BigNumber>(new BigNumber(0))
  const [totalRewardsAmount, setTotalRewardsAmount] = useState<BigNumber>(new BigNumber(0))
  const [exchangeRate, setExchangeRate] = useState<BigNumber>(new BigNumber(1))
  const [accuracy, setAccuracy] = useState<BigNumber>(new BigNumber(10))
  const [swappedByUser, setSwappedByUser] = useState<BigNumber>(new BigNumber(0))
  const [swappedPrivateByUser, setSwappedPrivateByUser] = useState<BigNumber>(new BigNumber(0))
  const [participants, setParticipants] = useState(0)
  const [closePeriod, setClosePeriod] = useState<number>()

  const fundsDecimals = useDecimals(fundTokenAddress);
  const rewardsDecimals = useDecimals(rewardTokenAddress);
  const precisionDiff = useMemo(() => {
    return rewardsDecimals - fundsDecimals;
  }, [rewardsDecimals, fundsDecimals]);

  const {
    callTransaction,
    sendTransaction
  } = useTransactions()

  const swapExchangeRate = useMemo(() => {
    return exchangeRate.dividedBy(accuracy)
  }, [exchangeRate, accuracy])

  const fundsSwapped = useMemo(() => {
    return privateSoldAmount
      .plus(publicSoldAmount)
      .times(swapExchangeRate)
      .dividedBy(new BigNumber(10).pow(precisionDiff))
  }, [privateSoldAmount, publicSoldAmount, swapExchangeRate, precisionDiff])

  const totalSwapAmount = useMemo(() => {
    return totalRewardsAmount
      .times(swapExchangeRate)
      .dividedBy(new BigNumber(10).pow(precisionDiff))
  }, [totalRewardsAmount, swapExchangeRate, precisionDiff])

  const swappedPublicByUser = useMemo(() => {
    return swappedByUser.minus(swappedPrivateByUser)
  }, [swappedByUser, swappedPrivateByUser])

  const resetInfo = () => {
    setTotalRewardsAmount(new BigNumber(0))
    setAccuracy(new BigNumber(10))
    setExchangeRate(new BigNumber(1))
    setClosePeriod(undefined)
  }

  const fetchInfo = useCallback(async () => {
    if (!presaleContract || !fundTokenContract) {
      resetInfo()
      return
    }

    try {
      const [
        initialRewards,
        rate,
        rateDecimals,
        closePeriodMillis
      ] = await Promise.all([
        callTransaction(presaleContract.methods.initialRewardAmount()),
        callTransaction(presaleContract.methods.exchangeRate()),
        callTransaction(presaleContract.methods.ACCURACY()),
        new Promise<any>(async (resolve) => {
          try {
            const res = await callTransaction(presaleContract.methods.closePeriod())
            resolve(+res * SECOND)
          } catch {
            resolve(undefined)
          }
        })
        // callTransaction(presaleContract.methods.closePeriod())
        //   .then((res) => +res * SECOND)
        //   .catch(() => undefined),
      ])

      if (isMountedRef.current) {
        setTotalRewardsAmount(new BigNumber(initialRewards))
        setAccuracy(new BigNumber(rateDecimals))
        setExchangeRate(new BigNumber(rate))
        setClosePeriod(closePeriodMillis)
      }
    } catch (err) {
      sendExceptionReport(err)
      isMountedRef.current && resetInfo()
    }
  }, [presaleContract, fundTokenContract, isMountedRef, callTransaction])

  useEffect(() => {
    fetchInfo()
  }, [presaleContract, fundTokenContract])

  const resetActualInfo = () => {
    setPrivateSoldAmount(new BigNumber(0))
    setPublicSoldAmount(new BigNumber(0))
    setSwappedByUser(new BigNumber(0))
    setSwappedPrivateByUser(new BigNumber(0))
    setParticipants(0)
  }

  const fetchActualInfo = useCallback(async () => {
    if (!account || !presaleContract || !fundTokenContract) {
      resetActualInfo()
      return
    }

    try {
      const [
        publicSold,
        privateSold,
        { ftBalance },
        swappedPrivate,
        participantsCount
      ] = await Promise.all([
        callTransaction(
          presaleContract.methods.publicSoldAmount(),
          blockNumber
        ),
        callTransaction(
          presaleContract.methods.privateSoldAmount(),
          blockNumber
        ),
        callTransaction(
          presaleContract.methods.recipients(account),
          blockNumber
        ),
        callTransaction(
          presaleContract.methods.privateSoldFunds(account),
          blockNumber
        ),
        callTransaction(
          presaleContract.methods.participantCount(),
          blockNumber
        )
      ])

      if (isMountedRef.current) {
        setPublicSoldAmount(new BigNumber(publicSold));
        setPrivateSoldAmount(new BigNumber(privateSold));
        setSwappedByUser(new BigNumber(ftBalance));
        setSwappedPrivateByUser(new BigNumber(swappedPrivate))
        setParticipants(+participantsCount);
      }
    } catch (err) {
      sendExceptionReport(err)
      isMountedRef.current && resetActualInfo()
    }
  }, [
    account,
    presaleContract,
    isMountedRef,
    blockNumber,
    callTransaction,
    fundTokenContract,
  ])

  useEffect(() => {
    if (!loading && account) {
      fetchActualInfo()
    }
  }, [fetchActualInfo, loading, account])

  const handleDeposit = useCallback(async (
    amount: string,
    callbacks: NotifyTxCallbacks = {}
  ) => {
    if (!presaleContract) return
    setLoading(true)

    const receipt = await sendTransaction(
      await presaleContract.methods.deposit(amount),
      callbacks
    ) as TransactionReceipt

    setBlockNumber(receipt.blockNumber)
    setLoading(false)
  }, [presaleContract, sendTransaction])

  const handelDepositEth = useCallback(async (
    amount: string,
    userData: IWhitelistUserData,
    merkleProof: string[],
    callbacks: NotifyTxCallbacks = {}
  ) => {
    if (!presaleContract) return
    setLoading(true)

    const receipt = await sendTransaction(
      await presaleContract.methods.deposit(
        amount,
        userData,
        merkleProof
      ),
      callbacks
    ) as TransactionReceipt

    setBlockNumber(receipt.blockNumber)
    setLoading(false)
  }, [presaleContract, sendTransaction])

  const handleDepositPrivate = useCallback(async (
    amount: string,
    callbacks: NotifyTxCallbacks = {}
  ) => {
    if (!presaleContract) return
    setLoading(true)

    const receipt = await sendTransaction(
      await presaleContract.methods.depositPrivateSale(amount),
      callbacks
    ) as TransactionReceipt

    setBlockNumber(receipt.blockNumber)
    setLoading(false)
  }, [presaleContract, sendTransaction])

  const handleDepositEthPrivate = useCallback(async (
    amount: string,
    userData: IWhitelistUserData,
    merkleProof: string[],
    callbacks: NotifyTxCallbacks = {}
  ) => {
    if (!presaleContract) return
    setLoading(true)

    const receipt = await sendTransaction(
      await presaleContract.methods.depositPrivateSale(
        amount,
        userData,
        merkleProof
      ),
      callbacks
    ) as TransactionReceipt

    setBlockNumber(receipt.blockNumber)
    setLoading(false)
  }, [presaleContract, sendTransaction])

  return {
    fundTokenBalance,
    totalSwapAmount,
    fundsSwapped,
    totalRewardsAmount,
    swapExchangeRate,
    swappedByUser,
    swappedPublicByUser,
    swappedPrivateByUser,
    participants,
    closePeriod,
    fetchActualInfo,
    onDeposit: handleDeposit,
    onDepositPrivate: handleDepositPrivate,
    onDepositEth: handelDepositEth,
    onDepositEthPrivate: handleDepositEthPrivate,
    fundsDecimals,
    rewardsDecimals
  }
}
