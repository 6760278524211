import { OGProperties, PageTags } from '../types';
import { RoutesPaths } from '@router/constants';

const title = 'Account Staking'
const url = window.location.origin + RoutesPaths.ACCOUNT.STAKING

export const accountStaking: PageTags = {
  title: `| ${title}`,
  url: url,
  properties: [
    {
      property: OGProperties.title,
      content: `FireStarter | ${title}`,
    },
    {
      property: OGProperties.description,
      content: 'Earn $ЯЛІЕ rewards by staking your ЯЛІЕ-USDC LP tokens. Determine your Tier. Receive Allocations from FireStarter Projects.',
    },
    {
      property: OGProperties.url,
      content: url,
    },
  ]
}
