import React, { useEffect } from 'react';
import { Nav } from 'react-bootstrap';
import './Account.scss';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Portfolio } from './Portfolio';
import { Staking } from './Staking';
import { useKYC } from '@contracts/hooks/useKYC';
import { useWeb3React } from '@web3-react/core';
import { RoutesPaths } from '@router/constants';
import { useNetwork } from '@hooks/useNetwork';
import { NonPolygonWidget } from '@components';
import { LockupMultiperiod } from './LockupMultiperiod';
import Banner from '@assets/banners/account-banner.png'
import PageHeading from "@components/layout/PageHeading/PageHeading";

export const Account = () => {
  const { path, isExact } = useRouteMatch();
  const matchLockingMultiperiod = useRouteMatch(RoutesPaths.ACCOUNT.LOCKING_MULTIPERIOD);
  const matchStaking = useRouteMatch(RoutesPaths.ACCOUNT.STAKING);
  const { getKYCStatus } = useKYC();
  const { account } = useWeb3React();
  const { isDefaultNetworkSelected } = useNetwork();

  useEffect(() => {
    getKYCStatus();
  }, [account]);

  return (
    <div className="account-page page">
      <PageHeading large banner={Banner}>
        <PageHeading.Title>Account</PageHeading.Title>
        <PageHeading.Body>
          <Nav
            as="nav"
            defaultActiveKey={`${path}/lockup`}
            className="account-nav nav-tabs dark"
          >
            <Nav.Item>
              <Nav.Link as={Link} to={RoutesPaths.ACCOUNT.MAIN} active={isExact}>
                My Portfolio
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                as={Link}
                to={RoutesPaths.ACCOUNT.LOCKING_MULTIPERIOD}
                active={matchLockingMultiperiod?.isExact}
              >
                Lockup
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                as={Link}
                to={RoutesPaths.ACCOUNT.STAKING}
                active={matchStaking?.isExact}
              >
                Staking
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </PageHeading.Body>
      </PageHeading>
      <Switch>
        <Route exact path={RoutesPaths.ACCOUNT.MAIN} component={Portfolio} />
        <Route
          exact
          path={RoutesPaths.ACCOUNT.LOCKING_MULTIPERIOD}
          component={isDefaultNetworkSelected ? LockupMultiperiod : NonPolygonWidget}
        />
        <Route
          exact
          path={RoutesPaths.ACCOUNT.STAKING}
          component={isDefaultNetworkSelected ? Staking : NonPolygonWidget}
        />
      </Switch>
    </div>
  );
};
