import React, { useEffect, useMemo } from 'react';
import './Homepage.scss';
import { Col, Container, Dropdown, DropdownButton, Image, Row } from 'react-bootstrap';
import {
  Accordion,
  RoundButton,
  TimerWrap,
  GetReadyBadge,
  ProjectsSlider,
  TiersTable,
  FeaturesSection
} from '@components';
import { partners, faqItems } from './contents';
import { buyZKSTLink, dueDateForProjects, ZKSTProjectId, KuCoinZKSTLink } from '@constants';
import { useProjectsState } from '@contracts/hooks/useProjects';
import { NormalizedProjectInfo, ProjectStatusesProps } from '@components/Project/types';
import { format } from 'date-fns';
import { RoutesPaths } from '@router/constants';
import { usePlatformSettings } from '@hooks/usePlatformSettings';
import { projectGetters } from '@contracts/getters/projectGetters';
import { ReactComponent as ZkSyncIcon } from '@assets/partners/zksync-white.svg';

function getProjectPriority(project: NormalizedProjectInfo): number {
  switch (project.presale.statusMessage) {
    case ProjectStatusesProps.Closed:
      return 1
    case ProjectStatusesProps['Coming Soon']:
      return 2
    case ProjectStatusesProps['Registration Closed']:
      return 3
    case ProjectStatusesProps['Registration Open']:
      return 4
    case ProjectStatusesProps['FCFS Live']:
    case ProjectStatusesProps['Guaranteed Live']:
      return 5
    default:
      return 1
  }
}

export const Homepage = () => {
  const {
    settings
  } = usePlatformSettings()

  const featuredProjectId = useMemo(() => settings?.featuredProjectId, [settings])
  const {
    loading,
    featuredProjects,
    currentProject,
    getProjectById,
    getProjects
  } = useProjectsState()

  useEffect(() => {
    getProjects()
    if (featuredProjectId && currentProject?.id !== featuredProjectId) {
      getProjectById(featuredProjectId)
    }
  }, [currentProject, featuredProjectId])

  const featuredProjectStatus = useMemo(() => {
    return currentProject?.presale.statusMessage || ProjectStatusesProps['Coming Soon']
  }, [currentProject])

  const projectsForSlider = useMemo(() => {
    return [
      ...((currentProject && currentProject?.id === featuredProjectId) ? [currentProject] : []),
      ...featuredProjects.filter(p => p.id !== featuredProjectId)
    ].sort((a, b) => {
      return getProjectPriority(b) - getProjectPriority(a)
    })
  }, [currentProject, featuredProjects, featuredProjectId])

  const discoverButtonProps = useMemo(() => {
    switch (featuredProjectStatus) {
      case ProjectStatusesProps['Coming Soon']:
        return {
          disabled: true
        }
      case ProjectStatusesProps['Registration Open']:
        return {
          to: `/whitelist/${featuredProjectId}`
        }
      case ProjectStatusesProps['Registration Closed']:
      case ProjectStatusesProps['FCFS Live']:
      case ProjectStatusesProps['Guaranteed Live']:
        return {
          to: `/projects/${featuredProjectId}`
        }
      default:
        return {
          to: `/projects/${ZKSTProjectId}`
        }
    }
  }, [featuredProjectStatus, featuredProjectId])

  const discoverButtonText = useMemo(() => {
    const projectName = currentProject?.name
    const isNFTClaim = !!currentProject && projectGetters.getIsNFTClaimProject(currentProject)

    switch (featuredProjectStatus) {
      case ProjectStatusesProps['Coming Soon']:
      case ProjectStatusesProps['Registration Open']:
        return `${projectName} Whitelist`

      case ProjectStatusesProps['Registration Closed']:
      case ProjectStatusesProps['FCFS Live']:
      case ProjectStatusesProps['Guaranteed Live']:
        return `${projectName} ${isNFTClaim ? 'Claim' : 'Pre-sale'}`

      default:
        return 'Discover'
    }
  }, [featuredProjectStatus, currentProject])

  const discoverButtonDate = useMemo(() => {
    switch (featuredProjectStatus) {
      case ProjectStatusesProps['Coming Soon']:
        return currentProject?.whitelisting.starts_at && format(currentProject.whitelisting.starts_at, 'MM/dd/yy')
      case ProjectStatusesProps['Registration Open']:
        return 'Apply'
      case ProjectStatusesProps['Registration Closed']:
        const startDate = currentProject?.presale.private_starts_at ?? currentProject?.presale.public_starts_at
        return startDate ? format(startDate, 'MM/dd/yy') : 'Soon'
      case ProjectStatusesProps['Guaranteed Live']:
      case ProjectStatusesProps['FCFS Live']:
        return 'LIVE'
      default:
        return null
    }
  }, [featuredProjectStatus, currentProject])

  return (
    <div className="homepage page">
      <section className="top-banner">
        <div className="background"></div>
        <Container>
          <div className="hero-row">
            <div className='hero-row__main'>
              <h1 className="title">
                A Launchpad for <br/>
                Everything ZK
              </h1>
              <p className='subtitle'>
                Freedom Unlocked
              </p>
              {
                currentProject && currentProject.id === featuredProjectId && (
                  <div className='hero-buttons'>
                    {
                      (
                        !discoverButtonDate
                      ) ? (
                        <>
                          <RoundButton {...discoverButtonProps} size="large" color="DARK">
                            {discoverButtonText}
                          </RoundButton>
                          {/*<DropdownButton
                            title="Buy $ZKST Tokens"
                            className="buy-zkst-dropdown"
                          >
                            <Dropdown.Item
                              className="buy-zkst-dropdown__item"
                              href={KuCoinZKSTLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Image src={KuCoinLogo} roundedCircle />
                              <span>KUCOIN</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="buy-zkst-dropdown__item"
                              href={buyZKSTLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Image src={QuickSwapLogo} roundedCircle />
                              <span>QUICKSWAP</span>
                            </Dropdown.Item>
                          </DropdownButton>*/}
                        </>
                      ) : (
                        <div className="hero-buttons__countdown">
                          {/*TODO: remove background and DARK color after Nicky D campaign is finished*/}
                          <RoundButton
                            {...discoverButtonProps}
                            size="large"
                            color="DARK"
                          >
                            {discoverButtonText}
                          </RoundButton>
                          <span className='hero-buttons__whitelist-date'>
                            {discoverButtonDate}
                          </span>
                        </div>
                      )
                    }
                  </div>
                )
              }
            </div>
            <div className="powered-by">
              <span className="powered-by__text">Powered by </span>
              <div className="powered-by__icons">
                <ZkSyncIcon />
              </div>
            </div>
          </div>
        </Container>
      </section>
      {/*<FeaturesSection />*/}
      <section className="coming-projects">
        <ProjectsSlider
          title="Our Projects"
          projectsLink={RoutesPaths.PROJECTS}
          linkText="All Projects"
          loadingProjects={loading}
          projects={projectsForSlider}
        />
      </section>
      <section className='advantages-section'>
        <Container>
          <Row className="justify-content-between">
            <Col lg={{ span: 6 }}>
              <h4 className="title text-uppercase fw-normal">
                We assist the <strong>early-stage funding</strong> of zK Projects and communities by {' '}
                <strong>leveraging various strategies</strong> to amplify their growth and visibility
              </h4>
              <div className='advantages-section__buttons'>
                {/*<DropdownButton
                  title="Buy $ZKST Tokens"
                  className="buy-zkst-dropdown"
                >
                  <Dropdown.Item
                    className="buy-zkst-dropdown__item"
                    href={KuCoinZKSTLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image src={KuCoinLogo} roundedCircle />
                    <span>KUCOIN</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="buy-zkst-dropdown__item"
                    href={buyZKSTLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image src={QuickSwapLogo} roundedCircle />
                    <span>QUICKSWAP</span>
                  </Dropdown.Item>
                </DropdownButton>*/}
                <RoundButton
                  to={RoutesPaths.ACCOUNT.LOCKING_MULTIPERIOD}
                  color="DARK"
                  size="large"
                >
                  Lock $ZKST Tokens
                </RoundButton>
              </div>
            </Col>
            <Col lg={{ span: 6 }}>
              <div className='platform-advantages'>
                <div className='advantages-item'>
                  <h3 className='advantages-item__title'>Initial DEX Offering</h3>
                  <p className="advantages-item__text">
                    An IDO, or Initial DEX Offering, is a decentralized fundraising method where project tokens are
                    directly offered to the public through a decentralized exchange
                  </p>
                </div>
                <div className='advantages-item'>
                  <h3 className='advantages-item__title'>ZERO TO LAUNCH</h3>
                  <p className="advantages-item__text">
                    We don’t launch, we incubate. Our growth strategies and relationships are leveraged to ensure an
                    explosive launch
                  </p>
                </div>
                <div className='advantages-item'>
                  <h3 className='advantages-item__title'>COMMUNITY {'>'} EVERYTHING</h3>
                  <p className="advantages-item__text">
                    Our approach goes beyond token launches; we cultivate communities, providing early adopters with
                    exclusive opportunities for participation, governance, and access
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="get-ready-section">
        <Container>
          <Row className="mt-auto">
            <Col>
              <GetReadyBadge />
            </Col>
          </Row>
        </Container>
      </section>
      <section className='tiers-section'>
        <Container>
          <h2 className='title'>zkSTUDIOS Tiers & Access to IDOs</h2>
          <TiersTable />
        </Container>
      </section>
      <TimerWrap dueDate={dueDateForProjects}>
        <section className="partnerships">
          <Container>
            <h2 className="title text-uppercase mb-0">Partners</h2>
            <Row className="align-items-stretch justify-content-start">
              {partners.map((item) => (
                <Col
                  key={item.name}
                  sm={{ span: 6 }}
                  md={{ span: 4 }}
                  lg={{ span: 3 }}
                  className="partnerships-col"
                >
                  <img
                    src={item.image} alt={item.name}
                    className="partnerships-item"
                  />
                </Col>
              ))}
            </Row>
          </Container>
        </section>
      </TimerWrap>
      <section className="faq-section">
        <Container>
          <Row className="faq-column justify-content-center">
            <Col
              className="col-xxl-10"
              md={{ span: 12 }}
              xl={{ span: 11 }}
            >
              <h2 className="title">FAQ</h2>
              <Accordion items={faqItems.slice(0, 4)} />
              <RoundButton
                to={RoutesPaths.FAQ}
                size="large"
              >
                See all Questions
              </RoundButton>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};
