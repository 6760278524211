import { IDOFormValues } from '@components/formfills';
import { FormFillResponse } from './types';
import axios from 'axios';
import { sendExceptionReport } from '@utils/errors';

const IDOFormEndpoint = 'https://script.google.com/macros/s/AKfycbz7bvj9SznFqhCWSYJB7sPJjEx6XdhFRE91SDH5q0iaoai3DzyOJc89FbdPRNMp5Ebi/exec'

const spreadSheetKeys: Record<keyof IDOFormValues, string> = {
  projectName: 'Project Name',
  position: 'What is your position within the project?',
  privacy: 'Is your team public or anonymous?',
  isForZKSync: 'Is your project designed for the zkSync Era network?',
  description: 'Please provide a one sentence pitch of your project',
  whitepaper: 'Please provide a link to your whitepaper',
  projectStatus: 'What is the current status of your project?',
  plannedFunds: 'How much do you plan to raise through an IDO?',
  currentFunds: 'How much have you already raised?',
  projectWebsite: 'Project Website',
  projectTelegram: 'Project Telegram Group',
  projectMedium: 'Medium Blog',
  telegram: 'Your Telegram',
  email: 'Your Email',
  contactMethod: 'Preferred method of contact'
}

export const submitToIDOForm = async (values: IDOFormValues): Promise<FormFillResponse> => {
  const formData = new FormData()
  formData.append('Timestamp', new Date().toLocaleString())
  for (let _key in values) {
    let key = _key as keyof IDOFormValues
    formData.append(spreadSheetKeys[key], values[key])
  }

  try {
    const response = await axios.post(
      IDOFormEndpoint,
      formData
    )

    const data = response.data as FormFillResponse

    if (data.result === 'error') {
      sendExceptionReport(data.error)
    }
    return data
  } catch (err) {
    sendExceptionReport(err)
    return {
      result: 'error',
      error: err
    }
  }
}
