import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Col, Container, FormControl, Image, InputGroup, Row } from 'react-bootstrap';
import {
  LoadingWrap,
  ApprovalSteps,
  RoundButton,
  BalanceItem,
  CommonTooltip
} from '@components/common';
import { ReactComponent as InfoIcon } from '@assets/info-icon.svg';
import { WhitelistStatus, WhitelistStatuses } from '@contracts/hooks/useWhitelist';
import { ZKSTProjectId } from '@constants';
import {
  balanceToCurrency,
  balanceToNumber,
  balanceToNumeric,
  numericToBalance,
  numericToUint256
} from '@utils/balanceFormatter';
import BigNumber from 'bignumber.js';
import { TokenInfo } from '../types';
import { useZKTier } from '@contracts/hooks/useZKTier/useZKTier';
import { NotifyTxCallbacks } from '@contracts/notify';
import { getIsSingleApprovalToken } from '@contracts/address';

interface Props {
  totalSwapAmount: BigNumber
  fundsSwapped: BigNumber
  publicMaxAllocation: BigNumber
  privateMaxAllocation: BigNumber
  privatePresaleAllowed: boolean
  swappedByUser: BigNumber
  swappedPrivateByUser: BigNumber
  swappedPublicByUser: BigNumber
  balance: BigNumber
  fundsDecimals: number
  allowance: BigNumber
  whiteListStatus: WhitelistStatus | null
  projectId: string
  fundToken: TokenInfo
  isPrivatePhaseInProgress: boolean,
  isPublicPhaseInProgress: boolean,
  isClosePeriod?: boolean;
  onApprove: (amount?: string, callbacks?: NotifyTxCallbacks) => Promise<void>
  onDeposit: (amount: string, callbacks?: NotifyTxCallbacks) => Promise<void>
  onDepositPrivate: (amount: string, callbacks?: NotifyTxCallbacks) => Promise<void>
}

export const SwapForm: React.FC<Props> = ({
  totalSwapAmount,
  fundsSwapped,
  publicMaxAllocation,
  privateMaxAllocation,
  privatePresaleAllowed,
  swappedByUser,
  swappedPrivateByUser,
  swappedPublicByUser,
  balance,
  fundsDecimals,
  allowance,
  whiteListStatus,
  projectId,
  fundToken,
  onApprove,
  isPrivatePhaseInProgress,
  isPublicPhaseInProgress,
  isClosePeriod,
  onDeposit,
  onDepositPrivate,
}) => {

  const {
    userTierInfo,
    loading: loadingTier
  } = useZKTier()

  const remainingFundsToSwap = useMemo(() => {
    return totalSwapAmount.minus(fundsSwapped)
  }, [totalSwapAmount, fundsSwapped])

  const isSingleApproval = useMemo(
    () => !!fundToken.address && getIsSingleApprovalToken(fundToken.address),
    [fundToken]
  )

  const allowedInClosePeriod = useMemo(() => {
    return (privatePresaleAllowed || !privateMaxAllocation.isZero()) && !publicMaxAllocation.isZero()
  }, [
    privatePresaleAllowed,
    privateMaxAllocation,
    publicMaxAllocation,
  ])

  const availableAllocation = useMemo(() => {
    if (isPrivatePhaseInProgress) {
      return privateMaxAllocation.minus(swappedPrivateByUser)
    }
    if (isClosePeriod && allowedInClosePeriod) {
      return publicMaxAllocation.minus(swappedPublicByUser)
    }
    if (isPublicPhaseInProgress && allowedInClosePeriod) {
      return new BigNumber(0)
    }
    return publicMaxAllocation.minus(swappedPublicByUser)
  }, [
    publicMaxAllocation,
    privateMaxAllocation,
    swappedPublicByUser,
    swappedPrivateByUser,
    isPrivatePhaseInProgress,
    isPublicPhaseInProgress,
    isClosePeriod,
    allowedInClosePeriod,
  ])

  const maxSwapAmount = useMemo(() => {
    return balanceToNumeric(
      BigNumber.min(
        availableAllocation,
        balance,
        remainingFundsToSwap,
      ),
      fundsDecimals
    );
  }, [
    remainingFundsToSwap,
    availableAllocation,
    balance,
    fundsDecimals
  ])

  const [amountToSwap, setAmountToSwap] = useState('0')
  const setMaxToSwap = useCallback(() => {
    setAmountToSwap(maxSwapAmount)
  }, [maxSwapAmount]);

  const disableApproval = useMemo(() => {
    return whiteListStatus !== WhitelistStatuses.passed
      || allowance.isGreaterThanOrEqualTo(numericToBalance(amountToSwap, fundsDecimals))
      || +amountToSwap <= 0
  }, [
    whiteListStatus,
    allowance,
    amountToSwap,
    fundsDecimals
  ])

  const disableSwapping = useMemo(() => {
    return whiteListStatus !== WhitelistStatuses.passed
      || +amountToSwap <= 0
      || !balanceToNumber(allowance, fundsDecimals)
      || numericToBalance(maxSwapAmount, fundsDecimals).isLessThan(numericToBalance(amountToSwap, fundsDecimals))
      || allowance.isLessThan(numericToBalance(amountToSwap, fundsDecimals))
      || (isPrivatePhaseInProgress && !privatePresaleAllowed)
      || ((isPublicPhaseInProgress && !isClosePeriod) && allowedInClosePeriod)
      || (isClosePeriod && availableAllocation.isZero())
  }, [
    maxSwapAmount,
    amountToSwap,
    allowance,
    whiteListStatus,
    fundsDecimals,
    isPrivatePhaseInProgress,
    isPublicPhaseInProgress,
    publicMaxAllocation,
    privatePresaleAllowed,
    isClosePeriod,
    allowedInClosePeriod,
  ])

  const warningMessage = useMemo(() => {
    if (isPrivatePhaseInProgress && !privatePresaleAllowed) {
      return 'The sale for FCFS is not open yet'
    }
    if (isPublicPhaseInProgress && !isClosePeriod && publicMaxAllocation.isZero()) {
      return 'The sale by Guaranteed access is already over'
    }
    if (publicMaxAllocation.isZero() && privateMaxAllocation.isZero()) {
      return `Your wallet didn't win to have access for this sale`
    }
    if (numericToBalance(amountToSwap, fundsDecimals).isGreaterThan(remainingFundsToSwap)) {
      return 'The amount exceeds the remaining funds to raise'
    }
    if (numericToBalance(amountToSwap, fundsDecimals).isGreaterThan(availableAllocation)) {
      return 'The amount exceeds what is available for your Tier.'
    }
    if (numericToBalance(amountToSwap, fundsDecimals).isGreaterThan(balance)) {
      return 'The amount exceeds your balance of the tokens'
    }
    if (remainingFundsToSwap.isZero()) {
      return 'All the funds have already been raised'
    }
  }, [
    amountToSwap,
    balance,
    availableAllocation,
    fundsDecimals,
    remainingFundsToSwap,
    isPrivatePhaseInProgress,
    isPublicPhaseInProgress,
    privatePresaleAllowed,
    publicMaxAllocation,
    privateMaxAllocation,
    isClosePeriod
  ])

  const handleSwap = useCallback(async () => {
    if (disableSwapping) {
      return
    }
    const amount = numericToUint256(amountToSwap, fundsDecimals)
    const depositMethod = isPrivatePhaseInProgress ? onDepositPrivate : onDeposit
    await depositMethod(
      amount,
      {
        onHash: () => setAmountToSwap('0')
      }
    )
  }, [
    amountToSwap,
    onDeposit,
    onDepositPrivate,
    fundsDecimals,
    disableSwapping,
    isPrivatePhaseInProgress,
  ])

  return (
    <section className="swap-section">
      <Container>
        <Row className="tile align-items-center">
          <Col xs={{ span: 12 }} xl={{ span: 6 }}>
            <div className='project-page__wallet-info'>
              <div className={classNames('whitelist-badge', { 'invalid': whiteListStatus !== WhitelistStatuses.passed })}>
                <LoadingWrap loading={!whiteListStatus}>
                  <span className={classNames({ 'red-text': whiteListStatus !== WhitelistStatuses.passed })}>
                    Wallet is {whiteListStatus !== WhitelistStatuses.passed && 'NOT'} whitelisted
                  </span>
                </LoadingWrap>
              </div>
              {
                projectId !== ZKSTProjectId && (
                  <div className='tier-status'>
                    <LoadingWrap loading={loadingTier}>
                      {!!(userTierInfo && userTierInfo.tier)
                        ? <p>Your ZKStudios Tier is <b className="orange-text">{userTierInfo.tier}</b></p>
                        : <p>You don't have any ZKStudios Tier yet</p>
                      }
                    </LoadingWrap>
                  </div>
                )
              }
              <BalanceItem
                image={fundToken.icon ?? '/token-logos/locked.svg'}
                title="Available Swap Amount"
                balance={balanceToCurrency(availableAllocation, fundsDecimals)}
                token={fundToken.name}
              />
            </div>
          </Col>
          <Col xs={{ span: 12 }} xl={{ span: 6 }}>
            <form noValidate className="swap-form bordered">
              <p className="form-message text-center">
                You have {balanceToCurrency(swappedByUser, fundsDecimals)} {fundToken.name}&nbsp;
                swapped total from {balanceToCurrency(privateMaxAllocation.plus(publicMaxAllocation), fundsDecimals)} available for your TIER
              </p>
              <InputGroup className='swap-form__input-group'>
                <InputGroup.Prepend>
                  <Image rounded src={fundToken.icon} />
                  <span>{fundToken.name}</span>
                </InputGroup.Prepend>
                <FormControl
                  placeholder="0.0"
                  type="number"
                  inputMode="numeric"
                  min={0}
                  value={amountToSwap}
                  onChange={(e) => setAmountToSwap(e.target.value)}
                  isInvalid={disableSwapping}
                  isValid={!disableSwapping}
                />
                <InputGroup.Append>
                  <RoundButton size="small" color="LIGHT" onClick={setMaxToSwap}>
                    MAX
                  </RoundButton>
                </InputGroup.Append>
              </InputGroup>
              {!!warningMessage &&
              <div className='form-message form-message--warning text-center'>
                <InfoIcon />
                <span>{warningMessage}</span>
              </div>
              }
              <div className="swap-form__buttons">
                <RoundButton
                  size="large"
                  color="DARK"
                  disabled={disableApproval}
                  onClick={() => onApprove(numericToUint256(amountToSwap, fundsDecimals))}
                >
                  Approve
                  {
                    isSingleApproval && (
                      <CommonTooltip id="allowance-tip" placement="auto">
                        <p>
                          The {fundToken.name} token has a special allowance behavior. <br/>
                          Thus, it may take additional transaction to approve the spending of your tokens.
                        </p>
                      </CommonTooltip>
                    )
                  }
                </RoundButton>
                <RoundButton
                  size="large"
                  color="DARK"
                  disabled={disableSwapping}
                  onClick={handleSwap}
                >
                  SWAP
                </RoundButton>
              </div>
              <ApprovalSteps fillingCondition={disableApproval} />
            </form>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
