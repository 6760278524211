import { mapGasPrice } from '@api/gas-stations/mappers'
import { web3NoAccountInstances } from '@utils/web3'
import { NetworksIDs } from '@contracts/networks'

export const getAnyGasPrice = async (networkId: string) => {
  const web3 = web3NoAccountInstances[networkId]
  const wei = await web3.eth.getGasPrice()
  const base = Number(web3.utils.fromWei(wei, 'gwei'))

  return mapGasPrice({
    safeLow: base,
    standard: base * 1.02,
    fast: base * 1.04,
    fastest: base * 1.06
  })
}