import { NetworksIDs, NetworkId, defaultEnvironmentNetworkId } from './networks';

export type ContractAddress = string;
export type WalletAddress = string;

interface ZKSTContracts {
  zkstToken: ContractAddress;
  multiperiodLocking: ContractAddress;
  lpStaking: ContractAddress;
  lpToken: ContractAddress;
}

const networksTokenAddresses: Record<NetworkId, ZKSTContracts> = {
  [NetworksIDs.zksync]: {
    zkstToken: process.env.REACT_APP_ZK_ZKST_TOKEN || '',
    multiperiodLocking: process.env.REACT_APP_ZK_MULTIPERIOD_LOCKING || '',
    lpStaking: process.env.REACT_APP_ZK_STAKING || '',
    lpToken: process.env.REACT_APP_ZK_LP_TOKEN || '',
  },
  [NetworksIDs.zksynctest]: {
    zkstToken: process.env.REACT_APP_ZKTEST_ZKST_TOKEN || '',
    multiperiodLocking: process.env.REACT_APP_ZKTEST_MULTIPERIOD_LOCKING || '',
    lpStaking: process.env.REACT_APP_ZKTEST_STAKING || '',
    lpToken: process.env.REACT_APP_ZKTEST_LP_TOKEN || '',
  }
};

export const tokenAddresses: ZKSTContracts =
  networksTokenAddresses[defaultEnvironmentNetworkId];


/**
 * Token addresses in lower case for which approval cannot be increased
 * while it is different from 0
 */
const singleApprovalTokens: Set<ContractAddress> = new Set([
  '0xdac17f958d2ee523a2206206994597c13d831ec7',
  // USDT token on Ethereum network (ERC-20) has such behavior
  // while on other networks it doesn't
])

export const getIsSingleApprovalToken = (tokenAddress: ContractAddress): boolean => {
  return singleApprovalTokens.has(tokenAddress.toLowerCase())
}
