import { JoinFormValues } from '@components/formfills';
import { FormFillResponse } from './types';
import axios from 'axios';
import { sendExceptionReport } from '@utils/errors';
import { toChecksumAddress } from '@utils/string';

const joinCommunityFormEndpoint = 'https://script.google.com/macros/s/AKfycbxNCmc98wfkg-SCF-o_8TQzW3bbQCWjJUAma6rEjFQoo9m4p0bArSOaS_mj-0V5BcW6/exec'

export const submitToJoinCommunity = async (values: JoinFormValues): Promise<FormFillResponse> => {
  const formData = new FormData()
  formData.append('Timestamp', new Date().toLocaleString())
  formData.append('First Name', values.firstName)
  formData.append('Last Name', values.lastName)
  formData.append('Email', values.email)
  formData.append('Twitter or Instagram', values.twitterOrInstagram)
  formData.append('Telegram', values.telegram)
  formData.append('Wallet Address', toChecksumAddress(values.walletAddress))
  formData.append('Are you based in the U.S?', values.isUSA)

  try {
    const response = await axios.post(
      joinCommunityFormEndpoint,
      formData
    )

    const data = response.data as FormFillResponse

    if (data.result === 'error') {
      sendExceptionReport(data.error)
    }
    return data
  } catch (err) {
    sendExceptionReport(err)
    return {
      result: 'error',
      error: err
    }
  }
}

