import { Col, Container, Row } from 'react-bootstrap';
import { faqItems } from '../../Homepage/contents';
import { Accordion, RoundButton, SEOTags } from '../../../components';
import React from 'react';
import { supportEmail } from '../../../constants';
import { seoTags } from '../../../seo-content';
import './FAQ.scss'
import Banner from '@assets//banners/faq-banner.png'
import PageHeading from "@components/layout/PageHeading/PageHeading";

export const FAQPage = () => {

  return (
    <div className="page faq-page">
      <SEOTags { ...seoTags.faqPage } />
      <PageHeading banner={Banner}>
        <PageHeading.Title>
          FAQ
        </PageHeading.Title>
        <PageHeading.Subtitle>
          Didn't find the answer to your question?
        </PageHeading.Subtitle>
        <PageHeading.Body>
          <RoundButton
            href={`mailto:${supportEmail}`}
            size="large"
            color="DARK"
          >
            Contact Support
          </RoundButton>
        </PageHeading.Body>
      </PageHeading>
      <section className='marketing-content'>
        <Container>
          <Row>
            <Col>
              <Accordion items={faqItems} />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}
