import React from 'react';
import Fog from '../../../assets/fog.png';
import { useTimeLeft } from '@hooks/useTimeLeft';
import { Timer } from '../Timer';

import './TimerWrap.css';

interface Props {
  dueDate: Date
  children: React.ReactNode
}

export const TimerWrap = ({ dueDate, children }: Props) => {
  const timeLeft = useTimeLeft(dueDate);

  return (
    <div className="timer-wrap">
      {timeLeft &&
        <>
          <div className="timer-blur"/>
          <img className="timer-fog" src={Fog} alt=""/>
          <div className="timer tile text-center p-5">
            <h2 className="title mb-4">Announcing soon</h2>
            <Timer duration={timeLeft} />
          </div>
        </>
      }
      {children}
    </div>
  );
}
