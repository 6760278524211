import { formatInTimeZone, getTimezoneOffset } from 'date-fns-tz';

export const formatLocalDateWithZone = (date: Date | number): string => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return formatInTimeZone(date, timeZone, 'yyyy-MM-dd HH:mm zzz');
};
export const formatDateToUTC = (date: Date | number): string => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return formatInTimeZone(+date - getTimezoneOffset(timeZone, date), timeZone, `yyyy-MM-dd HH:mm 'UTC`);
};

export const secondsToDays = (seconds: number): number => {
  return seconds / 3600 / 24
}
