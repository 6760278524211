import React, { useCallback, useMemo, useState } from 'react';
import './Staking.scss'
import { useZKTier } from '@contracts/hooks/useZKTier/useZKTier';
import { Col, Container, Row, Tabs, Tab, InputGroup, FormControl, Image } from 'react-bootstrap';
import {
  BalanceItem,
  CommonTooltip,
  ZKPowerLevel,
  LoadingWrap,
  RoundButton,
  SEOTags,
  KYCBadge,
  ApprovalSteps,
} from '@components';
import { useApproval } from '@contracts/hooks/useApproval';
import { tokenAddresses } from '@contracts/address';
import { useStaking } from '@contracts/hooks/useStaking';
import {
  balanceToCurrency,
  balanceToNumber,
  balanceToNumeric,
  numberToCurrency,
  numericToBalance,
  numericToUint256
} from '@utils/balanceFormatter';
import LPToken from '@assets/lp-token.svg';
import { ReactComponent as InfoIcon } from '../../../assets/info-icon.svg';
import { stakingTooltips } from './tooltips';
import { seoTags } from '@/seo-content';
import { buyLpTokenLink } from '@constants';
import { useNetwork } from '@hooks/useNetwork';
import { differenceInDays } from 'date-fns';

enum FormTypes {
  STAKE = 'STAKE',
  UNSTAKE = 'UNSTAKE',
  STATS = 'STATS',
}

type FormType = keyof typeof FormTypes

export const Staking = () => {
  const {
    isDefaultNetworkSelected,
  } = useNetwork()

  const {
    allowance,
    onApprove
  } = useApproval(
    ...(isDefaultNetworkSelected ? [
      tokenAddresses.lpToken,
      tokenAddresses.lpStaking
    ] : [])
  )
  const {
    APY,
    currentPenalty,
    rewardsPerSecond,
    isStakingActive,
    lastStaked,
    rewards,
    staked,
    lpBalance,
    onStake,
    onUnstake,
    onClaim,
    stakingStats,
    lpDecimals,
    zkstDecimals
  } = useStaking()

  const {
    userTierInfo,
    loading: loadingTier,
    getTier,
  } = useZKTier()

  const [stakeFormType, setStakeFormType] = useState<FormType>(FormTypes.STAKE)
  const [amountToStake, setAmountToStake] = useState('0')
  const [amountToUnstake, setAmountToUnstake] = useState('0')

  const daysSinceLastStake = useMemo(
    () => lastStaked ? differenceInDays(new Date(), lastStaked) : 0,
    [lastStaked]
  )

  const estimatedRewards = useMemo(() => {
    if (!isStakingActive) {
      return 0;
    }
    const monthInSeconds = 30 * 24 * 3600;
    const totalStakedNumber = balanceToNumber(stakingStats.totalStaked, lpDecimals);
    const rewardsPerSecondNumber = balanceToNumber(rewardsPerSecond, zkstDecimals);
    return +amountToStake * ((rewardsPerSecondNumber * monthInSeconds) / ((totalStakedNumber + +amountToStake) || 1));
  }, [amountToStake, stakingStats, rewardsPerSecond, isStakingActive, zkstDecimals, lpDecimals]);

  const setMaxToStake = useCallback(() => {
    setAmountToStake(balanceToNumeric(lpBalance, lpDecimals))
  }, [lpBalance, lpDecimals])

  const setMaxToUnstake = useCallback(() => {
    setAmountToUnstake(balanceToNumeric(staked, lpDecimals))
  }, [staked, lpDecimals])

  const disableStaking = useMemo(() => {
    return +amountToStake <= 0
      || lpBalance.isLessThan(numericToBalance(amountToStake, lpDecimals))
      || allowance.isLessThan(numericToBalance(amountToStake, lpDecimals))
      || !isStakingActive;
  }, [amountToStake, lpBalance, allowance, lpDecimals, isStakingActive])

  const disableUnstaking = useMemo(() => {
    return +amountToUnstake <= 0
      || staked.isLessThan(numericToBalance(amountToUnstake, lpDecimals))
  }, [amountToUnstake, staked, lpDecimals])

  const handleApprove = () => onApprove()

  const handleStake = useCallback(async () => {
    if (disableStaking) {
      return
    }
    const amount = numericToUint256(amountToStake, lpDecimals)
    await onStake(
      amount,
      {
        onHash: () => setAmountToStake('0')
      }
    )
    await getTier()
  }, [amountToStake, onStake, lpDecimals, disableStaking])

  const handleUnstake = useCallback(async () => {
    if (disableUnstaking) {
      return
    }
    const amount = numericToUint256(amountToUnstake, lpDecimals)
    await onUnstake(
      amount,
      {
        onHash: () => setAmountToUnstake('0')
      }
    )
    await getTier()
  }, [amountToUnstake, onUnstake, lpDecimals, disableUnstaking])

  const handleClaim = useCallback(async () => {
    if (!balanceToNumber(rewards, zkstDecimals)) {
      return
    }
    await onClaim()
  }, [onClaim, zkstDecimals, rewards])

  return (
    <div className="account-staking">
      <SEOTags { ...seoTags.accountStaking } />
      <section className='stake-allocation-section'>
        <Container>
          <Row className="stake-allocation-row">
            <Col className="text-left" md={{ span: 6 }}>
              <h4 className="title text-uppercase">
                Earn $ZKST rewards by staking your ZKST-USDC LP Tokens
              </h4>
              <div className="bottom-description">
                The amount of allocation will depend on the amount locked.
              </div>
            </Col>
          </Row>
          <Row className="stake-block account-staking__stake-block">
            <Col md={{ span: 6 }}>
              <div className='stake-block__info tile'>
                <BalanceItem
                  title="APY"
                  balance={APY.toString()}
                  token="%"
                  tooltipText={stakingTooltips.APY}
                />
                <BalanceItem
                  title="Amount staked"
                  balance={balanceToCurrency(staked, lpDecimals)}
                  token="LP"
                />
                <BalanceItem
                  title="Current rewards"
                  balance={balanceToCurrency(rewards, zkstDecimals)}
                  token="ZKST"
                  tooltipText={stakingTooltips.currentRewards}
                />
              </div>
              <div className="stake-block__info tile">
                <dl className='info-list'>
                  <div className="info-list__item">
                    <dt className="name">
                      Last Stake
                      <CommonTooltip
                        id="apy-tooltip"
                        placement="bottom-start"
                      >
                        {stakingTooltips.lastStake}
                      </CommonTooltip>
                    </dt>
                    <dd className='value'>
                      {
                        lastStaked ? (
                          <span>
                            {daysSinceLastStake}{' '}
                            <small>day(s) ago</small>
                          </span>
                        ) : '--'
                      }
                    </dd>
                  </div>
                  <div className="info-list__item">
                    <dt className="name">
                      Current Penalty
                      <CommonTooltip
                        id="apy-tooltip"
                        placement="bottom-start"
                      >
                        {stakingTooltips.currentPenalty}
                      </CommonTooltip>
                    </dt>
                    <dd className='value'>{balanceToCurrency(currentPenalty, zkstDecimals)}</dd>
                  </div>
                </dl>
              </div>
            </Col>
            <Col xs={{ span: 7 }} xl={{ span: 6 }}>
              <div className='stake-form'>
                <Tabs
                  id="stake-block-tabs"
                  className="stake-block-tabs light"
                  activeKey={stakeFormType}
                  onSelect={(eventKey => setStakeFormType(eventKey as FormType))}
                >
                  <Tab
                    eventKey={FormTypes.STAKE}
                    title={FormTypes.STAKE.toLowerCase()}
                    className="stake-block-tab-stake"
                  >
                    <div className='stake-form__heading'>
                      <Image src={LPToken} alt="LP Token" />
                      <div>
                        <p>
                          <span className="fw-bold me-1">ZKST-USDC LP{' '}</span>
                          {/*<RoundButton
                            href={buyLpTokenLink}
                            disabled={!buyLpTokenLink}
                            size="small"
                            color="LIGHT"
                          >
                            Get the token <LinkArrow />
                          </RoundButton>
                          <CommonTooltip id="lp-token-tooltip">
                            {stakingTooltips.getTokenLink}
                          </CommonTooltip>*/}
                        </p>
                        <p className="info-list">
                          <span className='name'>Available to stake: </span>
                          <span className='value'>{balanceToCurrency(lpBalance, lpDecimals)}</span>
                        </p>
                      </div>
                    </div>
                    <InputGroup className="stake-form__input-group">
                      <InputGroup.Prepend>
                        Amount:
                      </InputGroup.Prepend>
                      <FormControl
                        placeholder="0.0"
                        type="number"
                        inputMode="numeric"
                        value={amountToStake}
                        onChange={(e) => setAmountToStake(e.target.value)}
                        isInvalid={disableStaking}
                        isValid={!disableStaking}
                      />
                      <InputGroup.Append>
                        <RoundButton size="small" color="LIGHT" onClick={setMaxToStake}>
                          MAX
                        </RoundButton>
                      </InputGroup.Append>
                    </InputGroup>
                    <BalanceItem
                      image="/token-logos/ZK.svg"
                      title="Your Estimated Rewards"
                      balance={numberToCurrency(estimatedRewards)}
                      token="ZKST/month"
                      tooltipText={stakingTooltips.estimatedRewards}
                    />
                    <div className='stake-block__buttons'>
                      <RoundButton
                        size="large"
                        color="DARK"
                        disabled={allowance.isGreaterThanOrEqualTo(numericToBalance(amountToStake, lpDecimals))}
                        onClick={handleApprove}
                      >
                        Approve
                      </RoundButton>
                      <RoundButton
                        size="large"
                        color="DARK"
                        disabled={disableStaking}
                        onClick={handleStake}
                      >
                        Stake
                      </RoundButton>
                    </div>
                    <ApprovalSteps fillingCondition={allowance.isGreaterThanOrEqualTo(numericToBalance(amountToStake, lpDecimals))} />
                  </Tab>
                  <Tab
                    eventKey={FormTypes.UNSTAKE}
                    title={FormTypes.UNSTAKE.toLowerCase()}
                    className="stake-block-tab-unstake"
                  >
                    <div className='stake-form__heading'>
                      <Image src={LPToken}/>
                      <div>
                        <span className="fw-bold">ZKST-USDC LP</span>
                      </div>
                    </div>
                    <InputGroup className="stake-form__input-group">
                      <InputGroup.Prepend>
                        Amount:
                      </InputGroup.Prepend>
                      <FormControl
                        placeholder="0.0"
                        type="number"
                        inputMode="numeric"
                        value={amountToUnstake}
                        onChange={(e) => setAmountToUnstake(e.target.value)}
                        isInvalid={disableUnstaking}
                        isValid={!disableUnstaking}
                      />
                      <InputGroup.Append>
                        <RoundButton size="small" color="LIGHT" onClick={setMaxToUnstake}>
                          MAX
                        </RoundButton>
                      </InputGroup.Append>
                    </InputGroup>
                    {
                      !!balanceToNumber(currentPenalty, zkstDecimals) && (
                        <div className='form-message form-message--warning text-center'>
                          <InfoIcon />
                          <span>The 50% of rewards will be lost if you staked less then 30 days ago</span>
                        </div>
                      )
                    }
                    <div className='stake-block__buttons'>
                      <RoundButton
                        size="large"
                        color="DARK"
                        disabled={!balanceToNumber(rewards)}
                        onClick={handleClaim}
                      >
                        Claim
                      </RoundButton>
                      <RoundButton
                        size="large"
                        color="DARK"
                        disabled={disableUnstaking}
                        onClick={handleUnstake}
                      >
                        Unstake & Claim ZKST
                      </RoundButton>
                    </div>
                  </Tab>
                  <Tab
                    eventKey={FormTypes.STATS}
                    title={FormTypes.STATS.toLowerCase()}
                  >
                    <LoadingWrap loading={!stakingStats}>
                      {!!stakingStats && (
                        <dl className='info-list staking-stats'>
                          <div>
                            <dt className='name'>Total rewards</dt>
                            <dd className='value'>{balanceToCurrency(stakingStats.totalRewards, zkstDecimals)} ZKST</dd>
                          </div>
                          <div>
                            <dt className='name'>Total Staked</dt>
                            <dd className='value'>{balanceToCurrency(stakingStats.totalStaked, lpDecimals)} LP</dd>
                          </div>
                          <div>
                            <dt className='name'>Locked Rewards</dt>
                            <dd className='value'>{balanceToCurrency(stakingStats.lockedRewards, zkstDecimals)} ZKST</dd>
                          </div>
                          <div>
                            <dt className='name'>Unlocked Rewards</dt>
                            <dd className='value'>{balanceToCurrency(stakingStats.unlockedRewards, zkstDecimals)} ZKST</dd>
                          </div>
                          <div>
                            <dt className='name'>Program Duration</dt>
                            <dd className='value'>
                              {
                                stakingStats.programDuration ? `${stakingStats.programDuration} left` : '--'
                              }
                            </dd>
                          </div>
                        </dl>
                      )}
                    </LoadingWrap>
                  </Tab>
                </Tabs>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="mb-5">
        <Container>
          <Row className="mb-4">
            <Col>
              <LoadingWrap loading={loadingTier}>
                <p className="text-uppercase fs-3 fw-semibold">
                  Your ZK power is <span className='orange-text fw-bold'>{' '}{numberToCurrency(userTierInfo?.zkstPower ?? 0)}</span>.{' '}
                  {!!(userTierInfo && userTierInfo.tier) ? (
                    <>
                      Your ZKStudios Tier is <b className="orange-text">{userTierInfo.tier}</b>
                    </>
                  ) : (
                    <>
                      You don't have a ZKStudios Tier yet
                    </>
                  )
                  }
                </p>
              </LoadingWrap>
            </Col>
          </Row>
          <ZKPowerLevel currentTier={userTierInfo?.tier} />
        </Container>
      </section>
      <section className='kyc-section'>
        <Container>
          <KYCBadge />
        </Container>
      </section>
    </div>
  )
}
