import React, { useCallback, useEffect, useState } from 'react';
import { RoundButton, Spinner } from '@components/common';
import { Col, Form, Row } from 'react-bootstrap';
import { FormikHelpers, useFormik } from 'formik';
import * as yup from 'yup';
import './IDOForm.scss'
import { FormFillStatusBar } from '@/components';
import {
  allFieldNames,
  contactMethods,
  fundsOptions,
  initialValues,
  optionalFieldNames,
  zkSyncOptions,
  positions,
  privacyTypes,
  projectStatuses,
  requiredFieldNames
} from './constants';
import { IDOFormValues } from "./types";

const validationSchema = yup.object().shape({
  projectName: yup.string().required('Project Name is required'),
  position: yup.string().oneOf(positions).required('Choose your position'),
  privacy: yup.string().oneOf(privacyTypes).required('Specify the type of the team'),
  isForZKSync: yup.string().oneOf(zkSyncOptions).required('Confirm the network'),
  description: yup.string().required('Short description is required'),
  whitepaper: yup.string().url('Wrong format of link').required('Whitepaper is required'),
  projectStatus: yup.string().oneOf(projectStatuses).required('Choose the project status'),
  plannedFunds: yup.string().required('Type the amount of funds you plan to raise'),
  currentFunds: yup.string().oneOf(fundsOptions).required('Choose the amount of funds'),
  projectWebsite: yup.string().url('Wrong format of link'),
  projectTelegram: yup.string(),
  projectMedium: yup.string().url('Wrong format of link'),
  telegram: yup.string().required('Type your telegram username to contact'),
  email: yup.string().email('Wrong format of email').required('Type your email to contact'),
  contactMethod: yup.string().oneOf(contactMethods)
});

interface Props {
  onSubmit: (formData: IDOFormValues) => Promise<unknown> | unknown
}

export const IDOForm = ({ onSubmit }: Props) => {
  const [loading, setLoading] = useState(false);
  const [totalFields, setTotalFields] = useState(0)
  const [filledFields, setFilledFields] = useState(0)

  const updateFilledFieldsCount = () => {
    const validField = (fieldName: keyof IDOFormValues): number => (!!touched[fieldName] && !errors[fieldName] ? 1 : 0)

    const optionalFieldsFilled = optionalFieldNames.reduce(
      (acc, field) => validField(field as keyof IDOFormValues) + acc,
      0
    )
    const fieldsTotal = requiredFieldNames.length + optionalFieldsFilled
    const filledFields = allFieldNames.reduce((acc: number, field) => validField(field as keyof IDOFormValues) + acc, 0)

    setFilledFields(filledFields)
    setTotalFields(fieldsTotal)
  }

  const onSubmitHandler = async (
    values: IDOFormValues,
    formikHelpers: FormikHelpers<IDOFormValues>
  ) => {
    if (loading) {
      return;
    }

    setLoading(true);
    await onSubmit(values);
    setLoading(false);
  };

  const {
    errors,
    touched,
    handleSubmit,
    isValid,
    handleChange,
    handleBlur,
    setFieldTouched,
    getFieldProps,
  } = useFormik({
    validateOnChange: true,
    validateOnMount: true,
    validationSchema,
    onSubmit: onSubmitHandler,
    initialValues
  });

  const getFieldPropsUpgraded = (field: keyof IDOFormValues) => {
    const props = getFieldProps(field)
    const onChange = (e: any) => {
      props.onChange(e)
      setFieldTouched(field, true, true)
    }

    return { ...props, onChange }
  }

  const getHandleChangeUpgraded = (field: keyof IDOFormValues) => {
    const onChange = (e: any) => {
      handleChange(e)
      setFieldTouched(field, true, false)
    }

    return { onChange }
  }

  useEffect(() => {
    updateFilledFieldsCount()
  }, [errors, touched])

  return (
    <Form
      noValidate
      className='ido-form marketing-page-form'
      onSubmit={handleSubmit}
    >
      <div className="ido-form__statusbar">
        <FormFillStatusBar filledCount={filledFields} totalCount={totalFields} />
      </div>
      <div className='marketing-page-form__inner custom-scroll'>
        <div className="tile">
          <Row>
            <Col>
              <Form.Group controlId='projectName'>
                <Form.Label>Project Name*</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Project Name'
                  {...getFieldPropsUpgraded('projectName')}
                  isValid={touched.projectName && !errors.projectName}
                  isInvalid={touched.projectName && !!errors.projectName}
                />
                <Form.Control.Feedback type='invalid'>{errors.projectName}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <h4 className='title'>
                What is your position within the Project? *
              </h4>
              {
                positions.map((item) => (
                  <Form.Group key={item} controlId={item} className='form-group--radio'>
                    <Form.Check
                      type='radio'
                      name='position'
                      label={item}
                      value={item}
                      onBlur={handleBlur}
                      {...getHandleChangeUpgraded('position')}
                    />
                  </Form.Group>
                ))
              }
            </Col>
          </Row>
        </div>
        <div className="tile">
          <Row>
            <Col>
              <h4 className='title'>
                Is your team public or anonymous? *
              </h4>
              {
                privacyTypes.map((item) => (
                  <Form.Group key={item} controlId={item} className='form-group--radio'>
                    <Form.Check
                      type='radio'
                      name='privacy'
                      label={item}
                      value={item}
                      onBlur={handleBlur}
                      {...getHandleChangeUpgraded('privacy')}
                    />
                  </Form.Group>
                ))
              }
            </Col>
          </Row>
        </div>
        <div className="tile">
          <Row>
            <Col>
              <h4 className='title'>
                Is your Project designed for the zkSync Era Network? *
              </h4>
              {
                zkSyncOptions.map((item) => (
                  <Form.Group key={item} controlId={item} className='form-group--radio'>
                    <Form.Check
                      type='radio'
                      name='isForZKSync'
                      label={item}
                      value={item}
                      onBlur={handleBlur}
                      {...getHandleChangeUpgraded('isForZKSync')}
                    />
                  </Form.Group>
                ))
              }
            </Col>
          </Row>
        </div>
        <div className="tile">
          <h4 className="title">Project Pitch</h4>
          <Row>
            <Col>
              <Form.Group controlId='description'>
                <Form.Label>Please provide a one sentence pitch of your Project? *</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Add sentence'
                  {...getFieldPropsUpgraded('description')}
                  isValid={touched.description && !errors.description}
                  isInvalid={touched.description && !!errors.description}
                />
                <Form.Control.Feedback type='invalid'>{errors.description}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId='whitepaper'>
                <Form.Label>Please provide a link to your Whitepaper *</Form.Label>
                <Form.Control
                  type='url'
                  placeholder='https://'
                  {...getFieldPropsUpgraded('whitepaper')}
                  isValid={touched.whitepaper && !errors.whitepaper}
                  isInvalid={touched.whitepaper && !!errors.whitepaper}
                />
                <Form.Control.Feedback type='invalid'>{errors.whitepaper}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </div>
        <div className="tile">
          <Row>
            <Col>
              <h4 className='title'>
                What is the current status of your Project? *
              </h4>
              {
                projectStatuses.map((item) => (
                  <Form.Group key={item} controlId={item} className='form-group--radio'>
                    <Form.Check
                      type='radio'
                      name='projectStatus'
                      label={item}
                      value={item}
                      onBlur={handleBlur}
                      {...getHandleChangeUpgraded('projectStatus')}
                    />
                  </Form.Group>
                ))
              }
            </Col>
          </Row>
        </div>
        <div className="tile">
          <h4 className="title">Raise</h4>
          <Row>
            <Col>
              <Form.Group controlId='plannedFunds'>
                <Form.Label>How much do you plan to raise through an IDO? *</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='100000000'
                  {...getFieldPropsUpgraded('plannedFunds')}
                  isValid={touched.plannedFunds && !errors.plannedFunds}
                  isInvalid={touched.plannedFunds && !!errors.plannedFunds}
                />
                <Form.Control.Feedback type='invalid'>{errors.plannedFunds}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label>
                How much have you already raised? *
              </Form.Label>
              {
                fundsOptions.map((item) => (
                  <Form.Group key={item} controlId={item} className='form-group--radio'>
                    <Form.Check
                      type='radio'
                      name='currentFunds'
                      label={item}
                      value={item}
                      onBlur={handleBlur}
                      {...getHandleChangeUpgraded('currentFunds')}
                    />
                  </Form.Group>
                ))
              }
            </Col>
          </Row>
        </div>
        <div className="tile">
          <h4 className="title">
            Links
          </h4>
          <Row>
            <Col>
              <Form.Group controlId='projectWebsite'>
                <Form.Label>Project Website</Form.Label>
                <Form.Control
                  type='url'
                  placeholder='https://'
                  {...getFieldPropsUpgraded('projectWebsite')}
                  isValid={touched.projectWebsite && !errors.projectWebsite}
                  isInvalid={touched.projectWebsite && !!errors.projectWebsite}
                />
                <Form.Control.Feedback type='invalid'>{errors.projectWebsite}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId='projectTelegram'>
                <Form.Label>Project Telegram Group</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='@'
                  {...getFieldPropsUpgraded('projectTelegram')}
                  isValid={touched.projectTelegram && !errors.projectTelegram}
                  isInvalid={touched.projectTelegram && !!errors.projectTelegram}
                />
                <Form.Control.Feedback type='invalid'>{errors.projectTelegram}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId='projectMedium'>
                <Form.Label>Project Medium Blog</Form.Label>
                <Form.Control
                  type='url'
                  placeholder='https://'
                  {...getFieldPropsUpgraded('projectMedium')}
                  isValid={touched.projectMedium && !errors.projectMedium}
                  isInvalid={touched.projectMedium && !!errors.projectMedium}
                />
                <Form.Control.Feedback type='invalid'>{errors.projectMedium}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId='telegram'>
                <Form.Label>Your Telegram *</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='@'
                  {...getFieldPropsUpgraded('telegram')}
                  isValid={touched.telegram && !errors.telegram}
                  isInvalid={touched.telegram && !!errors.telegram}
                />
                <Form.Control.Feedback type='invalid'>{errors.telegram}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId='email'>
                <Form.Label>Your Email *</Form.Label>
                <Form.Control
                  type='email'
                  placeholder='youremail@mail.com'
                  {...getFieldPropsUpgraded('email')}
                  isValid={touched.email && !errors.email}
                  isInvalid={touched.email && !!errors.email}
                />
                <Form.Control.Feedback type='invalid'>{errors.email}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </div>
        <div className="tile">
          <Row>
            <Col>
              <h4 className='title'>
                Preferred Method of Contact
              </h4>
              {
                contactMethods.map((item) => (
                  <Form.Group key={item} controlId={item} className='form-group--radio'>
                    <Form.Check
                      type='radio'
                      name='contactMethod'
                      label={item}
                      value={item}
                      onBlur={handleBlur}
                      {...getHandleChangeUpgraded('contactMethod')}
                    />
                  </Form.Group>
                ))
              }
            </Col>
          </Row>
        </div>
        <Row className='text-center marketing-page-form__submit-row'>
          <Col>
            <RoundButton
              type='submit'
              size='large'
              wide
              disabled={!isValid || loading}
            >
              {
                loading
                  ? <Spinner />
                  : 'Submit'
              }
            </RoundButton>
          </Col>
        </Row>
      </div>
    </Form>
  );
};
