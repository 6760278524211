import { instance as API } from '../config';
import { GetPartnerResponse } from './types';
import { sendExceptionReport } from '@utils/errors';

export const getExclusivePartner = async (referrer: string): Promise<GetPartnerResponse | null> => {
  try {
    const { data } = await API.get('/get-exclusive-partner', {
      params: {
        referrer
      }
    })

    return data as GetPartnerResponse
  } catch (err) {
    sendExceptionReport(err)
    return null
  }
}
