import { IDOFormValues } from "@components/formfills/IDOForm/types";

export const positions = ['CEO', 'Founder / Co-Founder', 'Investor', 'Other'];
export const privacyTypes = ['Public', 'Anonymous'];
export const zkSyncOptions = ['Yes', 'No'];
export const projectStatuses = ['Idea', 'Initial Development', 'Late State of Development', 'Ready to Launch', 'Already Launched'];
export const fundsOptions = ['None', '< $ 100,000', '$ 100,000 - $ 500,000', '$500,000 - $1,000,000', '> $1,000,000 '];
export const contactMethods = ['Email', 'Telegram', 'Twitter'];

export const initialValues: IDOFormValues = {
  projectName: '',
  position: '',
  privacy: '',
  isForZKSync: '',
  description: '',
  whitepaper: '',
  projectStatus: '',
  plannedFunds: '',
  currentFunds: '',
  projectWebsite: '',
  projectTelegram: '',
  projectMedium: '',
  telegram: '',
  email: '',
  contactMethod: ''
};

export const requiredFieldNames = [
  'projectName',
  'position',
  'privacy',
  'isForZKSync',
  'description',
  'whitepaper',
  'projectStatus',
  'plannedFunds',
  'currentFunds',
  'telegram',
  'email'
]

export const optionalFieldNames = ['projectWebsite', 'projectTelegram', 'projectMedium', 'contactMethod']

export const allFieldNames = [...requiredFieldNames, ...optionalFieldNames]