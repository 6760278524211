export enum ZKTiers {
  Lunar = 'Lunar',
  Orbit = 'Orbit',
  Interplanetary = 'Interplanetary',
  Galactic = 'Galactic',
  Universal = 'Universal',
}

export type ZKTier = keyof typeof ZKTiers

export const tierLevels: Record<number, ZKTier> = {
  100: ZKTiers.Lunar,
  500: ZKTiers.Orbit,
  5000: ZKTiers.Interplanetary,
  10000: ZKTiers.Galactic,
  25000: ZKTiers.Universal,
}

export const tierBreakpoints = Object.keys(tierLevels)

export enum TierAccessTypes {
  SURPRISE_LOTTERY = 'surprise_lottery',
  FCFS_LOTTERY = 'fcfs_lottery',
  GUARANTEED = 'guaranteed'
}

export const tierAccesses = {
  [ZKTiers.Lunar]: TierAccessTypes.SURPRISE_LOTTERY,
  [ZKTiers.Orbit]: TierAccessTypes.FCFS_LOTTERY,
  [ZKTiers.Interplanetary]: TierAccessTypes.FCFS_LOTTERY,
  [ZKTiers.Galactic]: TierAccessTypes.FCFS_LOTTERY,
  [ZKTiers.Universal]: TierAccessTypes.FCFS_LOTTERY,
}

export const allocationMultipliers = {
  [ZKTiers.Lunar]: null,
  [ZKTiers.Orbit]: 1,
  [ZKTiers.Interplanetary]: 2,
  [ZKTiers.Galactic]: 4,
  [ZKTiers.Universal]: 8,
}

export const zkPowerMultipliers = {
  ZK_LOCKED: 1,
  LP_STAKED: 1000000,
  HIROS_AMOUNT: 4500
}

export const lotteryTicketPrice = 500

