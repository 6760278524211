import { OGProperties, PageTags } from '../types';
import { RoutesPaths } from '@router/constants';

const title = 'Account Lockup'
const url = window.location.origin + RoutesPaths.ACCOUNT.LOCKING

export const accountLockup: PageTags = {
  title: `| ${title}`,
  url: url,
  properties: [
    {
      property: OGProperties.title,
      content: `FireStarter | ${title}`,
    },
    {
      property: OGProperties.description,
      content: 'Lock your $ZKST. Determine your Tier. Receive Allocations from ZKStudios Projects',
    },
    {
      property: OGProperties.url,
      content: url,
    },
  ]
}
