import { Container } from 'react-bootstrap';
import { LeaderboardList } from '@components';
import './Leaderboard.css'
import PageHeading from "@components/layout/PageHeading/PageHeading";
import Banner from '@assets/banners/leaderboard-banner.png'

export const Leaderboard = () => {

  return (
    <div className='leaderboard-page page'>
      <PageHeading banner={Banner}>
        <PageHeading.Title>
          Leaderboard
        </PageHeading.Title>
      </PageHeading>
      <Container className="py-5">
        <p className='subtitle'>
          Reach the top. <strong>Leaders receive higher allocations</strong>
          and <strong>more access to our IDO’s.</strong> The more zkST Power you accumulate, the better chance you have of winning.
        </p>
      </Container>
      <section className='leaders-section'>
        <Container>
          <LeaderboardList />
        </Container>
      </section>
    </div>
  )
}
