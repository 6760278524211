import axios from 'axios';
import { GasPriceOptions } from './types';
import { mapGasPrice } from './mappers';

const baseURL = process.env.REACT_APP_ETH_GASSTATION ?? 'https://www.etherchain.org/api'

const instance = axios.create({
  baseURL
})

export const getEthGasPrice = async (): Promise<GasPriceOptions> => {
  const { data } = await instance.get('/gasPriceOracle')
  return mapGasPrice(data)
}
