import { useCallback, useEffect, useState } from 'react'
import { ZKTier, ZKTiers, TierAccessTypes } from './constants';
import { useWeb3React } from '@web3-react/core';
import { getUserTier } from '@api/tiers/getUserTier'
import { IUserTier } from '@api/tiers/types'

export const tiersColors: Record<ZKTier, string> = {
  [ZKTiers.Lunar]: '#FF6439',
  [ZKTiers.Orbit]: '#FF6439',
  [ZKTiers.Interplanetary]: '#FF6439',
  [ZKTiers.Galactic]: '#FF6439',
  [ZKTiers.Universal]: '#FF6439',
}

export const tiersImages: Record<ZKTier, string> = {
  [ZKTiers.Lunar]: 'tiers/lunar.svg',
  [ZKTiers.Orbit]: 'tiers/orbit.svg',
  [ZKTiers.Interplanetary]: 'tiers/interplanetary.svg',
  [ZKTiers.Galactic]: 'tiers/galactic.svg',
  [ZKTiers.Universal]: 'tiers/universal.svg',
}

export interface UserTierInfo extends IUserTier {
  tierColor: string | null
  tierImage: string | null
}

export interface IUseZKTier {
  userTierInfo: UserTierInfo | null,
  getTier: () => Promise<void>
  loading: boolean
}

export const useZKTier = (): IUseZKTier => {
  const { account } = useWeb3React()
  const [userTierInfo, setUserTier] = useState<UserTierInfo | null>(null)
  const [loading, setLoading] = useState(false)

  const getTier = useCallback(async () => {
    if (!account) return
    setLoading(true)
    const tierInfo = await getUserTier(account)
    setUserTier(tierInfo ? {
      ...tierInfo,
      tierColor: tierInfo.tier ? tiersColors[tierInfo.tier] : null,
      tierImage: tierInfo.tier ? tiersImages[tierInfo.tier] : null
    } : null)
    setLoading(false)
  }, [account])

  useEffect(() => {
    if (account) {
      getTier()
    }
  }, [account])

  return {
    userTierInfo,
    getTier,
    loading
  }
}
