import React, { useMemo } from 'react';
import { RoundButton } from '@components/common';
import { ProjectDescription, ProjectDescriptionProps } from '../ProjectDescription';
import { ProjectStats, ProjectNonContractProps } from '../ProjectStats';
import { ProjectStatusesProps, ProjectStatusProp, TokenInfo } from '../types';
import { ContractAddress } from '@contracts/address';
import './ProjectWidget.css';
import { generatePath } from 'react-router-dom';
import { RoutesPaths } from '@router/constants';
import { NetworkId } from '@contracts/networks';
import { useNetwork } from '@hooks/useNetwork';


export interface ProjectProps extends
  Omit<ProjectNonContractProps, 'status' | 'fundTokenName' | 'rewardTokenName' | 'participants'>,
  Omit<ProjectDescriptionProps, 'status' | 'fundTokenName'>
{
  id: string
  mainColor?: string
  chainId: NetworkId
  status: ProjectStatusProp
  presaleContract?: ContractAddress
  fundToken: TokenInfo
  rewardToken: TokenInfo
}

const ProjectWidget = ({
  id,
  mainColor,
  chainId,
  logo,
  name,
  projectType,
  subtitle,
  description,
  status,
  network,
  fundToken,
  rewardToken,
  whitelistingOpens,
  whitelistingCloses,
  privateOpens,
  privateCloses,
  publicOpens,
  publicCloses,
  socialLinks,
  isExternalPresale,
}: ProjectProps) => {
  const {
    checkIfSelected,
  } = useNetwork()

  const isProjectNetworkSelected = useMemo(() => checkIfSelected(chainId), [chainId, checkIfSelected])

  return (
    <div
      className="project-widget tile"
      data-color={mainColor}
      style={{
        // @ts-ignore
        '--project-main-color': mainColor,
      }}
    >
      <div className="project-widget__inner">
        <ProjectDescription
          logo={logo}
          name={name}
          subtitle={subtitle}
          status={status}
          network={network}
          fundTokenName={fundToken.name}
          description={description}
          socialLinks={socialLinks}
          projectType={projectType}
        />

        <ProjectStats
          fundTokenName={fundToken.name}
          rewardTokenName={rewardToken.name}
          status={status}
          whitelistingOpens={whitelistingOpens}
          whitelistingCloses={whitelistingCloses}
          privateOpens={privateOpens}
          privateCloses={privateCloses}
          publicOpens={publicOpens}
          publicCloses={publicCloses}
          isExternalPresale={isExternalPresale}
          isProjectNetworkSelected={isProjectNetworkSelected}
        />

        <div className='project-widget__buttons'>
          <RoundButton to={generatePath(RoutesPaths.PROJECT, { id })} size="large">
            Select
          </RoundButton>
          {status === ProjectStatusesProps['Registration Open'] &&
            <RoundButton to={generatePath(RoutesPaths.WHITELIST, { project_id: id })} size="large" color="DARK">
              Apply to whitelist
            </RoundButton>
          }
        </div>
      </div>
    </div>
  )
}

ProjectWidget.defaultProps = {
  mainColor: 'rgba(254, 111, 70, 0.5)'
}

export { ProjectWidget }
