import { generatePath, useParams } from 'react-router-dom';
import { useProjectsState } from '@contracts/hooks/useProjects';
import React, { useCallback, useEffect, useMemo } from 'react';
import { ProjectStatusesProps, ProjectStatusProp } from '@components/Project/types';
import { useApproval } from '@contracts/hooks/useApproval';
import { useWhitelist, WhitelistStatuses } from '@contracts/hooks/useWhitelist';
import { usePresale } from '@contracts/hooks/usePresale';
import { balanceToNumber } from '@utils/balanceFormatter';
import {
  AddNetworkButton,
  BackLink,
  DynamicImage,
  LoadingWrap,
  PartnerPassword,
  ProjectDescription,
  ProjectStats,
  RoundButton,
  SEOTags,
  SwapForm
} from '@components';
import { OGProperties } from '@/seo-content/types';
import { Col, Container, Image, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { useExclusivePartner } from '@contracts/hooks/useExclusivePartner';
import { ZKSTProjectId } from '@constants';
import { RoutesPaths } from '@router/constants';
import WLIcon from '@assets/account.svg';
import WLNotPassedIcon from '@assets/account-warning.svg';
import { createSAFTDocContent, SAFTfonts } from '@components/Project/SAFTModal/contents/pdf';
import { generatePdfAndOpen } from '@utils/pdfMake';
import { projectGetters } from '@contracts/getters/projectGetters';
import { useNetwork } from '@hooks/useNetwork';
import { getNetworkIcon } from '@contracts/networks';

interface ParamTypes {
  referrer: string
}

export const ExclusiveProject = () => {
  const { referrer } = useParams<ParamTypes>()

  const {
    loading,
    currentProject: project,
    getProjectById
  } = useProjectsState()

  const {
    passwordError,
    submittedPassword,
    submitPartnerPassword,
    partner,
    getPartnerInfo
  } = useExclusivePartner(referrer)

  useEffect(() => {
    if (!project || project.id !== ZKSTProjectId) {
      getProjectById(ZKSTProjectId as string)
    }
  }, [project, referrer])

  useEffect(() => {
    getPartnerInfo()
  }, [referrer])

  const statusMessage: ProjectStatusProp = useMemo(
    () => project?.presale.statusMessage || ProjectStatusesProps['Coming Soon'],
    [project])

  const {
    checkIfSelected,
    getNetwork,
    currentNetwork
  } = useNetwork()
  const isProjectNetworkSelected = useMemo(() => project ? checkIfSelected(project.chainId) : false, [project, checkIfSelected])
  const projectNetwork = useMemo(() => project ? getNetwork(project.chainId) : null, [project])

  const isPrivatePresaleInProgress = useMemo(
    () => statusMessage === ProjectStatusesProps['Guaranteed Live'],
    [statusMessage]
  )
  const isPublicPresaleInProgress = useMemo(
    () => statusMessage === ProjectStatusesProps['FCFS Live'],
    [statusMessage]
  )
  const isPresaleInProgress = useMemo(
    () => isPublicPresaleInProgress || isPrivatePresaleInProgress,
    [isPublicPresaleInProgress, isPrivatePresaleInProgress]
  )

  const {
    allowance,
    onApprove
  } = useApproval(
    ...(isProjectNetworkSelected ? [
      project?.presale.fund_token.address,
      project?.presale.presale_contract_address
    ] : [])
  )
  const {
    whiteListStatus,
    publicMaxAllocation,
    privateMaxAllocation,
    privatePresaleAllowed,
  } = useWhitelist(
    ZKSTProjectId as string,
    isProjectNetworkSelected ? project?.presale.whitelist_contract_address : undefined
  )
  const {
    fundTokenBalance: balance,
    totalSwapAmount,
    fundsSwapped,
    totalRewardsAmount,
    swappedByUser,
    swappedPrivateByUser,
    swappedPublicByUser,
    swapExchangeRate,
    participants,
    onDeposit,
    onDepositPrivate,
    fundsDecimals,
    rewardsDecimals
  } = usePresale(
    ...(isProjectNetworkSelected ? [
      project?.presale.presale_contract_address,
      project?.presale.fund_token.address,
      project?.presale.reward_token.address
    ] : [])
  )

  const downloadTermsAndConditions = useCallback(async () => {
    if (!project || !partner || !project.saft) {
      return
    }
    const docDefinition = createSAFTDocContent({
      projectName: project.name,
      registrationData: project.saft.company_registration_data,
      exchangeRate: project.saft.exchange_rate,
      companyOfficialName: project.saft.official_company_name,
      rewardTokenName: project.presale.reward_token.name,
      fundTokenName: project.presale.fund_token.name,
      contactEmail: project.saft.contact_email,
    })

    await generatePdfAndOpen({
      docDefinition,
      fonts: SAFTfonts
    })
  }, [project, partner])

  return (
    <div className="project-page page">
      <SEOTags
        url={`${window.location.origin}${generatePath(RoutesPaths.EXCLUSIVE_PROJECT, { referrer })}`}
        title={`| Project ${project?.name || ''}`}
        properties={[
          {
            property: OGProperties.title,
            content: `ZKStudios | Project ${project?.name || ''}`,
          },
          {
            property: OGProperties.description,
            content: 'Swap Tokens during Project Campaign and receive rewards',
          },
          {
            property: OGProperties.url,
            content: `${window.location.origin}${generatePath(RoutesPaths.EXCLUSIVE_PROJECT, { referrer })}`,
          },
        ]}
      />
      {
        !submittedPassword ? (
          <LoadingWrap loading={!partner}>
            {!!partner && (
              <PartnerPassword
                logo={partner.logo}
                name={partner.name}
                description={partner.description}
                passwordError={passwordError}
                onSubmit={submitPartnerPassword}
              />
            )}
          </LoadingWrap>
        ) : (
          <LoadingWrap loading={loading}>
            <section className='details-section'>
              <Container>
                {!!project && partner &&
                <Row className="gx-4 gy-4 gx-xl-5 gy-xl-5">
                  <Col xs={{ span: 12 }} xl={{ span: 6 }}>
                    <BackLink to={RoutesPaths.PROJECTS}>
                      Projects
                    </BackLink>
                    <ProjectDescription
                      logo={partner.logo}
                      name={project.name}
                      projectType={project.project_type}
                      subtitle={partner.project_subtitle}
                      status={statusMessage}
                      network={project.network}
                      fundTokenName={project.presale.fund_token.name}
                      description={project.info.description}
                      onDownloadTerms={downloadTermsAndConditions}
                      socialLinks={project.social_links}
                    />
                    <div className="whitelist-block">
                      <LoadingWrap loading={!whiteListStatus}>
                        {
                          (
                            statusMessage === ProjectStatusesProps['Registration Open'] &&
                            whiteListStatus === WhitelistStatuses.not_submitted
                          ) ? (
                            <RoundButton to={generatePath(RoutesPaths.EXCLUSIVE_WHITELIST, { referrer })} size="large">
                              Apply to whitelist
                            </RoundButton>
                          ) : (
                            <div
                              className={
                                classNames(
                                  'whitelist-badge',
                                  {
                                    'invalid': whiteListStatus !== WhitelistStatuses.passed
                                  }
                                )
                              }
                            >
                              <Image src={whiteListStatus === WhitelistStatuses.passed ? WLIcon : WLNotPassedIcon} />
                              <span
                                className={classNames({ 'red-text': whiteListStatus !== WhitelistStatuses.passed })}
                              >
                                Wallet is {whiteListStatus !== WhitelistStatuses.passed && 'NOT'} whitelisted
                              </span>
                            </div>
                          )
                        }
                      </LoadingWrap>
                    </div>
                  </Col>
                  <Col xs={{ span: 12 }} xl={{ span: 6 }}>
                    {
                      !isProjectNetworkSelected && (
                        <p className="details-section__warning-message">
                          To see full stats switch to the
                          <span style={{ color: projectNetwork?.color }}>{' '}{projectNetwork?.userFriendlyName}{' '}</span>
                          network
                        </p>
                      )
                    }
                    <ProjectStats
                      totalAmount={balanceToNumber(totalSwapAmount, fundsDecimals)}
                      totalRewards={balanceToNumber(totalRewardsAmount, rewardsDecimals)}
                      fundsSwapped={balanceToNumber(fundsSwapped, fundsDecimals)}
                      participants={participants}
                      fundTokenName={project.presale.fund_token.name}
                      rewardTokenName={project.presale.reward_token.name}
                      swapExchangeRate={balanceToNumber(swapExchangeRate, 0)}
                      status={statusMessage}
                      publicOpens={project.presale.public_starts_at}
                      publicCloses={project.presale.public_end_at}
                      minAllocation={partner.min_allocation}
                      maxAllocation={partner.max_allocation}
                      isExternalPresale={projectGetters.getIsExternalPresale(project)}
                      isProjectNetworkSelected={isProjectNetworkSelected}
                      expanded
                    />
                  </Col>
                </Row>
                }
              </Container>
            </section>
            {
              (!!project &&
              statusMessage === ProjectStatusesProps['FCFS Live']) && (
                isProjectNetworkSelected ? (
                  <SwapForm
                    totalSwapAmount={totalSwapAmount}
                    fundsSwapped={fundsSwapped}
                    publicMaxAllocation={publicMaxAllocation}
                    privateMaxAllocation={privateMaxAllocation}
                    privatePresaleAllowed={privatePresaleAllowed}
                    isPublicPhaseInProgress={isPublicPresaleInProgress}
                    isPrivatePhaseInProgress={isPrivatePresaleInProgress}
                    swappedByUser={swappedByUser}
                    swappedPrivateByUser={swappedPrivateByUser}
                    swappedPublicByUser={swappedPublicByUser}
                    balance={balance}
                    fundsDecimals={fundsDecimals}
                    allowance={allowance}
                    whiteListStatus={whiteListStatus ?? WhitelistStatuses.not_submitted}
                    projectId={project.id}
                    fundToken={project.presale.fund_token}
                    onApprove={onApprove}
                    onDeposit={onDeposit}
                    onDepositPrivate={onDepositPrivate}
                  />
                ) : (
                  <section className="swap-section swap-section--wrong-network">
                    <Container>
                      <Row className="tile">
                        <Col className="non-polygon-block text-center text-big">
                          <p>
                            You are on
                            <span className="network-name" style={{ color: currentNetwork?.color }}>
                              {currentNetwork && <img src={getNetworkIcon(currentNetwork)} />}
                              {currentNetwork?.userFriendlyName}
                            </span>
                            now.
                          </p>
                          <p>
                            If you want to participate in this project you need to switch
                            to the
                            <span className="network-name" style={{ color: projectNetwork?.color }}>
                              {projectNetwork && <img src={getNetworkIcon(projectNetwork)} />}
                              {projectNetwork?.userFriendlyName}
                            </span>
                            network
                          </p>
                          <AddNetworkButton size="large" wide networkId={project.chainId} />
                        </Col>
                      </Row>
                    </Container>
                  </section>
                )
              )
            }
            {
              !!project
              && statusMessage === ProjectStatusesProps.Closed
              && whiteListStatus === WhitelistStatuses.passed
              && (
                <section className="claim-section">
                  <Container>
                    <div className='claim-block tile'>
                      <div className='claim-block__title'>
                        <DynamicImage path="claim-image.svg" />
                        <h4>Claim your tokens</h4>
                      </div>
                      {
                        isProjectNetworkSelected ? (
                          <RoundButton to={RoutesPaths.ACCOUNT.MAIN} size="large" wide>
                            Claim now
                          </RoundButton>
                        ) : (
                          <>
                        <span className="claim-block__warning-message">
                          To claim your tokens you need to switch to the
                          <span style={{ color: projectNetwork?.color }}>{' '}{projectNetwork?.userFriendlyName}{' '}</span>
                          network
                        </span>
                            <AddNetworkButton size="large" wide networkId={project.chainId} />
                          </>
                        )
                      }
                    </div>
                  </Container>
                </section>
              )
            }
          </LoadingWrap>
        )
      }
    </div>
  )
}
