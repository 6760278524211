import React, { useState } from 'react';
import {
  JoinForm,
  JoinFormValues,
  FormFillResponseView,
  SEOTags
} from '@components';
import { Container } from 'react-bootstrap';
import { submitToJoinCommunity } from '@api/form-fills';
import { seoTags } from '@/seo-content';
import Banner from '@assets/banners/join-network-banner.png'
import PageHeading from "@components/layout/PageHeading/PageHeading";

export const JoinCommunity = () => {
  const [formStage, setFormStage] = useState<'FILL' | 'RESPONSE'>('FILL')
  const [responseStatus, setResponseStatus] = useState<'error' | 'success' | null>(null)

  const resetForm = () => {
    setFormStage('FILL')
    setResponseStatus(null)
  }

  const submitJoining = async (values: JoinFormValues) => {
    const response = await submitToJoinCommunity(values)
    setResponseStatus(response.result)
    setFormStage('RESPONSE')
  }

  return (
    <div className="page join-our-network-page">
      <SEOTags { ...seoTags.joinNetwork } />
      <PageHeading banner={Banner} large>
        <PageHeading.Title>
          Join Our <br/> Network
        </PageHeading.Title>
      </PageHeading>
      <Container>
        {
          formStage === 'FILL'
            ? <JoinForm onSubmit={submitJoining}/>
            : <FormFillResponseView
              status={responseStatus}
              onReset={resetForm}
            />
        }
      </Container>
    </div>
  )
}
