 import React, { useCallback, useEffect } from 'react';
import { Col, Container, Row, Image, Navbar } from 'react-bootstrap';
import {
  TimerWrap,
  ProjectsList,
  LoadingWrap,
  GetReadyBadge,
  SEOTags,
  ProjectFilter
} from '../../components';
import { dueDateForProjects } from '../../constants';
import './Projects.css';
import { useProjectsState } from '../../contracts/hooks/useProjects';
import { seoTags } from '../../seo-content';
import { useSearchParams } from '../../hooks/useSearchParams';
import { getNetworkIcon, NetworkId, supportedNetworks } from '../../contracts/networks';

export const projectFilterItems = [
  ...Object.values(supportedNetworks).map(item => ({
    value: item.networkId,
    content: <>
      <Image
        width={32}
        height={32}
        src={getNetworkIcon(item)}
        roundedCircle
      />
      {' '}
      {item.userFriendlyName}
    </>
  })),
  {
    value: 'all',
    content: 'All Networks'
  },
]

export const Projects = () => {
  const {
    loading,
    liveProjects,
    upcomingProjects,
    completedProjects,
    getProjects,
    abortRequest,
  } = useProjectsState()

  const loadProjects = useCallback(async () => {
    abortRequest()
    await getProjects()
  }, [abortRequest, getProjects])

  useEffect(() => {
    loadProjects()
  }, [])

  return (
    <div className="projects-page page">
      <SEOTags { ...seoTags.projects } />
      <section className="project-top">
        <Container className="py-5">
          <div className='d-flex align-items-center justify-content-between'>
            <h1 className="title mb-0 text-uppercase">Projects</h1>
          </div>
        </Container>
      </section>
      <section className="projects-live">
        <Container>
          <h3 className="title mb-5"><b className="orange-text">Live</b> Projects</h3>
          <Row className="g-4">
            <LoadingWrap loading={loading}>
              {
                liveProjects.length
                  ? <ProjectsList projects={liveProjects} />
                  : (
                    <Col md={{ span: 6 }}>
                      <div className="tile no-projects-tile">
                        <div className="dots">
                          <div/>
                          <div/>
                          <div/>
                        </div>
                        <p className="tile__description">
                          There are no live projects yet
                        </p>
                      </div>
                    </Col>
                  )
              }
            </LoadingWrap>
          </Row>
        </Container>
      </section>
      <TimerWrap dueDate={dueDateForProjects}>
        <section className="projects-upcoming">
          <Container>
            <h3 className="title mb-5"><b>Upcoming</b> Projects</h3>
            <Row className="g-4">
              <LoadingWrap loading={loading}>
                {
                  upcomingProjects.length
                    ? <ProjectsList projects={upcomingProjects} />
                    : (
                      <Col md={{ span: 6 }}>
                        <div className="tile no-projects-tile">
                          <div className="dots">
                            <div/>
                            <div/>
                            <div/>
                          </div>
                          <p className="tile__description">
                            There are no upcoming projects now
                          </p>
                        </div>
                      </Col>
                    )
                }
              </LoadingWrap>
            </Row>
          </Container>
        </section>
      </TimerWrap>
      <section className="projects-completed">
        <Container>
          <h3 className="title mb-5"><b>Completed</b> Projects</h3>
          <Row className="g-4">
            <LoadingWrap loading={loading}>
              {
                completedProjects.length
                  ? <ProjectsList projects={completedProjects} />
                  : (
                    <Col md={{ span: 6 }}>
                      <div className="tile no-projects-tile">
                        <div className="dots">
                          <div/>
                          <div/>
                          <div/>
                        </div>
                        <p className="tile__description">
                          No projects have been completed yet
                        </p>
                      </div>
                    </Col>
                  )
              }
            </LoadingWrap>
          </Row>
        </Container>
      </section>
      <section className="py-5">
        <Container>
          <Row className="mt-auto">
            <Col>
              <GetReadyBadge />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};
