import { defaultEnvironmentNetworkId, NetworkId } from '@contracts/networks';
import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';
import { useWeb3 } from '@hooks/useWeb3';
import { useNetwork } from '@hooks/useNetwork';
import {
  getLPStakingContract,
  getLpTokenContract,
  getMultiperiodLockingContract
} from '@contracts/getContract';
import { useMemo } from 'react';

export const useMainContract = (getter: (currentChainId: NetworkId, web3: Web3) => Contract | null): Contract | null => {
  const web3 = useWeb3()
  const { currentNetworkId } = useNetwork()

  if (currentNetworkId !== defaultEnvironmentNetworkId) {
    throw new Error('Calling internal contract with a non default network')
  }

  return useMemo(
    () => getter(currentNetworkId, web3),
    [getter, currentNetworkId, web3]
  )
}

export const useMultiperiodLockingContract = () => {
  return useMainContract(getMultiperiodLockingContract)
}

export const useLpStakingContract = () => {
  return useMainContract(getLPStakingContract)
};

export const useLpTokenContract = () => {
  return useMainContract(getLpTokenContract)
};
