import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ApprovalSteps,
  Divider,
  DynamicImage,
  KYCBadge,
  LoadingWrap,
  LocksTable,
  RoundButton,
  SEOTags,
  Spinner,
} from '@components';
import { seoTags } from '@/seo-content';
import {
  Alert,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  FormControl,
  Image,
  InputGroup,
  Row
} from 'react-bootstrap';
import { useZKTier } from '@contracts/hooks/useZKTier/useZKTier';
import InputIcon from '../../../assets/input-icon.svg';
import {
  balanceToCurrency,
  balanceToNumeric,
  numberToPercentage,
  numericToBalance,
  numericToUint256
} from '@utils/balanceFormatter';
import BigNumber from 'bignumber.js';
import './LockupMultiperiod.scss'
import { ReactComponent as InfoIcon } from '../../../assets/info-icon.svg';
import {
  useMultiperiodLocking
} from '@contracts/hooks/useMultiperiodLocking';
import { useWeb3React } from '@web3-react/core';
import { addMilliseconds, isFuture } from 'date-fns'
import { formatDateToUTC } from '@utils/dates';
import { useApproval } from '@contracts/hooks/useApproval';
import { tokenAddresses } from '@contracts/address';
import { isDefined } from '@utils/object';
import classNames from 'classnames';
import { useDecimals, useTokenBalance } from '@contracts/hooks/useBalances';
import { getReadablePeriod } from '@components/Lockup/utils';
import { LockupPeriodOptions } from '@components/Lockup/LockupPeriodOptions/LockupPeriodOptions';
import { buyZKSTLink, KuCoinZKSTLink } from '@constants';
import KuCoinLogo from '@assets/features/kucoin-logo.png';
import QuickSwapLogo from '@assets/features/quickswap-logo.png';

const ERROR_DISMISS_TIMEOUT = 10000

export const LockupMultiperiod = () => {
  const {
    loadingPeriods,
    loadingEntries,
    lockPeriods,
    lockEntries,
    totalLocked,
    getEntries,
    lock,
    unlock,
    unlockEarly,
    getPenalty,
  } = useMultiperiodLocking()

  const {
    allowance,
    onApprove
  } = useApproval(
    tokenAddresses.zkstToken,
    tokenAddresses.multiperiodLocking
  )

  const { account } = useWeb3React()
  const {
    loading: loadingTier,
    userTierInfo,
    getTier,
  } = useZKTier()

  const [periodIndex, setPeriodIndex] = useState(0)
  const [isLocking, setIsLocking] = useState(false)
  const [isUnlocking, setIsUnlocking] = useState(false)
  const [amountToLock, setAmountToLock] = useState('0')
  const [lockingError, setLockingError] = useState<string | undefined>()
  const [unlockingError, setUnlockingError] = useState<string | undefined>()
  const [activeRecordId, setActiveRecordId] = useState<string | undefined>()
  const [unlockPenalty, setUnlockPenalty] = useState<BigNumber | undefined>()

  const balance = useTokenBalance(
    tokenAddresses.zkstToken,
    (isLocking || isUnlocking),
  )
  const decimals = useDecimals(tokenAddresses.zkstToken)

  const goToUnlock = useCallback(async(stakeId: string) => {
    if (!lockEntries) return
    setUnlockPenalty(undefined)
    setActiveRecordId(stakeId)
    const penalty = await getPenalty(stakeId)
    setUnlockPenalty(penalty)
  }, [lockEntries, getPenalty])

  const goToLock = useCallback(() => {
    setActiveRecordId(undefined)
    setUnlockPenalty(undefined)
  }, [])

  const activeRecord = useMemo(() => {
    if (!lockEntries || !activeRecordId) return
    return lockEntries[activeRecordId]
  }, [lockEntries, activeRecordId])

  const activeRecordTier = useMemo(() => {
    return (activeRecord && lockPeriods) ? lockPeriods[activeRecord.tierIndex] : undefined
  }, [activeRecord, lockPeriods])

  useEffect(() => {
    if (lockPeriods && account && !isLocking && !isUnlocking) {
      getEntries()
      getTier()
    }
  }, [lockPeriods, account, isLocking, isUnlocking])

  const setMaxToLock = useCallback(() => {
    setAmountToLock(balanceToNumeric(balance, decimals))
  }, [balance, decimals])

  const disableLocking = useMemo(() => {
    return +amountToLock <= 0
      || isLocking
      || balance.isLessThan(numericToBalance(amountToLock, decimals))
      || allowance.isLessThan(numericToBalance(amountToLock, decimals))
      || !isDefined(periodIndex)
      || !lockPeriods
      || !lockPeriods[periodIndex].isActive
  }, [
    amountToLock,
    isLocking,
    balance,
    allowance,
    decimals,
    periodIndex,
    lockPeriods,
  ])

  const setLockErrorMessage = (msg: string) => {
    setLockingError(msg)
    setTimeout(() => setLockingError(undefined), ERROR_DISMISS_TIMEOUT)
  }

  const setUnlockErrorMessage = (msg: string) => {
    setUnlockingError(msg)
    setTimeout(() => setUnlockingError(undefined), ERROR_DISMISS_TIMEOUT)
  }

  const handleLock = useCallback(async () => {
    if (disableLocking) return
    setIsLocking(true)
    const amount = numericToUint256(amountToLock, decimals)
    const result = await lock(
      amount,
      periodIndex,
      {
        onHash: () => setAmountToLock('0')
      }
    )
    setIsLocking(false)
    if ('error' in result) {
      setLockErrorMessage('Transaction was failed')
      return
    }
  }, [
    amountToLock,
    decimals,
    disableLocking,
    lockPeriods,
    periodIndex,
    lock,
  ])

  const disableUnlocking = useMemo(() => {
    return !activeRecord
      || !activeRecordTier
      || isUnlocking
      || !unlockPenalty
      || activeRecord.unstakedAt > 0
      || loadingEntries
      || isFuture(activeRecord.stakedAt + activeRecordTier.fullPenaltyCliff)
  }, [
    activeRecord,
    activeRecordTier,
    isUnlocking,
    unlockPenalty,
    loadingEntries
  ])

  const handleUnlock = useCallback(async () => {
    if (disableUnlocking) return
    setIsUnlocking(true)

    const isEarlyUnlocking = isFuture(addMilliseconds(activeRecord!.stakedAt, activeRecordTier!.lockPeriod))
    const unlockMethod = isEarlyUnlocking ? unlockEarly : unlock

    const result = await unlockMethod(activeRecord!.stakeId)
    setIsUnlocking(false)
    if ('error' in result) {
      setUnlockErrorMessage('Transaction was failed')
      return
    }
  }, [
    disableUnlocking,
    activeRecord,
    activeRecordTier,
    unlock,
    unlockEarly,
  ])

  return (
    <div className={'lockup-page account-lockup'}>
      <SEOTags {...seoTags.accountLockupNew} />
      <section className='lockup-content'>
        <Container>
          <Row className={'gx-4 gy-4 gx-xl-5 gy-xl-5'}>
            <Col
              xs={{ span: 12 }}
              xl={{ span: 6 }}
            >
              <div className='lockup-content__caption mb-4'>
                <h4 className="title mb-3">
                  Lock your <span className="orange-text">TOKEN</span>, get a Tier
                </h4>
                <div className="bottom-description">
                  The amount of allocation will depend on the amount locked.
                </div>
              </div>
              {/*<div className='d-flex mb-4'>
                <DropdownButton
                  title="Buy $ZKST Tokens"
                  className="buy-zkst-dropdown"
                >
                  <Dropdown.Item
                    className="buy-zkst-dropdown__item"
                    href={KuCoinZKSTLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image src={KuCoinLogo} roundedCircle />
                    <span>KUCOIN</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="buy-zkst-dropdown__item"
                    href={buyZKSTLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image src={QuickSwapLogo} roundedCircle />
                    <span>QUICKSWAP</span>
                  </Dropdown.Item>
                </DropdownButton>
              </div>*/}
              <div className='tile lockup-content__locks lockup-locks'>
                <h3 className='title mb-5'>My Locks</h3>
                <LocksTable
                  lockPeriods={lockPeriods}
                  lockEntries={lockEntries}
                  loading={loadingEntries}
                  onSelect={goToUnlock}
                />
              </div>
            </Col>
            <Col
              xs={{ span: 12 }}
              xl={{ span: 6 }}
            >
              <div className={classNames(
                'tile lockup-form',
                {
                  'hidden': !!activeRecord
                }
              )}>
                <h3 className='title mb-4'>Lock</h3>
                <div className='lockup-form__tier'>
                  <LoadingWrap loading={loadingTier}>
                    {
                      (!!userTierInfo && userTierInfo.tier) ? (
                        <>
                          <DynamicImage path={userTierInfo.tierImage!} />
                          Your ZKStudios tier is {' '}
                          <b className='orange-text'>
                            {userTierInfo.tier}
                          </b>
                        </>
                      ) : (
                        <>You don't have a ZKStudios tier yet</>
                      )
                    }
                  </LoadingWrap>
                </div>
                <Divider />
                <div className='lockup-form__balances'>
                  <dl className='info-list'>
                    <div>
                      <dt className='name'>Available to lock</dt>
                      <dd className='value'>{balanceToCurrency(balance, decimals)} <small>ZKST</small></dd>
                    </div>
                    <div>
                      <dt className='name'>Amount locked</dt>
                      <dd className='value'>
                        <LoadingWrap loading={loadingEntries}>
                          {balanceToCurrency(totalLocked, decimals)} <small>ZKST</small>
                        </LoadingWrap>
                      </dd>
                    </div>
                  </dl>
                </div>
                <div className='lockup-form__controls'>
                  <InputGroup className="lockup-form__input-group">
                    <InputGroup.Prepend>
                      <Image
                        src={InputIcon}
                        width={32}
                        height={32}
                        roundedCircle
                        className='lockup-form__input-icon'
                      />
                    </InputGroup.Prepend>
                    <FormControl
                      className="lockup-form__input"
                      placeholder="0.0"
                      type="number"
                      value={amountToLock}
                      onChange={(e) => setAmountToLock(e.target.value)}
                    />
                    <InputGroup.Append>
                      <RoundButton
                        size="small"
                        color="LIGHT"
                        onClick={setMaxToLock}
                      >
                        MAX
                      </RoundButton>
                    </InputGroup.Append>
                  </InputGroup>
                  <h5 className='grey-text fw-normal my-4'>
                    Locking period
                  </h5>
                  <div className='lockup-form__options mt-2 mb-5'>
                    <LoadingWrap loading={loadingPeriods}>
                      {
                        !!lockPeriods?.length && (
                          <LockupPeriodOptions
                            lockPeriods={lockPeriods}
                            value={periodIndex}
                            onChange={setPeriodIndex}
                          />
                        )
                      }
                    </LoadingWrap>
                  </div>
                  <div className="lockup-form__buttons">
                    <RoundButton
                      size="large"
                      color="DARK"
                      disabled={allowance.isGreaterThanOrEqualTo(numericToBalance(amountToLock, decimals))}
                      onClick={() => onApprove()}
                    >
                      Approve
                    </RoundButton>
                    <RoundButton
                      size="large"
                      color="DARK"
                      disabled={disableLocking}
                      onClick={handleLock}
                    >
                      {isLocking ? <Spinner /> : 'LOCK'}
                    </RoundButton>
                  </div>
                  <ApprovalSteps fillingCondition={allowance.isGreaterThanOrEqualTo(numericToBalance(amountToLock, decimals))} />
                  <div
                    className={classNames(
                      'form-message form-message--warning text-center mt-3',
                      { hidden: !lockingError }
                    )}
                  >
                    <InfoIcon />
                    <span>{lockingError}</span>
                  </div>
                </div>
              </div>
              <div className={classNames(
                'tile lockup-form lockup-form--unlock',
                {
                  'hidden': !activeRecord
                }
              )}>
                <button onClick={goToLock} disabled={isUnlocking} className={'btn-close'} />
                <h3 className='title mb-4'>Unlock</h3>
                <div className='lockup-form__tier'>
                  <LoadingWrap loading={loadingTier}>
                    {
                      (!!userTierInfo && userTierInfo.tier) ? (
                        <>
                          <DynamicImage path={userTierInfo.tierImage!} />
                          Your ZKStudios tier is {' '}
                          <b className='orange-text'>
                            {userTierInfo.tier}
                          </b>
                        </>
                      ) : (
                        <>You don't have a ZKStudios tier yet</>
                      )
                    }
                  </LoadingWrap>
                </div>
                <Divider />
                <LoadingWrap loading={!unlockPenalty}>
                  <div className='lockup-form__balances'>
                    <dl className='info-list'>
                      <div>
                        <dt className='name'>Locked amount</dt>
                        <dd className='value'>{activeRecord ? balanceToCurrency(activeRecord.amount, decimals) : '--'} <small>ZKST</small></dd>
                      </div>
                      <div>
                        <dt className='name'>APY</dt>
                        <dd className='value'>{activeRecordTier ? numberToPercentage(activeRecordTier.apy) : '--'}</dd>
                      </div>
                      <div>
                        <dt className='name'>Accumulated Rewards</dt>
                        <dd className='value'>{activeRecord ? balanceToCurrency(activeRecord.rewards, decimals) : '--'} <small>ZKST</small></dd>
                      </div>
                      <div>
                        <dt className='name'>Lock Period</dt>
                        <dd className='value'>{activeRecordTier ? getReadablePeriod(activeRecordTier.lockPeriod) : '--'}</dd>
                      </div>
                      <div>
                        <dt className='name'>Locked at</dt>
                        <dd className='value'>{activeRecord ? formatDateToUTC(activeRecord.stakedAt) : '--'}</dd>
                      </div>
                    </dl>
                  </div>
                  <div className='lockup-form__controls mt-3'>
                    <InputGroup className="lockup-form__input-group">
                      <InputGroup.Prepend>
                        <Image
                          src={InputIcon}
                          width={32}
                          height={32}
                          roundedCircle
                          className='lockup-form__input-icon'
                        />
                      </InputGroup.Prepend>
                      <FormControl
                        className="lockup-form__input"
                        placeholder="0.0"
                        readOnly
                        type="number"
                        value={activeRecord ? balanceToNumeric(activeRecord?.amount, decimals) : ''}
                        onChange={(e) => setAmountToLock(e.target.value)}
                      />
                    </InputGroup>
                    {
                      (!!unlockPenalty && !unlockPenalty.isZero() && !activeRecord?.unstakedAt) && (
                        <Alert bsPrefix={'custom-alert'} variant={'light'} className="mt-3 text-center">
                          The penalty for early unlocking is <b className="orange-text">{balanceToCurrency(unlockPenalty, decimals)} ZKST</b>
                        </Alert>
                      )
                    }
                    <div className="lockup-form__buttons mt-3">
                      <RoundButton
                        size="large"
                        disabled={disableUnlocking}
                        onClick={handleUnlock}
                      >
                        {isUnlocking ? <Spinner /> : 'Unlock'}
                      </RoundButton>
                    </div>
                    <div className="lockup-form__buttons mt-3">
                      <RoundButton
                        size="large"
                        color="TRANSPARENT"
                        disabled={isUnlocking}
                        onClick={goToLock}
                      >
                        Back To Lock
                      </RoundButton>
                    </div>

                    <div
                      className={classNames(
                        'form-message form-message--warning text-center mt-3',
                        { hidden: !unlockingError }
                      )}
                    >
                      <InfoIcon />
                      <span>{unlockingError}</span>
                    </div>
                  </div>
                </LoadingWrap>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className='kyc-section mt-5'>
        <Container>
          <KYCBadge />
        </Container>
      </section>
    </div>
  )
}
