import axios from 'axios';

export const coingecko = axios.create({
  baseURL: 'https://api.coingecko.com/api/v3',
})

export const COINGECKO_FLAME_ID = 'firestarter'
export const COINGECKO_USDC_ID = 'usd-coin'
export const coingeckoRetryTimeout = 60000

export const getPricesByTokenIds = async (tokenIds: string[]): Promise<Record<string, number>> => {
  const { data } = await coingecko.get('/simple/price', {
    params: {
      ids: tokenIds.join(','),
      vs_currencies: 'usd'
    }
  })

  const result: Record<string, number> = {}
  tokenIds.forEach(id => {
    result[id] = data[id].usd
  })

  return result
}

