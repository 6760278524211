import React from 'react';
import { ProjectStatusProp, ProjectType, SocialLink, TokenName } from '../types';
import './ProjectDescription.scss';
import { Image } from 'react-bootstrap';
import { ProjectTag } from '../ProjectTag';
import { DynamicImage } from '@components/common/DynamicImage';
import { NetworkName } from '@contracts/networks';

export interface ProjectDescriptionProps {
  logo: string
  name: string
  subtitle?: string
  status: ProjectStatusProp
  network: NetworkName
  fundTokenName: TokenName
  description: string
  socialLinks: SocialLink[]
  projectType: ProjectType
}

interface Props extends ProjectDescriptionProps {
  onDownloadTerms?: () => void
}

export const ProjectDescription: React.FC<Props> = React.memo(({
  logo,
  name,
  subtitle,
  status,
  network,
  description,
  fundTokenName,
  onDownloadTerms,
  socialLinks,
  projectType,
}) => {

  return (
    <div className='project-info'>
      <header className='project-info__head'>
        <Image src={logo} roundedCircle className='project-info__logo' />
        <div>
          <div className='project-info__tags'>
            <ProjectTag type="status" value={status} />
            <ProjectTag type="network" value={network} />
            <ProjectTag type="token" projectType={projectType} value={fundTokenName} />
          </div>
          <h3 className='project-info__name'>{name}</h3>
          {!!subtitle && (
            <p className='project-info__subtitle'>
              {subtitle}
            </p>
          )}
        </div>
      </header>
      <div className='project-info__description' dangerouslySetInnerHTML={{ __html: description }} />
      {
        !!onDownloadTerms && (
          <a
            href='javascript:'
            className='project-info__download-terms'
            onClick={onDownloadTerms}
          >
            Download terms & conditions
          </a>
        )
      }
      {
        !!socialLinks.length && (
          <div className='project-info__links'>
            {socialLinks.map(link =>
              <a key={link.name} className="project-info__link" href={link.url} target="_blank" rel="noreferrer noopener">
                <DynamicImage path={`socials/${link.name}.svg`} />
              </a>
            )}
          </div>
        )
      }
    </div>
  );
});
