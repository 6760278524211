import './MobileView.scss'
import { ReactComponent as Logo } from '@assets/logo.svg'
import Banner from '@assets/banners/mobile-banner.png'
import { socialLinks } from "@constants";
import { DynamicImage } from "@components";
import { Container } from "react-bootstrap";

export const MobileView = () => (
  <div className="mobile-screen page">
    <Container>
      <header className="mobile-screen__header">
        <Logo />
        <div className="mobile-screen__banner">
          <img src={Banner} alt="Banner"/>
        </div>
      </header>
      <main className="mobile-screen__content">
        <h4 className="title">
          The <strong>mobile version</strong> of the website will be <strong>available soon</strong>
        </h4>
        <p className="caption">
          Please use the web version
        </p>
      </main>
      <footer className="mobile-screen__footer">
        <ul className="mobile-screen__socials">
          {socialLinks.map((link) => (
            <li key={link.href}>
              <a
                href={link.href}
                target="_blank"
                rel="noopener noreferrer"
              >
                <DynamicImage path={`socials/${link.icon}.svg`} />
              </a>
            </li>
          ))}
        </ul>
      </footer>
    </Container>
  </div>
)