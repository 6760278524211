import { useCallback, useEffect, useState } from 'react';
import { checkPartnerPassword, getExclusivePartner } from '@api/exclusive-partners';
import { useIsMounted } from '@hooks/useIsMounted';

interface PartnerInfo {
  name: string
  logo: string
  description: string
  project_subtitle: string
  referrer: string
  min_allocation: number
  max_allocation: number
}

export const useExclusivePartner = (referrer: string) => {
  const isMountedRef = useIsMounted()
  const [loading, setLoading] = useState(false)
  const [partner, setPartner] = useState<PartnerInfo | null>(null)
  const [submittedPassword, setSubmittedPassword] = useState('')
  const [passwordError, setPasswordError] = useState<string | null>(null)

  useEffect(() => {
    setPasswordError(null)
  }, [])

  const submitPartnerPassword = useCallback(async (password: string) => {
    setPasswordError(null)

    const data = await checkPartnerPassword(referrer, password)

    if (isMountedRef.current) {
      if (!data) {
        setPasswordError('Password is invalid')
        setTimeout(() => setPasswordError(null), 6000)
        return
      }

      setSubmittedPassword(data.password)
    }
  }, [referrer, isMountedRef])

  const getPartnerInfo = useCallback(async () => {
    const data = await getExclusivePartner(referrer)

    isMountedRef.current && setPartner(data)
  }, [referrer, isMountedRef])

  return {
    loading,
    partner,
    submittedPassword,
    passwordError,
    submitPartnerPassword,
    getPartnerInfo
  }
}
