import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Accordion, Image } from 'react-bootstrap';
import {
  ArrowIcon,
  LoadingWrap,
  RoundButton,
  Spinner,
  WrongNetworkBlock
} from '@components/common';
import { RoutesPaths } from '@router/constants';
import { Link, generatePath } from 'react-router-dom';
import { NormalizedProjectInfo } from '@components/Project/types';
import { balanceToCurrency, balanceToNumber } from '@utils/balanceFormatter';
import { ReactComponent as InfoIcon } from '@assets/info-icon.svg';
import { ReactComponent as LinkArrow } from '@assets/link-arrow.svg';
import classNames from 'classnames';
import './PortfolioItem.css'
import { useMultipleVesting } from '@contracts/hooks/useMultipleVesting';
import { useWeb3React } from '@web3-react/core';
import { ContractAddress } from '@contracts/address';
import { getMultipleVestingForWallet } from '@api/projects';
import { usePresale } from '@contracts/hooks/usePresale';
import BigNumber from 'bignumber.js';
import { useIsMounted } from '@hooks/useIsMounted';
import { projectGetters } from '@contracts/getters/projectGetters';
import { useNetwork } from '@hooks/useNetwork';

interface Props {
  project: NormalizedProjectInfo
}

export const PortfolioItem = ({ project }: Props) => {
  const isMountedRef = useIsMounted()
  const [collapsed, setCollapsed] = useState(true)

  const isMultipleVesting: boolean = useMemo(() => projectGetters.getIsMultipleVesting(project), [project])
  const isExternalPresale: boolean = useMemo(() => projectGetters.getIsExternalPresale(project), [project])
  const [isAddressesChecked, setAddressesChecked] = useState(!isMultipleVesting)
  const [multipleVestingAddresses, setVestingAddresses] = useState<ContractAddress[]>([])

  const { account } = useWeb3React()
  const { checkIfSelected, currentNetworkId, getNetwork } = useNetwork()
  const isProjectNetworkSelected = useMemo(() => checkIfSelected(project.chainId.toString()), [project, checkIfSelected])
  const projectNetwork = useMemo(() => getNetwork(project.chainId), [project, getNetwork])

  const getVestingAddresses = useCallback(async () => {
    if (!account) {
      setVestingAddresses([])
      return
    }
    const data = await getMultipleVestingForWallet(account)
    if (isMountedRef.current) {
      setVestingAddresses(data || [])
      setAddressesChecked(true)
    }
  }, [account, project, isMountedRef])

  useEffect(() => {
    if (isMultipleVesting) {
      getVestingAddresses()
    }
  }, [account, isMultipleVesting, getVestingAddresses, currentNetworkId])

  const vestingAddresses = useMemo(() => {
    let addresses = multipleVestingAddresses
    if (project.presale.vesting_contract_address) {
      addresses = [...addresses, project.presale.vesting_contract_address]
    }
    return (isAddressesChecked && isProjectNetworkSelected) ? addresses : []
  }, [multipleVestingAddresses, project, isAddressesChecked, isProjectNetworkSelected])

  const {
    isClaiming,
    totalVested,
    unvested,
    claimed,
    withdrawable,
    withdraw
  } = useMultipleVesting(vestingAddresses)

  const {
    swappedByUser,
    swapExchangeRate,
    fundsDecimals,
    rewardsDecimals
  } = usePresale(
    ...(
      isProjectNetworkSelected ? [
        project.presale.presale_contract_address,
        project.presale.fund_token.address,
        project.presale.reward_token.address
      ] : []
    )
  );

  const [currentPrice, setCurrentPrice] = useState(new BigNumber(0.65))
  const currentValue = useMemo(() => {
    return totalVested.multipliedBy(currentPrice)
  },[totalVested, currentPrice])

  const ROI = useMemo(() => {
    let bnROI = currentPrice.minus(swapExchangeRate).div(swapExchangeRate)
    return (balanceToNumber(bnROI, 0) * 100).toFixed(2)
  }, [swapExchangeRate, currentPrice])

  const rewardToken = useMemo(() => {
    return project?.presale.reward_token.name
  }, [project])

  const fundToken = useMemo(() => {
    return project?.presale.fund_token.name
  }, [project])

  return (isProjectNetworkSelected && totalVested.isZero() && !isExternalPresale)
    ? <></>
    : (
      <Accordion>
        <div className="portfolio-item tile">
          <div className='portfolio-item__main'>
            <LoadingWrap loading={!project}>
              <div className='portfolio-item__col'>
                <div className='portfolio-item__logo'>
                  <Image
                    src={project.assets.logo_image_url}
                    roundedCircle
                    className='portfolio-item__logo-image'
                  />
                </div>
                <h4 className="portfolio-item__name">
                  <Link to={generatePath(RoutesPaths.PROJECT, { id: project.id })}>{project.name}</Link>
                </h4>
              </div>
              {
                isExternalPresale ? (
                  <>
                    {Array.from(Array(4).keys()).map(k => (
                      <div key={k} className='portfolio-item__col'>
                        <span></span>
                      </div>
                    ))}
                    <div className="portfolio-item__col">
                      <RoundButton
                        size="large"
                        href={project.presale.external_presale_link}
                      >
                        Go To Website <LinkArrow/>
                      </RoundButton>
                    </div>
                  </>
                ) : !isProjectNetworkSelected ? (
                  <div className="portfolio-item__col portfolio-item__col--wrong-network">
                    <WrongNetworkBlock
                      prefix={'To claim your tokens'}
                      expectedNetwork={projectNetwork}
                      embedded={false}
                    />
                  </div>
                ) : (
                  <>
                    <div className='portfolio-item__col'>
                      <span>
                        {balanceToCurrency(totalVested, rewardsDecimals)} <br/> {rewardToken}
                      </span>
                    </div>
                    <div className='portfolio-item__col'>
                      <span>
                       {balanceToCurrency(unvested, rewardsDecimals)} <br/> {rewardToken}
                      </span>
                    </div>
                    <div className='portfolio-item__col'>
                      <span>
                        {balanceToCurrency(claimed, rewardsDecimals)} <br/> {rewardToken}
                      </span>
                    </div>
                    <div className='portfolio-item__col orange-text fw-semibold'>
                      <span>
                        {balanceToCurrency(withdrawable, rewardsDecimals)} <br/> {rewardToken}
                      </span>
                    </div>
                    <div className='portfolio-item__col'>
                      <RoundButton
                        color="DARK"
                        disabled={!balanceToNumber(withdrawable, rewardsDecimals) || isClaiming}
                        onClick={withdraw}
                      >
                        {isClaiming ? <Spinner/> : 'Claim'}
                      </RoundButton>
                      <Accordion.Toggle
                        eventKey={'0'}
                        as={RoundButton}
                        size="large"
                        color="LIGHT"
                        className={classNames({
                          'portfolio-item__toggle': true,
                          'active': !collapsed
                        })}
                        onClick={() => setCollapsed(!collapsed)}
                      >
                        % <ArrowIcon/>
                      </Accordion.Toggle>
                    </div>
                  </>
                )
              }
            </LoadingWrap>
          </div>
          {
            !isExternalPresale && isProjectNetworkSelected && (
              <Accordion.Collapse eventKey={'0'}>
                <div className='portfolio-item__collapse'>
                  <p className="text-big text-wide">
                    Performance
                  </p>
                  <dl className='portfolio-item__additions'>
                    <div>
                      <dt className='name'>Contributed</dt>
                      <dd className='value'>{balanceToCurrency(swappedByUser, fundsDecimals)} {fundToken}</dd>
                    </div>
                    <div>
                      <dt className='name'>Current Value</dt>
                      <dd className='value'>
                        {/*TODO: Current price for tokens is not implemented*/}
                        Not available
                        {/*{balanceToCurrency(currentValue, fundsDecimals)} {fundToken}*/}
                      </dd>
                    </div>
                    <div>
                      <dt className='name'>ROI</dt>
                      <dd className='value'>
                        {/*TODO: Current price for tokens is not implemented*/}
                        Not available
                        {/*{ROI} %*/}
                      </dd>
                    </div>
                  </dl>
                </div>
              </Accordion.Collapse>
            )
          }
          <div className='portfolio-item__footer'>
            {
              project.presale.release_schedule && (
                <div className="portfolio-item__schedule">
                  <a className="text-big text-wide" href={project.presale.release_schedule} target="_blank" rel="noreferrer">
                    <InfoIcon />
                    Review tokens release schedule
                  </a>
                </div>
              )
            }
          </div>
        </div>
      </Accordion>
    )
}
