import React, { useMemo } from 'react';
import './ProjectSlide.scss'
import { ProjectDescriptionProps } from '../ProjectDescription';
import { ProjectTag } from '../ProjectTag';
import { Image } from 'react-bootstrap';
import { RoundButton } from '@components/common';
import { generatePath, Link } from 'react-router-dom';
import { RoutesPaths } from '@router/constants';
import classNames from 'classnames';
import { ProjectTypes } from '../types';
import { ReactComponent as RightArrow} from "@assets/right_arrow.svg";

interface Props extends Omit<ProjectDescriptionProps, 'description' | 'socialLinks'> {
  id: string
  mainColor?: string
  NFTImage?: string
}

export const ProjectSlide = ({
  id,
  logo,
  projectType,
  NFTImage,
  name,
  subtitle,
  status,
  network,
  mainColor,
  fundTokenName,
}: Props) => {

  const isNFTCampaign = useMemo(
    () => [ProjectTypes.NFT_claim, ProjectTypes.NFT_presale].includes(projectType as ProjectTypes),
    [projectType]
  )

  return (
    <Link to={generatePath(RoutesPaths.PROJECT, { id })} className="project-slide">
      <header className='project-slide__head'>
        <div className='project-slide__tags'>
          <ProjectTag type="status" value={status} />
          <ProjectTag type="network" value={network} />
          <ProjectTag type="token" projectType={projectType} value={fundTokenName} />
        </div>
        <h3 className='project-slide__name'>
          {name}
        </h3>
        {!!subtitle && (
          <p className='project-slide__subtitle'>
            {subtitle}
          </p>
        )}
      </header>
      <div
        style={{
          backgroundColor: mainColor || '#201C19'
        }}
        className='project-slide__image'
      >
        <Image
          src={logo}
          width={isNFTCampaign ? 60 : 140}
          height={isNFTCampaign ? 60 : 140}
          roundedCircle
          className={classNames({
            [`project-slide__logo${isNFTCampaign ? '--nft' : ''}`]: true
          })}
        />
        {
          isNFTCampaign && NFTImage && (
            <Image
              src={NFTImage}
              className="project-slide__nft-image"
            />
          )
        }
        <RoundButton
          className="project-slide__button"
          color="LIGHT"
        >
          <RightArrow className="link-arrow" />
        </RoundButton>
      </div>
    </Link>
  )
}
