import React, { useState } from 'react';
import { RoundButton, Spinner } from '@components/common';
import { Row, Col, Form } from 'react-bootstrap';
import { useFormik, FormikHelpers } from 'formik';
import * as yup from 'yup'
import { utils } from 'web3'

const isUSAOptions = ['Yes', 'No']

export interface JoinFormValues {
  firstName: string
  lastName: string
  email: string
  twitterOrInstagram: string
  telegram: string
  walletAddress: string
  isUSA: 'Yes' | 'No' | ''
}

const initialValues: JoinFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  twitterOrInstagram: '',
  telegram: '',
  walletAddress: '',
  isUSA: '',
}

const validationSchema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().email('Wrong format of email').required('Type your email to contact'),
  twitterOrInstagram: yup.string(),
  telegram: yup.string(),
  walletAddress: yup
    .string()
    .test(
      'isAddress',
      'It is not an Eth Address',
      value => utils.isAddress(value ?? '')),
  isUSA: yup.string().oneOf(isUSAOptions),
})

interface Props {
  onSubmit: (formData: JoinFormValues) => Promise<unknown> | unknown
}

export const JoinForm = ({ onSubmit }: Props) => {
  const [loading, setLoading] = useState(false)

  const onSubmitHandler = async (
    values: JoinFormValues,
    formikHelpers: FormikHelpers<JoinFormValues>
  ) => {
    if (loading) {
      return
    }

    setLoading(true)
    await onSubmit(values)
    setLoading(false)
  }

  const {
    errors,
    touched,
    handleSubmit,
    isValid,
    handleChange,
    handleBlur,
    getFieldProps,
  } = useFormik({
    validateOnChange: true,
    validateOnMount: true,
    validationSchema,
    onSubmit: onSubmitHandler,
    initialValues
  })

  return (
    <Form
      noValidate
      className="join-form marketing-page-form tile"
      onSubmit={handleSubmit}
    >
      <div className="marketing-page-form__inner custom-scroll">
        <Row>
          <Col>
            <Form.Group controlId="firstName">
              <Form.Label>First Name *</Form.Label>
              <Form.Control
                type="text"
                placeholder="First Name"
                {...getFieldProps('firstName')}
                isValid={touched.firstName && !errors.firstName}
                isInvalid={touched.firstName && !!errors.firstName}
              />
              <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="lastName">
              <Form.Label>Last Name *</Form.Label>
              <Form.Control
                type="text"
                placeholder="Last Name"
                {...getFieldProps('lastName')}
                isValid={touched.lastName && !errors.lastName}
                isInvalid={touched.lastName && !!errors.lastName}
              />
              <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label>Email *</Form.Label>
              <Form.Control
                type="email"
                placeholder="youremail@mail.com"
                {...getFieldProps('email')}
                isValid={touched.email && !errors.email}
                isInvalid={touched.email && !!errors.email}
              />
              <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="twitterOrInstagram">
              <Form.Label>Twitter or Instagram</Form.Label>
              <Form.Control
                type="text"
                placeholder="@"
                {...getFieldProps('twitterOrInstagram')}
                isValid={touched.twitterOrInstagram && !errors.twitterOrInstagram}
                isInvalid={touched.twitterOrInstagram && !!errors.twitterOrInstagram}
              />
              <Form.Control.Feedback type="invalid">{errors.twitterOrInstagram}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="telegram">
              <Form.Label>Telegram</Form.Label>
              <Form.Control
                type="text"
                placeholder="@"
                {...getFieldProps('telegram')}
                isValid={touched.telegram && !errors.telegram}
                isInvalid={touched.telegram && !!errors.telegram}
              />
              <Form.Control.Feedback type="invalid">{errors.telegram}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="walletAddress">
              <Form.Label>Wallet Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Wallet Address"
                {...getFieldProps('walletAddress')}
                isValid={touched.walletAddress && !errors.walletAddress}
                isInvalid={touched.walletAddress && !!errors.walletAddress}
              />
              <Form.Control.Feedback type="invalid">{errors.walletAddress}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4 className='title'>
              Are you based in the United States?
            </h4>
            {
              isUSAOptions.map((item) => (
                <Form.Group key={item} controlId={item} className="form-group--radio">
                  <Form.Check
                    type="radio"
                    name="isUSA"
                    label={item}
                    value={item}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Form.Group>
              ))
            }
          </Col>
        </Row>
        <Row className="text-center marketing-page-form__submit-row">
          <Col>
            <RoundButton
              type="submit"
              size="large"
              wide
              disabled={!isValid || loading}
            >
              {
                loading
                  ? <Spinner />
                  : 'Submit'
              }
            </RoundButton>
          </Col>
        </Row>
      </div>
    </Form>
  )
}
