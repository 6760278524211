export const RoutesPaths = {
  MAIN: '/',
  METAVERSE_NFT: {
    MAIN: '/metaverse-nft',
    COLLECTION: '/metaverse-nft/collection',
  },
  NFT_TOKEN: '/nft/:token_name/:nft_id',
  ACCOUNT: {
    MAIN: '/account',
    LOCKING: '/account/lockup',
    LOCKING_MULTIPERIOD: '/account/lockup-new',
    STAKING: '/account/staking',
    LINKING_WALLETS: '/account/linking-wallets',
  },
  LEADERBOARD: '/leaderboard',
  PROJECTS: '/projects',
  PROJECT: '/projects/:id',
  WHITELIST: '/whitelist/:project_id',
  EXCLUSIVE_PROJECT: '/exclusive/:referrer/project',
  EXCLUSIVE_WHITELIST: '/exclusive/:referrer/whitelist',
  ABOUT_US: '/about-us',
  APPLY_FOR_IDO: '/apply-for-ido',
  JOIN_NETWORK: '/join-our-network',
  USER_AGREEMENT: '/user-agreement',
  PRIVACY: '/privacy-policy',
  PRESS_KIT: '/press-kit',
  FAQ: '/faq',
  KYC_PROCESS: '/kyc-process',
  ADD_NETWORK_GUIDE: '/add-network-to-metamask',
  SUBSCRIBE: '/subscribe'
}
