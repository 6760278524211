import { sendEnvExceptionReport } from '@utils/errors';

export enum NetworksIDs {
  matic = '137',
  maticmum = '80001',
  eth = '1',
  rin = '4',
  bnb = '56',
  bnbt = '97',
  zksync = '324',
  zksynctest = '280',
  solana = 'sol-101',
  solanadev = 'sol-103',
}

export enum NetworkNames {
  matic = 'Matic Mainnet',
  maticmum = 'Matic Testnet Mumbai',
  eth = 'Ethereum Mainnet',
  rin = 'Ethereum Testnet Rinkeby',
  bnb = 'Binance Smart Chain Mainnet',
  bnbt = 'Binance Smart Chain Testnet',
  zksync = 'zkSync Era',
  zksynctest = 'zkSync Era Testnet',
  solana = 'Solana Mainnet',
  solanadev = 'Solana Devnet',
}

export enum NetworkUserFriendlyNames {
  matic = 'Polygon',
  maticmum = 'Mumbai',
  eth = 'Ethereum',
  rin = 'Rinkeby',
  bnb = 'BSC',
  bnbt = 'BSC Testnet',
  zksync = 'zkSync',
  zksynctest = 'zkSync Testnet',
  solana = 'Solana',
  solanadev = 'Solana Devnet',
}

export type NetworkName = NetworkNames[number];
export type NetworkId = NetworksIDs[number];
export type NetworkUserFriendlyName = NetworkUserFriendlyNames[number];

export interface NetworkCurrency {
  name: string;
  symbol: string;
  decimals: number;
}

export interface NetworkInfo {
  name: NetworkName;
  icon: string;
  userFriendlyName: NetworkUserFriendlyName;
  networkId: NetworkId;
  nativeCurrency: NetworkCurrency;
  rpcUrl: string;
  privateRPC?: string;
  explorerUrl: string;
  production: boolean;
  isActive: boolean;
  color: string;
}

export type NetworksList = Record<NetworkId, NetworkInfo>;

export const networks: NetworksList = {
  [NetworksIDs.maticmum]: {
    name: NetworkNames.maticmum,
    icon: 'polygon',
    userFriendlyName: NetworkUserFriendlyNames.maticmum,
    networkId: NetworksIDs.maticmum,
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18
    },
    rpcUrl: 'https://rpc-mumbai.maticvigil.com/',
    explorerUrl: 'https://mumbai.polygonscan.com/',
    production: false,
    isActive: false,
    color: '#8248e5',
  },
  [NetworksIDs.matic]: {
    name: NetworkNames.matic,
    icon: 'polygon',
    userFriendlyName: NetworkUserFriendlyNames.matic,
    networkId: NetworksIDs.matic,
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18
    },
    rpcUrl: 'https://polygon-rpc.com/',
    explorerUrl: 'https://polygonscan.com/',
    production: true,
    isActive: false,
    color: '#8248e5'
  },
  [NetworksIDs.eth]: {
    name: NetworkNames.eth,
    icon: 'eth',
    userFriendlyName: NetworkUserFriendlyNames.eth,
    networkId: NetworksIDs.eth,
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    },
    rpcUrl: process.env.REACT_APP_ETH_RPC_URL ?? 'https://main-light.eth.linkpool.io/',
    explorerUrl: 'https://etherscan.io',
    production: true,
    isActive: false,
    color: '#627EEA'
  },
  [NetworksIDs.rin]: {
    name: NetworkNames.rin,
    icon: 'rinkeby',
    userFriendlyName: NetworkUserFriendlyNames.rin,
    networkId: NetworksIDs.rin,
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    },
    rpcUrl: 'https://rinkeby-light.eth.linkpool.io/',
    explorerUrl: 'https://rinkeby.etherscan.io',
    production: false,
    isActive: false,
    color: '#686868'
  },
  [NetworksIDs.bnb]: {
    name: NetworkNames.bnb,
    icon: 'bsc',
    userFriendlyName: NetworkUserFriendlyNames.bnb,
    networkId: NetworksIDs.bnb,
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 8
    },
    rpcUrl: 'https://bsc-dataseed.binance.org/',
    explorerUrl: 'https://bscscan.com',
    production: true,
    isActive: false,
    color: '#F8D12F'
  },
  [NetworksIDs.bnbt]: {
    name: NetworkNames.bnbt,
    icon: 'bsc',
    userFriendlyName: NetworkUserFriendlyNames.bnbt,
    networkId: NetworksIDs.bnbt,
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 8
    },
    rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
    explorerUrl: 'https://testnet.bscscan.com/',
    production: false,
    isActive: false,
    color: '#F8D12F'
  },
  [NetworksIDs.zksync]: {
    name: NetworkNames.zksync,
    icon: 'zksync',
    userFriendlyName: NetworkUserFriendlyNames.zksync,
    networkId: NetworksIDs.zksync,
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrl: 'https://mainnet.era.zksync.io',
    explorerUrl: 'https://explorer.zksync.io',
    production: true,
    isActive: true,
    color: '#4E529A'
  },
  [NetworksIDs.zksynctest]: {
    name: NetworkNames.zksynctest,
    icon: 'zksynctest',
    userFriendlyName: NetworkUserFriendlyNames.zksynctest,
    networkId: NetworksIDs.zksynctest,
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrl: 'https://testnet.era.zksync.dev',
    explorerUrl: 'https://goerli.explorer.zksync.io',
    production: false,
    isActive: false,
    color: '#4E529A'
  },
  [NetworksIDs.solana]: {
    name: NetworkNames.solana,
    icon: 'solana',
    userFriendlyName: NetworkUserFriendlyNames.solana,
    networkId: NetworksIDs.solana,
    nativeCurrency: {
      name: 'SOL',
      symbol: 'SOL',
      decimals: 9
    },
    rpcUrl: 'https://solana-mainnet.phantom.tech',
    explorerUrl: 'https://solscan.io',
    production: true,
    isActive: false,
    color: '#DC1FFF'
  },
  [NetworksIDs.solanadev]: {
    name: NetworkNames.solanadev,
    icon: 'solana',
    userFriendlyName: NetworkUserFriendlyNames.solanadev,
    networkId: NetworksIDs.solanadev,
    nativeCurrency: {
      name: 'SOL',
      symbol: 'SOL',
      decimals: 9
    },
    rpcUrl: 'https://api.devnet.solana.com',
    explorerUrl: 'https://solscan.io',
    production: false,
    isActive: false,
    color: '#DC1FFF'
  },
};

export const getNetworkIcon = (network: NetworkInfo): string => {
  return `/networks/${network.icon}.png`
}

export const checkIsMerkleWhitelisting = (networkId: NetworkId): boolean => {
  return [
    NetworksIDs.eth,
    NetworksIDs.rin,
    NetworksIDs.matic,
    NetworksIDs.maticmum,
    NetworksIDs.bnb,
    NetworksIDs.bnbt,
    NetworksIDs.zksync,
    NetworksIDs.zksynctest
  ].includes(networkId as NetworksIDs)
}

export const defaultEnvironmentNetworkId = process.env
  .REACT_APP_SELECTED_NETWORK_ID as NetworkId;
export const defaultEnvironmentNetworkData = networks[defaultEnvironmentNetworkId];
export const defaultEnvironmentNetworkName = networks[defaultEnvironmentNetworkId].name;
if (!defaultEnvironmentNetworkId) {
  sendEnvExceptionReport(new Error(`Selected network ID isn't provided`));
}

export const supportedNetworks: Record<NetworkId, NetworkInfo> = Object.entries(networks).reduce(
  (acc, [id, network]) => {
    if (!network.isActive) {
      return acc
    }
    if (process.env.REACT_APP_ENV === 'production' && !network.production) {
      return acc;
    }
    if (process.env.REACT_APP_ENV !== 'production' && network.networkId === NetworksIDs.solana) {
      return acc
    }

    acc[id] = networks[id];
    return acc;
  },
  {} as Record<NetworkId, NetworkInfo>
);
