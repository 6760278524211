import { instance as API } from '../config';
import { PartnerPasswordResponse } from './types';
import { sendExceptionReport } from '@utils/errors';

export const checkPartnerPassword = async (
  referrer: string,
  password: string
): Promise<PartnerPasswordResponse | null> => {
  try {
    const { data } = await API.post('/check-partner-password', {
      referrer,
      password
    })

    return data as PartnerPasswordResponse
  } catch (err) {
    if (err.response.status !== 400) {
      sendExceptionReport(err)
    }
    return null
  }
}
