import React, { useEffect, useMemo } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { RoutesPaths } from '../../router/constants';
import { useParams, useHistory, generatePath } from 'react-router-dom';
import { format } from 'date-fns';
import {
  BackLink,
  DynamicImage,
  LoadingWrap,
  ProjectDescription,
  RoundButton,
  SEOTags,
  WhitelistForm,
  WhitelistStatusView,
  KYCBadge,
  ProjectBio, WrongNetworkBlock
} from '../../components';
import { useZKTier } from '@contracts/hooks/useZKTier/useZKTier';
import { useWhitelist, WhitelistStatuses } from '../../contracts/hooks/useWhitelist';
import { supportEmail } from '../../constants';
import { useKYC } from '../../contracts/hooks/useKYC';
import './Whitelist.scss'
import { KYCStatuses } from '../../api/kyc/types';
import { useWeb3React } from '@web3-react/core';
import { useProjectsState } from '../../contracts/hooks/useProjects';
import { ProjectStatusesProps } from '../../components/Project/types';
import { OGProperties } from '../../seo-content/types';
import { projectGetters } from '../../contracts/getters/projectGetters';
import { useNetwork } from '../../hooks/useNetwork';
import { formatLocalDateWithZone } from '../../utils/dates';

interface ParamTypes {
  project_id: string
}

export const Whitelist = () => {
  const { project_id: id } = useParams<ParamTypes>()
  const history = useHistory()
  const {
    currentProject: project,
    getProjectById
  } = useProjectsState()

  useEffect(() => {
    if (id !== project?.id || !project) {
      getProjectById(id)
    }
  }, [id, project])

  useEffect(() => {
    if (
      project
      && project.id === id
      && project.presale.statusMessage !== ProjectStatusesProps['Registration Open']
    ) {
      history.replace(`/projects/${id}`)
    }
  }, [project, id])

  const {
    getNetwork,
    checkIfSelected,
    currentNetwork
  } = useNetwork()

  const isExternalPresale = useMemo(() => project ? projectGetters.getIsExternalPresale(project) : false, [project])
  const isProjectNetworkSelected = useMemo(() => project ? checkIfSelected(project.chainId) : false, [project, checkIfSelected])
  const projectNetwork = useMemo(() => project ? getNetwork(project.chainId) : null, [project])

  const {
    whiteListStatus,
    applyToWhitelist
  } = useWhitelist(
    id,
    isProjectNetworkSelected ? project?.presale.whitelist_contract_address : undefined
  )
  const { KYCStatus, getKYCStatus } = useKYC()
  const { account } = useWeb3React()
  const {
    userTierInfo,
    loading: loadingTier
  } = useZKTier()

  useEffect(() => {
    getKYCStatus()
  }, [account])

  return (
    <div className="whitelist-page page">
      <SEOTags
        url={`${window.location.origin}${generatePath(RoutesPaths.WHITELIST, { project_id: id })}`}
        title={`| ${project?.name || ''} Whitelist`}
        properties={[
          {
            property: OGProperties.title,
            content: `ZKStudios | ${project?.name || ''} Whitelist`,
          },
          {
            property: OGProperties.description,
            content: `Apply to Whitelist of the ${project?.name || ''} Project`,
          },
          {
            property: OGProperties.url,
            content: `${window.location.origin}${generatePath(RoutesPaths.WHITELIST, { project_id: id })}`,
          },
        ]}
      />
      <Container className="py-5">
        <h1 className="title mb-0">{!!project && projectGetters.getProjectTypeSuffix(project)} Whitelist</h1>
      </Container>
      <LoadingWrap loading={!whiteListStatus}>
        {
          !!project
          && !!project.whitelisting.starts_at
          && whiteListStatus === WhitelistStatuses.not_submitted
          && (
            <section className="whitelist-section">
              <Container>
                <Row>
                  <Col xs={{ span: 12 }} xl={{ span: 6 }}>
                    <BackLink to={generatePath(RoutesPaths.PROJECT, { id })}>
                      Back to Campaign
                    </BackLink>
                    <ProjectDescription
                      logo={project.assets.logo_image_url}
                      name={project.name}
                      projectType={project.project_type}
                      subtitle={project.info.subtitle}
                      status={project.presale.statusMessage}
                      network={project.network}
                      fundTokenName={project.presale.fund_token.name}
                      description={project.info.description}
                      socialLinks={project.social_links}
                    />
                    {
                      !!project.info.bio && (
                        <ProjectBio {...project.info.bio} />
                      )
                    }
                    <div className='whitelist-time tile'>
                      <h4 className='title'>Time Frame</h4>
                      <dl className='info-list'>
                        <div>
                          <dt className='name'>Whitelist opens</dt>
                          <dd className='value'>{formatLocalDateWithZone(project.whitelisting.starts_at)}</dd>
                        </div>
                        {
                          !!project.whitelisting.end_at && (
                            <div>
                              <dt className='name'>Whitelist closes</dt>
                              <dd className='value'>{formatLocalDateWithZone(project.whitelisting.end_at)}</dd>
                            </div>
                          )
                        }
                        {
                          !!project.presale.public_starts_at && (
                            <div>
                              <dt className='name'>Public offering date</dt>
                              <dd className='value'>{format(project.presale.public_starts_at, 'MM/dd/yyyy')}</dd>
                            </div>
                          )
                        }
                        {
                          !!project.whitelisting.country_restrictions.length && (
                            <div>
                              <dt className='name'>Restricted countries</dt>
                              <dd className='value'>{project.whitelisting.country_restrictions.join(', ')}</dd>
                            </div>
                          )
                        }
                      </dl>
                    </div>
                    <KYCBadge />
                    {
                      project.name.toLowerCase() !== 'ZKStudio' &&
                      (
                        <div className='whitelist-tier tile'>
                          <LoadingWrap loading={loadingTier}>
                            {!!(userTierInfo && userTierInfo.tier)
                              ? <div className='balance-item'>
                                  <DynamicImage
                                    className="balance-item__icon"
                                    path={userTierInfo.tierImage ?? ''}
                                    roundedCircle
                                  />
                                  <div>
                                    <div className='tile__description balance-item__title'>Your tier</div>
                                    <div className='tile__main balance-item__main fst-italic fw-bold orange-text'>{userTierInfo.tier}</div>
                                  </div>
                                </div>
                              : <p className="text-big text-wide">
                                  You don’t have a <span className="orange-text fst-italic fw-semibold">zkStudios</span> Tier yet.
                                </p>
                            }
                            <RoundButton to={RoutesPaths.ACCOUNT.LOCKING_MULTIPERIOD} size="large">
                              {!!userTierInfo?.tier ? 'Upgrade your' : 'Get'} Tier
                            </RoundButton>
                          </LoadingWrap>
                        </div>
                      )
                    }
                    <div className='whitelist-help tile'>
                      <p className='text-big fw-semibold'>Need some help with zkStudios?</p>
                      <RoundButton href={`mailto:${supportEmail}`} size="large" color="DARK">
                        Get Help
                      </RoundButton>
                    </div>
                  </Col>
                  <Col xs={{ span: 12 }} xl={{ span: 6 }} className="mt-5 mt-xl-0">
                    {
                      projectGetters.getIsNFTCampaign(project) && project.assets.nft_image_url && (
                        <div className="whitelist-nft-image">
                          <Image src={project.assets.nft_image_url} />
                        </div>
                      )
                    }
                    {
                      isProjectNetworkSelected || isExternalPresale ? (
                        <WhitelistForm
                          project={project}
                          kycStatus={KYCStatus ?? KYCStatuses.not_sent}
                          whitelistInfo={project.whitelisting}
                          onSubmit={applyToWhitelist}
                        />
                      ) : (
                        <div className="tile wrong-network-tile">
                          <h3 className='title'>
                            Apply To Whitelist
                          </h3>
                          <WrongNetworkBlock
                            prefix={'To apply to whitelist'}
                            expectedNetwork={projectNetwork!}
                            embedded={false}
                          />
                        </div>
                      )
                    }
                  </Col>
                </Row>
              </Container>
            </section>
          )
        }
        {
          whiteListStatus && whiteListStatus !== WhitelistStatuses.not_submitted && (
            <>
              <Container>
                <BackLink to={generatePath(RoutesPaths.PROJECT, { id })}>
                  Back to Campaign
                </BackLink>
              </Container>
              <WhitelistStatusView status={whiteListStatus} />
            </>
          )
        }
      </LoadingWrap>
    </div>
  )
}
