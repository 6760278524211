import React, { useState } from 'react';
import {
  FormFillResponseView, SEOTags,
  StaticPageWrapper,
  SubscribeForm,
  SubscribeFormValues
} from '../../../components';
import { subscribe } from '../../../api/subscription';
import { seoTags } from '../../../seo-content';

export const Subscription = () => {
  const [formStage, setFormStage] = useState<'FILL' | 'RESPONSE'>('FILL')
  const [responseStatus, setResponseStatus] = useState<'error' | 'success' | null>(null)

  const resetForm = () => {
    setFormStage('FILL')
    setResponseStatus(null)
  }

  const submitSubscription = async (values: SubscribeFormValues) => {
    const response = await subscribe(values)
    setResponseStatus(response.result)
    setFormStage('RESPONSE')
  }

  return (
    <StaticPageWrapper title="Subscription" showTitle={false}>
      <SEOTags { ...seoTags.subscribePage } />
      {
        formStage === 'FILL'
          ? <SubscribeForm onSubmit={submitSubscription} />
          : <FormFillResponseView status={responseStatus} onReset={resetForm} />
      }
    </StaticPageWrapper>
  )
}
