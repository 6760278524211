import React from 'react'
import { SEOTags, StaticPageWrapper } from '../../../components'
import { Container } from 'react-bootstrap'
import { seoTags } from '../../../seo-content'

export const Privacy = () => {

  return (
    <StaticPageWrapper title="ZKStudios Privacy Policy">
      <SEOTags {...seoTags.privacy} />
      <section className="marketing-page__text">
        <Container>
          <p><span>This Privacy Policy describes how </span><strong>ZkStudios.xyz</strong><span> (in the following: &ldquo;ZkStudios&rdquo;, also &ldquo;We&rdquo;) will manage end users personal information which we collect as a result of your use of ZkStudios.xyz, your use of our websites located at https://www.zkstudios.xyz and any pages or websites under the "ZkStudios" brand including any tablet or mobile phone applications that are owned and/or operated by us or whenever we otherwise deal with you. ZkStudios is committed to ensuring that the personal information we collect about you is protected and is used, stored and disclosed in accordance with the Privacy Act and this Privacy Policy.</span></p>
          <p>&nbsp;</p>
          <h4 className="title">Website Use</h4>
          <p>&nbsp;</p>
          <p><span>In using the website, you agree to be bound by the terms of this Privacy Policy. ZkStudios.xyz may review the Privacy Policy periodically and reserves the right to change the Privacy Policy at any time at its discretion by posting such changes on the website. Changes will be effective immediately when posted on the website. The end users continued use of the website following posting will constitute your acceptance of those changes.</span></p>
          <p>&nbsp;</p>
          <p><span>This Privacy Policy will not apply to websites which are linked to the website and over which ZkStudios.xyz has no control.</span></p>
          <p>&nbsp;</p>
          <h4 className="title">Personal Information</h4>
          <p>&nbsp;</p>
          <p><span>The kinds of Personal Information that ZkStudios.xyz may collect from you is:</span></p>
          <p>&nbsp;</p>
          <ul>
            <li><span>Name</span></li>
            <li><span>Email address</span></li>
            <li><span>ETH wallet address</span></li>
            <li><span>SOL wallet address</span></li>
            <li><span>Personally submitted preferences</span></li>
            <li><span>Location data</span></li>
            <li><span>IP address</span></li>
          </ul>
          <p>&nbsp;</p>
          <h4 className="title">Collection Purposes</h4>
          <p>&nbsp;</p>
          <p><span>ZkStudios.xyz will collect your Personal Information only by lawful and fair means and not in an intrusive way to operate its Service, and to provide you with the following information:</span></p>
          <p>&nbsp;</p>
          <ul>
            <li><span>If you wish to subscribe to ZkStudios.xyz&acute;s weekly newsletter or other marketing communications.</span></li>
            <li><span>To update you on the technical progress of ZkStudios.</span></li>
            <li><span>To provide services to you as a User.</span></li>
            <li><span>To identify you as a User for security purposes and to comply with our legal obligations.</span></li>
            <li><span>To maintain your User account.</span></li>
            <li><span>To upgrade and enhance your experience within the website or over the telephone, or to tailor or develop information, services or products to suit your needs which may include market research and conducting promotions.</span></li>
            <li><span>To tell you about our products or services that we think may be of interest to you by communicating with you via email.</span></li>
            <li><span>To create aggregate data about Users through demographic profiling, statistical analysis of the database to provide to potential and existing ZkStudios holders, and to allow for more efficient operation of ZkStudios.xyz&rsquo;s business.</span></li>
            <li><span>To comply with ZkStudios.xyz&rsquo;s legal and statutory obligations.</span></li>
            <li><span>For taking appropriate action if ZkStudios.xyz has reason to suspect that unlawful activity or misconduct of a serious nature has been, is being or may be engaged in that relates to our functions and activities.</span></li>
            <li><span>To establish, exercise or defend any legal claims.</span></li>
            <li><span>Manage job applications.</span></li>
          </ul>
          <p>&nbsp;</p>
          <p><span>You may choose to deal with us on an anonymous basis or to use a pseudonym unless it is not practicable for us to deal with individuals who we cannot identify or we are required by law to only deal with identified individuals. Also, if you do not provide ZkStudios.xyz with the Personal Information we request, we may be unable to process your request to become a User, provide you with our services or respond to your enquiry or complaint. Examples of Personal Information that may be collected by ZkStudios.xyz include your name, email address, telephone numbers, your date of birth, other verification information such as your Service usage activities. By becoming a User or otherwise choosing to provide ZkStudios.xyz with Personal Information you consent to ZkStudios.xyz collecting, using and disclosing your Personal Information for the above purposes.</span></p>
          <p>&nbsp;</p>
          <p><span>We use Google Analytics in order to better understand our users &acute;needs and to optimize this service and experience. Google Analytics is a technology service that helps us better understand our users&acute; experience (eg how much time they spend on which pages, which links they choose to click, what users do and dont like, etc.) and this enables us to build and maintain our service with user feedback. Google Analytics uses cookies and other technologies to collect data on our users behavior and their devices. This includes a devices IP address (processed during your session and stored in a de-identified form), device screen size, device type (unique device identifiers), browser information, geographic location (country only), and the preferred language used to display our website.</span></p>
          <p>&nbsp;</p>
          <h4 className="title">KYC/AML verification</h4>
          <p>&nbsp;</p>
          <p><span>We use third-party KYC/AML verification service Blockpass that collects your personal information for the purpose of verification of your identity. Please, read the Blockpass Privacy Policy following the link</span></p>
          <p><a href="https://www.blockpass.org/privacy"><span>https://www.blockpass.org/privacy</span></a></p>
          <p>&nbsp;</p>
          <h4 className="title">Direct Marketing and Opting Out</h4>
          <p>&nbsp;</p>
          <p><span>Occasionally we may use your Personal Information to inform you about ZkStudios.xyz&acute;s products or services or about promotional activities which ZkStudios.xyz believes may be of interest or of benefit to you. We may do this via email, SMS, telephone or mail. If you no longer wish to receive marketing or promotional material from ZkStudios.xyz at all or in any particular form, you may contact us at any time by email to info@zkstudios.xyz with your request with which we will comply as soon as is practical.</span></p>
          <p>&nbsp;</p>
          <p><span>From time to time we may contact you in relation to the management and administration of your ZkStudios.xyz account. These communications can be via any of the modes of contact recorded when registering as a User. Such communication does not affect your opt-in or opt-out status for direct marketing communications.</span></p>
          <p>&nbsp;</p>
          <h4 className="title">Management of your Personal Information</h4>
          <p>&nbsp;</p>
          <p><span>ZkStudios.xyz will take all reasonable steps to ensure that the Personal Information which it collects, uses or discloses is correct and is stored in a secure environment which is accessed only by authorised persons.</span></p>
          <p>&nbsp;</p>
          <p><span>ZkStudios.xyz will destroy or permanently de-identify the Personal Information we hold when it is no longer required for any purpose permitted under the APPs including our legal or operational obligations.</span></p>
          <p>&nbsp;</p>
          <h4 className="title">Security of Personal Information</h4>
          <p>&nbsp;</p>
          <p><span>You acknowledge that no data transmission over the Internet is totally secure. Accordingly, ZkStudios.xyz does not warrant the security of any information which you transmit to it. Any information which you transmit to ZkStudios.xyz is transmitted at your own risk. However, once ZkStudios.xyz receives your transmission, ZkStudios.xyz will take reasonable steps to protect your Personal Information from misuse, loss and unauthorised access, modification and disclosure including by using password protected systems and databases and Secure Socket Layer (SSL) technology.</span></p>
          <p>&nbsp;</p>
          <p><span>ZkStudios.xyz&rsquo;s employees, agents and contractors are required to maintain the confidentiality of Users&rsquo; Personal Information and trading behaviour.</span></p>
          <p>&nbsp;</p>
          <p><span>Information posted on bulletin boards or communicated within a social media environment (for example, Facebook, Twitter, Chat Rooms) becomes public information. ZkStudios.xyz cannot guarantee the security of this type of disclosed information.</span></p>
          <p>&nbsp;</p>
          <p><span>We take seriously the responsibility to exclude children from access to our services. We will not accept their information for the purposes of accessing or using the Service. It is however, ultimately the responsibility of parents or guardians to monitor their children&rsquo;s Internet activities including where appropriate by using Internet screening software.</span></p>
          <p>&nbsp;</p>
          <p><span>Remember to always log out of your account when you have completed your time on the website. This is particularly important if you share a computer with another person. You are responsible for the security of and access to your own computer, mobile device or any other handset used to access the website.</span></p>
          <p>&nbsp;</p>
          <p><span>Ultimately, you are solely responsible for maintaining the secrecy of your username, password and any account information. Please be careful whenever using the Internet and our website.</span></p>
          <p>&nbsp;</p>
          <h4 className="title">Access to Personal Information</h4>
          <p>&nbsp;</p>
          <p><span>You may access the Personal Information collected by ZkStudios.xyz by contacting us at info@zkstudios.xyz. We will give you access to your Personal Information in the manner requested if that is possible and within a reasonable period. If we refuse your request or cannot give you access in the manner you have requested, we will do what we can to meet your requirements by other means. We may not be required to give you access to your Personal Information in certain circumstances which are set out in the APPs including where it may have an unreasonable impact on other individuals&acute;s privacy. If we refuse access for such reasons, we will advise you in writing of the refusal and our reasons and the complaint mechanisms available to you.</span></p>
          <p>&nbsp;</p>
          <h4 className="title">Use of Website</h4>
          <p>&nbsp;</p>
          <p><span>By using this website, you agree to ZkStudios.xyz collecting and processing your Personal Information on the basis explained in this Privacy Policy.</span></p>
          <p>&nbsp;</p>
          <h4 className="title">Contact Details</h4>
          <p>&nbsp;</p>
          <p><span>If you have any queries, requests for access or correction or complaints relating to the handling of your personal information, please contact us by email.</span></p>
          <p>&nbsp;</p>
          <p><span>info@zkstudios.xyz</span></p>
          <p>&nbsp;</p>
          <h4 className="title">Updates to this Privacy Policy</h4>
          <p>&nbsp;</p>
          <p><span>ZkStudios.xyz may review, change and update this Privacy Policy to reflect our current practices and obligations. We will publish our current Privacy Policy on our website at www.zkstudios.xyz and the changes will take effect at the time of publishing. You should review this privacy policy regularly and remain familiar with its terms.</span></p>
          <p><br /><br /></p>
          <p><span>Last updated: August 9, 2023</span></p>
        </Container>
      </section>
    </StaticPageWrapper>
  )
}
