import { generatePath, useParams } from 'react-router-dom';
import { useProjectsState } from '../../contracts/hooks/useProjects';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useWhitelist, WhitelistStatuses } from '../../contracts/hooks/useWhitelist';
import { useKYC } from '../../contracts/hooks/useKYC';
import { useWeb3React } from '@web3-react/core';
import {
  AddNetworkButton,
  BackLink,
  LoadingWrap,
  PartnerPassword,
  ProjectDescription,
  RoundButton,
  SEOTags,
  WhitelistForm,
  WhitelistStatusView
} from '../../components';
import { OGProperties } from '../../seo-content/types';
import { Col, Container, Row } from 'react-bootstrap';
import { formatISO9075 } from 'date-fns';
import { KYCBadge } from '../../components/common/KYCBadge';
import { ZKSTProjectId, supportEmail } from '../../constants';
import { KYCStatuses } from '../../api/kyc/types';
import { useExclusivePartner } from '../../contracts/hooks/useExclusivePartner';
import { RoutesPaths } from '../../router/constants';
import { WhitelistRequestFormData } from '../../api/whitelist/types';
import { projectGetters } from '../../contracts/getters/projectGetters';
import { useNetwork } from '../../hooks/useNetwork';
import { getNetworkIcon } from '../../contracts/networks';

interface ParamTypes {
  referrer: string
}

export const ExclusiveWhitelist = () => {
  const { referrer } = useParams<ParamTypes>()
  const {
    currentProject: project,
    getProjectById
  } = useProjectsState()

  const {
    passwordError,
    submittedPassword,
    submitPartnerPassword,
    partner,
    getPartnerInfo
  } = useExclusivePartner(referrer)

  useEffect(() => {
    if (!project || project.id !== ZKSTProjectId) {
      getProjectById(ZKSTProjectId as string)
    }
  }, [project, referrer])

  useEffect(() => {
    getPartnerInfo()
  }, [referrer])

  const {
    getNetwork,
    checkIfSelected,
    currentNetwork
  } = useNetwork()

  const isProjectNetworkSelected = useMemo(() => project ? checkIfSelected(project.chainId) : false, [project, checkIfSelected])
  const projectNetwork = useMemo(() => project ? getNetwork(project.chainId) : null, [project])

  const { whiteListStatus, applyToExclusiveWhitelist } = useWhitelist(
    ZKSTProjectId as string,
    isProjectNetworkSelected ? project?.presale.whitelist_contract_address : undefined
  )
  const { KYCStatus, getKYCStatus } = useKYC()
  const { account } = useWeb3React()

  const submitWhitelist = useCallback(async (formData: WhitelistRequestFormData) => {
    await applyToExclusiveWhitelist(
      formData,
      referrer,
      submittedPassword
    )
  }, [referrer, submittedPassword])

  useEffect(() => {
    getKYCStatus()
  }, [account])

  return (
    <div className="whitelist-page page">
      <SEOTags
        url={`${window.location.origin}${generatePath(RoutesPaths.EXCLUSIVE_WHITELIST, { referrer })}`}
        title={`| ${project?.name || ''} Whitelist`}
        properties={[
          {
            property: OGProperties.title,
            content: `ZKStudios | ${project?.name || ''} Whitelist`,
          },
          {
            property: OGProperties.description,
            content: `Apply to ZKStudios Whitelist as exclusive partner`,
          },
          {
            property: OGProperties.url,
            content: `${window.location.origin}${generatePath(RoutesPaths.EXCLUSIVE_WHITELIST, { referrer })}`,
          },
        ]}
      />
      {
        !submittedPassword ? (
          <LoadingWrap loading={!partner}>
            {!!partner && (
              <PartnerPassword
                logo={partner.logo}
                name={partner.name}
                description={partner.description}
                passwordError={passwordError}
                onSubmit={submitPartnerPassword}
              />
            )}
          </LoadingWrap>
        ) : (
          <LoadingWrap loading={!whiteListStatus}>
            <Container className="py-5">
              <h1 className="title mb-0">{project && project.name} Whitelist</h1>
            </Container>
            {
              !!project
              && partner
              && !!project.whitelisting.starts_at
              && whiteListStatus === WhitelistStatuses.not_submitted
              && (
                <section className="whitelist-section">
                  <Container>
                    <Row>
                      <Col xs={{ span: 12 }} xl={{ span: 6 }}>
                        <BackLink to={generatePath(RoutesPaths.EXCLUSIVE_PROJECT, { referrer })}>
                          Back to Campaign
                        </BackLink>
                        <ProjectDescription
                          logo={partner.logo}
                          name={project.name}
                          projectType={project.project_type}
                          subtitle={partner.project_subtitle}
                          status={project.presale.statusMessage}
                          network={project.network}
                          fundTokenName={project.presale.fund_token.name}
                          description={project.info.description}
                          socialLinks={[]}
                        />
                        <div className='whitelist-time tile'>
                          <h2 className='title'>Time Frame</h2>
                          <dl className='info-list'>
                            <div>
                              <dt className='name'>Whitelist opens</dt>
                              <dd className='value'>{formatISO9075(project.whitelisting.starts_at)}</dd>
                            </div>
                            {
                              !!project.whitelisting.end_at && (
                                <div>
                                  <dt className='name'>Whitelist closes</dt>
                                  <dd className='value'>{formatISO9075(project.whitelisting.end_at)}</dd>
                                </div>
                              )
                            }
                            <div>
                              <dt className='name'>Restricted countries</dt>
                              <dd className='value'>{project.whitelisting.country_restrictions.join(', ')}</dd>
                            </div>
                          </dl>
                        </div>
                        <KYCBadge />
                        <div className='whitelist-help tile'>
                          <p className='text-big fw-semibold'>Need some help with ZKStudio?</p>
                          <RoundButton href={`mailto:${supportEmail}`} size="large" color="DARK">
                            Get Help
                          </RoundButton>
                        </div>
                      </Col>
                      <Col xs={{ span: 12 }} xl={{ span: 6 }} className="mt-5 mt-xl-0">
                        {
                          isProjectNetworkSelected ? (
                            <WhitelistForm
                              project={project}
                              kycStatus={KYCStatus ?? KYCStatuses.not_sent}
                              whitelistInfo={project.whitelisting}
                              onSubmit={submitWhitelist}
                            />
                          ) : (
                            <div className="non-polygon-block tile text-center">
                              <p>
                                You are on
                                <span className="network-name" style={{ color: currentNetwork?.color }}>
                                  {currentNetwork && <img src={getNetworkIcon(currentNetwork)} />}
                                  {currentNetwork?.userFriendlyName}
                                </span>
                                now.
                              </p>
                              <p>
                                In order to apply to this whitelist you need to switch to the
                                <span className="network-name" style={{ color: projectNetwork?.color }}>
                                  {projectNetwork && <img src={getNetworkIcon(projectNetwork)} />}
                                  {projectNetwork?.userFriendlyName}
                                </span>
                                network
                              </p>
                              <AddNetworkButton size="large" wide networkId={project.chainId} />
                            </div>
                          )
                        }
                      </Col>
                    </Row>
                  </Container>
                </section>
              )
            }
            {whiteListStatus && whiteListStatus !== WhitelistStatuses.not_submitted &&
            <WhitelistStatusView status={whiteListStatus} />
            }
          </LoadingWrap>
        )
      }
    </div>
  )
}
